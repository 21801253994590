import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Divider, Button, Chip, CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";

import LoginSelector from "../../../containers/Login/selectors/LoginSelector";

import { Icon, RichTextViewer, Text } from "../../atoms";
import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../styles";
import { renderImage } from "../../../utils/imageUtils";
import { showNotification } from "../../../utils/commonUtils";
import { images } from "../../../assets/images";

import {
  useChatRequestResumeMutation,
  useGetPeopleDescriptionQuery,
} from "../../../reactQuery/people";
import { JOB_SEEKER } from "appConstants";
import { logEvent } from "../../../configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

const ChatJobSeekerDetailCard = ({ user, isProfileShown }) => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const [isRequestResumeLoading, setIsRequestResumeLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const uid = searchParams.get("uid");

  const { data, isLoading } = useGetPeopleDescriptionQuery({
    type: JOB_SEEKER,
    userId: uid,
  });

  const [requestResume] = useChatRequestResumeMutation("jobSeeker", uid);
  const isResumeRequested =
    data?.resumeEmpIds && data?.resumeEmpIds.includes(loggedInUser?.uid);

  const onClickRequestResume = async (e) => {
    e.stopPropagation();
    if (isResumeRequested) {
      showNotification({
        alertProps: {
          severity: "warning",
          children: "You have already requested for this jobseeker's resume",
        },
      });
      return;
    }
    setIsRequestResumeLoading(true);
    await requestResume({
      jobSeekerId: data.userId || data.uid,
      employerId: loggedInUser.uid,
    });
    setIsRequestResumeLoading(false);
    if (!isResumeRequested) {
      logEvent(FirebaseEvents.EMP_REQUEST_RESUME);
    }
  };

  // Downloading of resume on onClick has been using the 'download' at Button component
  const onClickViewResume = (e) => {
    e.stopPropagation();
    logEvent(FirebaseEvents.EMP_CLICKS_VIEW_RESUME);
  };

  return (
    <ProfileWrapper>
      <TopRow>
        <Avatar
          sx={{
            width: 56,
            height: 56,
            marginRight: spacing.xs,
            marginLeft: spacing.s,
          }}
          src={renderImage(user?.photoURL || data?.photoURL, "user")}
        >
          <img
            alt={user?.fullName}
            src={images.defaults.user}
            style={{ width: 56, height: 56 }}
          />
        </Avatar>
        <AvatarFullNameAndAge
          isProfileShown={isProfileShown}
        >
          <Text weight="medium" numberOfLines={1} color={colors.labelBlack}>
            {user?.fullName || data?.fullName}
          </Text>
          <Text size="xs">{user?.age || data?.age} yrs</Text>
        </AvatarFullNameAndAge>
      </TopRow>
      <Divider
        sx={{
          transition: "opacity 200ms",
          opacity: isProfileShown ? 0 : 1,
        }}
      />
      {!isProfileShown && (
        <>
          {isLoading || isRequestResumeLoading ? (
            <LoaderContainer>
              <CircularProgress />
            </LoaderContainer>
          ) : (
            <>
              <DetailsBox sx={{ marginTop: spacing.xxs }}>
                <Row>
                  <Icon name="money" alt={"money"} size={31} />
                  <InfoBox>
                    <Text size="xs" color={colors.brownText} weight="semibold">
                      Hourly Wage
                    </Text>
                    <Text weight="semibold" size="s" numberOfLines={1}>
                      {data?.wageExpectation
                        ?.map((wageValue) => `$${wageValue}`)
                        .join(", ")}
                      /hr
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon name="clock" alt={"clock"} size={31} />
                  <InfoBox>
                    <Text size="xs" color={colors.brownText} weight="semibold">
                      Working Hours
                    </Text>
                    <Text weight="semibold" size="s" numberOfLines={1}>
                      {`${data?.workingHours?.join(", ")} hrs/week`}
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon name="experience" alt={"experience"} size={31} />
                  <InfoBox>
                    <Text size="xs" color={colors.brownText} weight="semibold">
                      Experience
                    </Text>
                    <Text weight="semibold" size="s" color={colors.blackText}>
                      {data?.experience
                        .map((hours) =>
                          hours === "Beginner" ? hours : hours + " yrs"
                        )
                        .join(", ")}
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon name="timeslot" alt={"timeslot"} size={31} />
                  <InfoBox>
                    <Text size="xs" color={colors.brownText} weight="semibold">
                      Shift
                    </Text>
                    <Text weight="semibold" size="s" numberOfLines={1}>
                      {data?.timeSlot && Array.isArray(data?.timeSlot)
                        ? data?.timeSlot.join(", ")
                        : data?.timeSlot}
                    </Text>
                  </InfoBox>
                </Row>
                <Row>
                  <Icon name="calendar" alt={"calendar"} size={31} />
                  <InfoBox>
                    <Text size="xs" color={colors.brownText} weight="semibold">
                      Working Days
                    </Text>
                    <Text weight="semibold" size="s" numberOfLines={1}>
                      {data?.daysPerWeek && Array.isArray(data?.daysPerWeek)
                        ? data?.daysPerWeek.join(", ")
                        : data?.daysPerWeek}
                    </Text>
                  </InfoBox>
                </Row>
              </DetailsBox>
              <Divider sx={{ mx: spacing.m }} />
              <DetailsBox>
                <Text
                  weight="medium"
                  color={colors.titleBlack}
                  style={{ marginTop: spacing.l }}
                >
                  About Me
                </Text>
                <RichTextViewer style={{ marginTop: spacing.xs }}>
                  {data?.note}
                </RichTextViewer>
              </DetailsBox>
              <Divider style={{ marginTop: spacing.s }} />
              <DetailsBox>
                <Text
                  weight="medium"
                  color={colors.titleBlack}
                  style={{ marginTop: spacing.s }}
                >
                  My Job Interests
                </Text>
                <InterestsFlexBox sx={{ marginTop: spacing.xs }}>
                  {data?.job_interests?.map((jobRole, index) => {
                    return (
                      <Chip
                        label={jobRole}
                        clickable={false}
                        key={index}
                        variant="outlined"
                        sx={{
                          height: 34,
                          margin: "0 0.75rem 0.75rem 0",
                          borderRadius: styleUtils.pxToRem("17px"),
                          "& .MuiChip-label": {
                            fontSize: textSizes.xs,
                            fontWeight: typography.semibold,
                            color: "#2C4895",
                          },
                          border: "1px solid #2C4895",
                          "& .MuiChip-clickable:hover": {
                            backgroundColor: colors.white,
                          },
                        }}
                      />
                    );
                  })}
                </InterestsFlexBox>
              </DetailsBox>
              <Divider sx={{ mx: spacing.s, marginTop: spacing.s }} />
              <DetailsBox
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {!data?.resumeUrl && (
                  <div>
                    <Text
                      weight="medium"
                      color={colors.titleBlack}
                      style={{ marginTop: spacing.l }}
                    >
                      Request job seeker resume
                    </Text>
                    <Button
                      variant={isResumeRequested ? "outlined" : "contained"}
                      disabled={isResumeRequested}
                      sx={{
                        width: "auto",
                        borderRadius: 100,
                        padding: `${spacing.xs} ${spacing.xxl}`,
                        marginTop: spacing.s,
                      }}
                      onClick={onClickRequestResume}
                    >
                      {isResumeRequested
                        ? " Requested Resume"
                        : "Request Resume"}
                    </Button>
                  </div>
                )}

                {data?.resumeUrl && (
                  <div>
                    <Text
                      weight="medium"
                      color={colors.titleBlack}
                      style={{ marginTop: spacing.s, marginBottom: spacing.l }}
                    >
                      Request job seeker resume
                    </Text>
                    <Button
                      target="_blank"
                      rel="noreferrer"
                      download
                      onClick={onClickViewResume}
                      href={data?.resumeUrl}
                      variant="outlined"
                      sx={{
                        width: "auto",
                        borderRadius: 100,
                        padding: `${spacing.xs} ${spacing.xxl}`,
                      }}
                    >
                      View Resume
                    </Button>
                  </div>
                )}
              </DetailsBox>
            </>
          )}
        </>
      )}
    </ProfileWrapper>
  );
};

ChatJobSeekerDetailCard.propTypes = {
  user: PropTypes.object.isRequired,
  isProfileShown: PropTypes.bool.isRequired,
};

export default ChatJobSeekerDetailCard;

const ProfileWrapper = styled("div")`
  display: flex;
  height: 100%;
  flex-direction: column;
  padding-left: ${spacing.xxxs};
  padding-right: ${spacing.xxxs};
  padding-bottom: ${spacing.s};
  overflow-y: auto;
`;

const TopRow = styled("div")`
  display: flex;
  margin-bottom: ${spacing.s};
  margin-left: ${spacing.xxs};
`;

const DetailsBox = styled("div")`
  margin-left: ${spacing.m};
  margin-bottom: ${spacing.m};
`;

const InterestsFlexBox = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: ${spacing.l};
`;

const InfoBox = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${spacing.xs};
`;

const LoaderContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const AvatarFullNameAndAge = styled("div")`
  transition: opacity 200ms;
  opacity: ${({isProfileShown}) => isProfileShown ? 0 : 1};
`;
