import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Avatar, CircularProgress, Divider, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import LoginSelector from "../../../containers/Login/selectors/LoginSelector";
import JobCard from "../JobCard";
import { Text } from "../../atoms";

import { renderImage } from "../../../utils/imageUtils";
import { images } from "../../../assets/images";

import { JOBSEEKER_PAGE_ROUTE, EXPLORE_JOBS_PAGE_ROUTE } from "../../../routes/routeConstants";
import { spacing, colors } from "../../../styles";
import { useGetEmployerDetailsQuery } from "reactQuery/employerDetails";

const ChatEmployerDetailCard = ({ user, isProfileShown }) => {
  const navigate = useNavigate();

  const { data: employerDetails, isLoading } = useGetEmployerDetailsQuery({
    employerUid: user.uid || user.userId,
  });

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const onClickJob = (job) => {
    navigate({
      pathname: `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
      search: `?showJobDetailView=true&jobId=${job.jobId}&brandName=${job.brandName}`,
    });
  };

  const renderJobs = (item, index) => {
    const isFavourite = item?.jobSeekers.includes(loggedInUser?.uid);
    return (
      <JobCard
        size="s"
        key={index}
        item={item}
        showChat={false}
        isFavourite={isFavourite}
        onClickJob={onClickJob}
      />
    );
  };

  return (
    <ProfileWrapper>
      <TopRow>
        <Avatar
          sx={{ width: 56, height: 56, marginRight: spacing.xs }}
          src={renderImage(user?.photoURL || employerDetails?.data?.empDetails?.photoURL, "job")}
        >
          <img alt={user?.fullName} src={images.defaults.job} style={{ width: 56, height: 56 }} />
        </Avatar>
        <div
          style={{
            transition: "opacity 200ms",
            opacity: isProfileShown ? 0 : 1,
          }}
        >
          <Tooltip
            placement="top"
            arrow
            title={
              <Text size="s" color={colors.white} numberOfLines={1}>
                {user?.fullName || employerDetails?.data?.empDetails?.fullName}
              </Text>
            }
          >
            <div>
              <Text weight="medium" numberOfLines={1}>
                {user?.fullName || employerDetails?.data?.empDetails?.fullName}
              </Text>
            </div>
          </Tooltip>
          <Tooltip
            placement="top"
            arrow
            title={
              <Text size="s" color={colors.white} numberOfLines={1}>
                {user?.companyName || employerDetails?.data?.empDetails?.companyName}
              </Text>
            }
          >
            <div>
              <Text size="xs" numberOfLines={1}>
                {user?.companyName || employerDetails?.data?.empDetails?.companyName}
              </Text>
            </div>
          </Tooltip>
          <Text size="xs" numberOfLines={1}>
            {user?.designation || employerDetails?.data?.empDetails?.designation}
          </Text>
        </div>
      </TopRow>
      <Divider
        sx={{
          transition: "opacity 200ms",
          opacity: isProfileShown ? 0 : 1,
        }}
      />
      <MiddleRow
        sx={{
          transition: "opacity 200ms",
          opacity: isProfileShown ? 0 : 1,
        }}
      >
        {employerDetails?.data?.data?.length > 0 && (
          <Text noWrap weight="medium" style={{ marginTop: spacing.s, marginBottom: spacing.xxs }}>
            Also Hiring
          </Text>
        )}
      </MiddleRow>
      <div
        style={{
          display: "flex",
          overflow: "auto",
          flex: 1,
          transition: "opacity 200ms",
          opacity: isProfileShown ? 0 : 1,
          justifyContent: "center",
        }}
      >
        {isLoading ? (
          <CircularProgress style={{ alignSelf: "center" }} />
        ) : (
          <>
            {employerDetails?.data?.data?.length > 0 ? (
              <JobsContainer id="scrollableDiv">
                <InfiniteScroll
                  dataLength={employerDetails?.data?.data?.length}
                  scrollableTarget="scrollableDiv"
                >
                  {employerDetails?.data?.data?.map(renderJobs)}
                </InfiniteScroll>
              </JobsContainer>
            ) : null}
          </>
        )}
      </div>
    </ProfileWrapper>
  );
};

ChatEmployerDetailCard.propTypes = {
  user: PropTypes.object.isRequired,
  isProfileShown: PropTypes.bool.isRequired,
};

export default ChatEmployerDetailCard;

const ProfileWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const TopRow = styled("div")`
  display: flex;
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};
  padding-bottom: ${spacing.s};
`;

const MiddleRow = styled("div")`
  display: flex;
  flex-direction: column;
  padding-left: ${spacing.s};
  padding-right: ${spacing.s};
`;

const JobsContainer = styled("div")`
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`;
