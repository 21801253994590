import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Icon, Text } from "../../components/atoms";
import { spacing } from "../../styles";
import LoginSelector from "../Login/selectors/LoginSelector";
import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import { renderImage } from "../../utils/imageUtils";
import {
  markMessageAsRead,
  updateLastMessage,
  getSelectedUserChat,
  getLatestChatMsg,
} from "../../configs/network/apis";

import ChatInput from "./ChatInput";
import RatingsModal from "components/molecules/Ratings";
import UploadModal from "./UploadModal";
import { images } from "../../assets/images";
import { getBase64, hideLoader, showLoader, showNotification } from "../../utils/commonUtils";
import ChatSettings from "./ChatSettings";
import ChatScroller from "./ChatScroller";
import useIsMobile from "hooks/useIsMobile";

const ChatBox = ({ selectedChatUser, blockedUsers, setBlockedUsers, getChatUsers }) => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const [scrollableDivHeight, setScrollableDivHeight] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [showRatingModal, setShowRatingModal] = useState(false);
  const navigate = useNavigate();

  const isRatingDone = useSelector(LoginSelector.getUserRatingStatus);

  const shouldShowRatingModal = messages.length > 0 && !isRatingDone;

  const scrollableDivRef = useRef();
  const scrollViewRef = useRef();

  //TO-DO: make userId/uid uniform across all user details
  const isBlocked =
    loggedInUser?.blockedIds?.includes(selectedChatUser?.userId || selectedChatUser?.uid) || false;
  const isBlockedBy =
    loggedInUser?.blockedByIds?.includes(selectedChatUser?.userId || selectedChatUser?.uid) ||
    false;
  const isMobile = useIsMobile();

  let data;
  if (selectedChatUser?.userType === JOB_SEEKER) {
    data = {
      name: selectedChatUser?.fullName,
      image: selectedChatUser?.photoURL,
      defaultImage: "user",
    };
  } else {
    data = {
      name: selectedChatUser?.companyName || selectedChatUser?.fullName,
      image: selectedChatUser?.photoURL,
      defaultImage: "job",
    };
  }

  let employerId, jobSeekerId;
  if (loggedInUser?.userType === EMPLOYER) {
    employerId = loggedInUser?.uid;
    jobSeekerId = selectedChatUser?.userId || selectedChatUser?.uid;
  } else {
    employerId = selectedChatUser?.userId || selectedChatUser?.uid;
    jobSeekerId = loggedInUser?.uid;
  }

  useEffect(() => {
    if (shouldShowRatingModal) {
      setShowRatingModal(true);
    }
  }, [messages]);

  //Get Height of ChatDiv
  useEffect(() => {
    if (scrollableDivRef.current) {
      setScrollableDivHeight(scrollableDivRef.current.clientHeight);
    }
  }, [scrollableDivRef.current]);

  useEffect(() => {
    if (!isBlocked) {
      getChat();
    }
    return () => {
      setMessages([]);
    };
  }, [employerId, jobSeekerId]);

  useEffect(() => {
    let latestMsgInterval = setInterval(getLatestMessage, 60000);
    return () => {
      clearInterval(latestMsgInterval);
    };
  }, [messages]);

  useEffect(() => {
    markLastMessageRead();
  }, [employerId, jobSeekerId]);

  const getChat = async () => {
    try {
      const res = await getSelectedUserChat({
        userId: selectedChatUser?.userId || selectedChatUser?.uid,
      });
      setMessages(res?.data?.length > 0 ? res.data : []);
      if (scrollViewRef.current) {
        scrollViewRef.current.scrollTop = 0;
      }
    } catch (error) {
      const errorMsg = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Something went wrong please try again.";
      showNotification({
        alertProps: {
          severity: "error",
          children: errorMsg,
        },
      });
    }
  };

  const markLastMessageRead = async () => {
    await markMessageAsRead({
      senderId: selectedChatUser?.userId || selectedChatUser?.uid,
    });
  };

  const onSend = async (message) => {
    const msgRes = await updateLastMessage({
      receiverId: selectedChatUser?.userId || selectedChatUser?.uid,
      message,
    });

    if (msgRes?.data?.chat?.sent && msgRes?.data?.chat?.text) {
      setMessages([...messages, msgRes?.data?.chat]);
    }
  };

  const getLatestMessage = async () => {
    if (messages?.length > 0) {
      const latestMsgRes = await getLatestChatMsg({
        userId: selectedChatUser?.userId || selectedChatUser?.uid,
        offset: messages?.length,
      });
      if (latestMsgRes?.data?.length > 0) {
        setMessages([...messages, ...latestMsgRes.data]);
      }
    }
  };

  const onUploadAttachments = async (file) => {
    showLoader();
    const attachment = await getBase64(file);
    const attachRes = await updateLastMessage({
      receiverId: selectedChatUser?.userId || selectedChatUser?.uid,
      message: file.name,
      isFile: true,
      attachment,
      attachmentName: file.name,
    });
    if (attachRes?.data?.chat?.sent && attachRes?.data?.chat?.text) {
      setMessages([...messages, attachRes?.data?.chat]);
    }
    hideLoader();
    hideUploadModal();
  };

  const showUploadModal = () => {
    setIsUploadModalOpen(true);
  };

  const hideUploadModal = () => {
    setIsUploadModalOpen(false);
  };

  const onClickBack = () => {
    navigate(-1);
  };

  return (
    <>
      <ChatWrapper>
        <SelectedUserDetailHeader>
          <NameRow>
            <Icon
              name="back"
              size={24}
              style={{ display: isMobile ? "block" : "none", marginRight: "10px" }}
              onClick={onClickBack}
            />
            <Avatar
              sx={{ width: 42.5, height: 42.5 }}
              src={renderImage(data.image, data.defaultImage)}
            >
              <img
                alt={data?.name}
                src={images.defaults[data.defaultImage]}
                style={{ width: 42.5, height: 42.5 }}
              />
            </Avatar>
            <Text size="xs" weight="medium" style={{ marginLeft: spacing.xs }}>
              {data.name}
            </Text>
          </NameRow>
          <div>
            <ChatSettings
              isBlocked={isBlocked}
              userId={selectedChatUser?.userId || selectedChatUser?.uid}
              selectedChatUser={selectedChatUser}
              blockedUsers={blockedUsers}
              setBlockedUsers={setBlockedUsers}
              getChatUsers={getChatUsers}
            />
          </div>
        </SelectedUserDetailHeader>
        <Divider />
        <Chats ref={scrollableDivRef}>
          {scrollableDivHeight && messages?.length > 0 && (
            <ChatScroller
              ref={scrollViewRef}
              {...{ scrollableDivHeight, isBlocked, isBlockedBy, messages }}
            />
          )}
        </Chats>
        <ChatInput
          isBlocked={isBlocked}
          isBlockedBy={isBlockedBy}
          onSend={onSend}
          showUploadModal={showUploadModal}
          aiGeneratorInput={{
            jobSeekerId,
            employerId,
          }}
        />
      </ChatWrapper>
      {isUploadModalOpen && (
        <UploadModal
          isUploadModalOpen={isUploadModalOpen}
          showUploadModal={showUploadModal}
          hideUploadModal={hideUploadModal}
          onUploadAttachments={onUploadAttachments}
          uploadButtonName="Send"
          isResume={false}
        />
      )}
      <RatingsModal isOpen={showRatingModal} setShowRatingModal={setShowRatingModal} />
    </>
  );
};

ChatBox.propTypes = {
  selectedChatUser: PropTypes.object.isRequired,
  blockedUsers: PropTypes.array.isRequired,
  setBlockedUsers: PropTypes.func.isRequired,
  getChatUsers: PropTypes.func.isRequired,
};

export default ChatBox;

const ChatWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
`;

const SelectedUserDetailHeader = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${spacing.xs} ${spacing.s};
  box-shadow: 0 5px 5px 0px rgba(0, 0, 0, 0.1);
`;

const NameRow = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const Chats = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 ${spacing.s};
  overflow: auto;
`;
