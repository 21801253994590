import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Button, FormHelperText, Grid } from "@mui/material";
import { useFormik } from "formik";
import { styled } from "@mui/system";
import * as yup from "yup";

import useIsMobile from "../../../../../hooks/useIsMobile";
import { showLoader } from "../../../../../utils/commonUtils";
import { userUpdate } from "../../../../Login/actions/LoginActions";

import { Text, TextInput, SingleSelect, MultiSelect } from "../../../../../components/atoms";
import {
  wageExpectations,
  workingHrs,
  workExperience,
  daysAvailable,
  timeSlots,
  PREFERENCE_SELECTION_VALIDATION_TEXT,
  EXPERIENCE_SELECTION_VALIDATION_TEXT,
} from "../constants";
import * as FormConstants from "../constants";

import { colors, spacing, styleUtils } from "../../../../../styles";

const EditPreferences = (props) => {
  const { handleEdit, userData } = props;
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const validate = yup.object({
    selectedWageExpectations: yup
      .array()
      .required("Please select wage expectation")
      .min(1, PREFERENCE_SELECTION_VALIDATION_TEXT),
    selectedWorkingHrs: yup
      .array()
      .required("Please select working hours")
      .min(1, PREFERENCE_SELECTION_VALIDATION_TEXT),
    selectedworkExperience: yup
      .array()
      .required("Please select work experience")
      .min(1, EXPERIENCE_SELECTION_VALIDATION_TEXT),

    selectedDays: yup
      .array()
      .required("Please select days/week")
      .min(1, PREFERENCE_SELECTION_VALIDATION_TEXT),
    selectedTimeSlots: yup
      .array()
      .required("Shift is required")
      .min(1, PREFERENCE_SELECTION_VALIDATION_TEXT),
  });

  const handleEditPreferences = (values) => {
    showLoader();
    handleEdit("preferences", "");
    dispatch(
      userUpdate({
        wageExpectation: values.selectedWageExpectations,
        workingHours: values.selectedWorkingHrs,
        experience: values.selectedworkExperience,
        daysPerWeek: values.selectedDays,
        timeSlot: values.selectedTimeSlots,
        note: values.note,
      }),
    );
  };

  const formProps = useFormik({
    initialValues: {
      selectedWageExpectations: userData?.wageExpectation,
      selectedworkExperience: userData?.experience,
      selectedWorkingHrs: userData?.workingHours,
      selectedDays: userData?.daysPerWeek,
      selectedTimeSlots: userData?.timeSlot,
      note: userData?.note,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      handleEditPreferences(values);
    },
  });

  const handleWageExpectation = (value) => {
    formProps.setFieldValue("selectedWageExpectations", value);
  };

  const handleWorkingHrs = (value) => {
    formProps.setFieldValue("selectedWorkingHrs", value);
  };

  const handleWorkExperience = (value) => {
    let val = value === undefined ? [] : [value];
    formProps.setFieldValue("selectedworkExperience", val);
  };

  const handleDaysPerWeek = (value) => {
    formProps.setFieldValue("selectedDays", value);
  };

  const handleSlotsChange = (value) => {
    formProps.setFieldValue("selectedTimeSlots", value);
  };
  return (
    <>
      <Grid container sx={{ my: spacing.xs, flex: 1, overflow: "auto" }}>
        <Grid item xs={12} sm={12} md={6} sx={{ px: spacing.xs, mt: spacing.xs, mx: spacing.m }}>
          <Text size="s" color="#383838" sx={{ marginBottom: spacing.xxxs }}>
            {FormConstants.WAGE_EXPECTATION} *
          </Text>
          <SelectContainer>
            <MultiSelect
              selectOptions={wageExpectations}
              prevSelectedOptions={formProps.values.selectedWageExpectations}
              handleSelect={handleWageExpectation}
              name="wage expectation"
            />
          </SelectContainer>
          {Boolean(formProps.errors.selectedWageExpectations) && (
            <FormHelperText sx={{ color: "red" }}>
              {formProps.errors.selectedWageExpectations}
            </FormHelperText>
          )}
          <Text size="s" color="#383838" sx={{ marginBottom: spacing.xxxs }}>
            {FormConstants.HOURS} *
          </Text>
          <SelectContainer>
            <MultiSelect
              selectOptions={workingHrs}
              prevSelectedOptions={formProps.values.selectedWorkingHrs}
              handleSelect={handleWorkingHrs}
              name="workinghours"
            />
          </SelectContainer>
          {Boolean(formProps.errors.selectedWorkingHrs) && (
            <FormHelperText sx={{ color: "red" }}>
              {formProps.errors.selectedWorkingHrs}
            </FormHelperText>
          )}
          <Text size="s" color="#383838" sx={{ marginBottom: spacing.xxxs }}>
            {FormConstants.WORK_EXPERIENCE} *
          </Text>
          <SelectContainer>
            <SingleSelect
              options={workExperience}
              prevSelectedOptions={formProps.values?.selectedworkExperience[0]}
              handleSelect={handleWorkExperience}
              name="selectedworkExperience"
            />
          </SelectContainer>
          {Boolean(formProps.errors.selectedworkExperience) && (
            <FormHelperText sx={{ color: "red" }}>
              {formProps.errors.selectedworkExperience}
            </FormHelperText>
          )}
          <Text size="s" color="#383838" sx={{ marginBottom: spacing.xxxs }}>
            {FormConstants.DAYS_WEEK} *
          </Text>
          <SelectContainer>
            <MultiSelect
              selectOptions={daysAvailable}
              prevSelectedOptions={formProps.values.selectedDays}
              handleSelect={handleDaysPerWeek}
            />
          </SelectContainer>
          {Boolean(formProps.errors.selectedDays) && (
            <FormHelperText sx={{ color: "red" }}>{formProps.errors.selectedDays}</FormHelperText>
          )}
          <Text size="s" color="#383838" sx={{ marginBottom: spacing.xxxs }}>
            {FormConstants.SHIFT} *
          </Text>
          <SelectContainer>
            <MultiSelect
              selectOptions={timeSlots}
              prevSelectedOptions={formProps.values.selectedTimeSlots}
              handleSelect={handleSlotsChange}
              name="timeSlot"
            />
          </SelectContainer>
          {Boolean(formProps.errors.selectedTimeSlots) && (
            <FormHelperText className="profile-form-error-field">
              {formProps.errors.selectedTimeSlots}
            </FormHelperText>
          )}
          <Text size="s" color="#515458" sx={{ marginBottom: spacing.xs }}>
            {FormConstants.NOTE_FOR_EMPLOYER}
          </Text>
          <StyledTextInput
            name="note"
            value={formProps.values.note}
            placeholder={userData?.note}
            onChange={formProps.handleChange}
            sx={{ height: styleUtils.pxToRem("132px"), overflow: "auto" }}
            inputProps={{
              style: { height: "100%" },
            }}
            multiline
          />
          {formProps.touched.note && Boolean(formProps.errors.note) && (
            <FormHelperText sx={{ color: "red" }}>{formProps.errors.note}</FormHelperText>
          )}
        </Grid>
      </Grid>
      <Footer sx={{ justifyContent: isMobile ? "space-evenly" : "flex-end" }}>
        <ActionButton variant="outlined" onClick={() => handleEdit("preferences", "")}>
          {FormConstants.CANCEL}
        </ActionButton>
        <ActionButton
          variant="contained"
          disabled={!formProps.dirty}
          onClick={formProps.handleSubmit}
        >
          {FormConstants.SAVE}
        </ActionButton>
      </Footer>
    </>
  );
};

EditPreferences.propTypes = {
  handleEdit: PropTypes.func.isRequired,
  userData: PropTypes.object.isRequired,
};
export default EditPreferences;

const SelectContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${spacing.s};
`;

const StyledTextInput = styled(TextInput)(() => ({
  borderRadius: 8,
  border: `1px solid ${colors.lightBlue}`,
}));

const Footer = styled("div")`
  display: flex;
  align-items: center;
  margin-top: ${spacing.xs};
  padding: ${spacing.s} ${spacing.l};
`;

const ActionButton = styled(Button)`
  font-size: 1rem;
  margin-right: ${spacing.s};
  height: ${styleUtils.pxToRem("40px")};
  width: ${styleUtils.pxToRem("143px")};
`;
