import PropTypes from "prop-types";
import { remove } from "lodash";

import {
  Autocomplete,
  Button,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "../../../../hooks/useIsMobile";
import { Icon } from "../../../../components/atoms";

import { colors, spacing, styleUtils, textSizes, typography } from "../../../../styles";
import * as FormConsts from "./constants";

const SeekerInterestForm = ({ formik, handleNav }) => {
  const isMobile = useIsMobile();

  const setInterests = (jobRole) => {
    if (!formik?.values?.job_interests?.includes(jobRole)) {
      if (formik?.values?.job_interests?.length < 6) {
        formik.setFieldValue("job_interests", [...formik.values.job_interests, jobRole]);
      } else {
        formik.setFieldError("job_interests", "You can only select a maximum of 6 jobs interests");
      }
    }
  };

  const handleDeleteInterests = (jobRole) => {
    remove(formik?.values?.job_interests, (option) => option === jobRole);
    formik.setFieldValue("job_interests", [...formik.values.job_interests]);
  };

  return (
    <InterestsContainer sx={{ width: styleUtils.pxToRem(isMobile ? "304px" : "432px") }}>
      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("8px") }}>
        <Autocomplete
          clearOnEscape={true}
          disablePortal
          forcePopupIcon={false}
          options={FormConsts.ALL_JOB_ROLES}
          onChange={(event, newValue) => {
            if (newValue) {
              setInterests(newValue);
            } else {
              //No code here
            }
          }}
          placeholder="Add your interests"
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                width: styleUtils.pxToRem(isMobile ? "304px" : "432px"),
                height: styleUtils.pxToRem("42px"),
              }}
            />
          )}
          sx={{
            width: styleUtils.pxToRem(isMobile ? "304px" : "432px"),
            height: styleUtils.pxToRem("42px"),
            borderColor: "#E0E0E0",
            "& .MuiOutlinedInput-root": {
              padding: "0px 0px 0px 5px",
            },
            "& .MuiOutlinedInput-root:hover": {
              borderColor: "#E0E0E0",
            },
            "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
              borderBottom: "1px solid #E0E0E0",
            },
            "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
              backgroundColor: colors.selectedBlue,
            },
          }}
        />
        {Boolean(formik.errors.job_interests) && (
          <FormHelperText className="profile-form-error-field">
            {formik.errors.job_interests}
          </FormHelperText>
        )}
      </FormControl>
      <InterestsFlexBox sx={{ marginTop: spacing.l }}>
        {formik?.values?.job_interests?.map((jobRole, index) => {
          return (
            <Chip
              label={jobRole}
              key={index}
              variant="contained"
              deleteIcon={<Icon size={15} name="close-white" />}
              onDelete={() => {
                handleDeleteInterests(jobRole);
              }}
              sx={{
                height: 34,
                margin: spacing.xs,
                backgroundColor: "#2C4895",
                borderRadius: styleUtils.pxToRem("17px"),
                "& .MuiChip-label": {
                  fontSize: textSizes.s,
                  fontWeight: typography.semibold,
                  color: colors.white,
                  fontFamily: "Mulish",
                },
                border: "1px solid #2C4895",
              }}
            />
          );
        })}
      </InterestsFlexBox>
      <InterestsFlexBox sx={{ marginTop: spacing.xl }}>
        {FormConsts.TOP_JOB_ROLES.map((jobRole, index) => {
          if (!formik?.values?.job_interests?.includes(jobRole)) {
            return (
              <Chip
                label={jobRole}
                key={index}
                variant="outlined"
                onClick={() => setInterests(jobRole)}
                sx={{
                  height: 34,
                  margin: spacing.xs,
                  borderRadius: styleUtils.pxToRem("17px"),
                  "& .MuiChip-label": {
                    fontSize: textSizes.s,
                    fontWeight: typography.semibold,
                    color: "#2C4895",
                    fontFamily: "Mulish",
                  },
                  border: "1px solid #2C4895",
                  "& .MuiChip-clickable:hover": {
                    backgroundColor: colors.white,
                  },
                }}
              />
            );
          }
          return null;
        })}
      </InterestsFlexBox>

      <FormsBtnContainer sx={{ marginTop: styleUtils.pxToRem(isMobile ? "109px" : "64px") }}>
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => handleNav(false)}
        />
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "161px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
        >
          {FormConsts.SIGNUP_NEXT_BTN}
        </Button>
      </FormsBtnContainer>
    </InterestsContainer>
  );
};

SeekerInterestForm.propTypes = {
  formik: PropTypes.object.isRequired,
  handleNav: PropTypes.func.isRequired,
};

export default SeekerInterestForm;

const InterestsContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

const InterestsFlexBox = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;
