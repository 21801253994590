import { memo, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

import Text from "../Text";

const MultiCheckbox = ({
  selectOptions,
  prevSelectedOptions = [],
  handleSelect,
  multi = true,
  sx,
  labelSx,
}) => {
  const selectOptionsValues = selectOptions
    .filter((item) => item.value !== "all")
    .map((item) => item.value);
  const [checkedOptions, setCheckedOptions] = useState(
    multi ? prevSelectedOptions : [prevSelectedOptions],
  );

  useEffect(() => {
    setCheckedOptions(multi ? prevSelectedOptions : [prevSelectedOptions]);
  }, [prevSelectedOptions]);

  const handleChange = (value) => {
    if (multi) {
      if (value === "all") {
        handleSelect(
          checkedOptions.length >= selectOptionsValues.length
            ? []
            : [...selectOptionsValues, "all"],
        );
        return;
      }
      if (checkedOptions.includes(value)) {
        // handling "removing" check case and removing "all"
        handleSelect(
          checkedOptions.filter((item) => {
            return !(item === value || item === "all");
          }),
        );
        return;
      } else {
        const currentSelectedOptions = [...checkedOptions, value];
        if (currentSelectedOptions.length === selectOptionsValues.length) {
          // handling case of all items being selected, but "all" option is not there
          handleSelect([...currentSelectedOptions, "all"]);
        } else {
          handleSelect(currentSelectedOptions);
        }
        return;
      }
    }
    //remove preselected value for single select checkbox
    handleSelect(checkedOptions.includes(value) ? 0 : value);
  };

  return (
    <FormGroup sx={{ ...sx }}>
      {selectOptions.map((data, index) => (
        <FormControlLabel
          key={index}
          onChange={() => handleChange(data.value)}
          control={
            <Checkbox
              checked={checkedOptions.includes(data.value)}
              sx={{ height: 34, color: "#A2A2A2" }}
            />
          }
          label={
            <Text size="s" weight="medium" sx={{ transform: "translateY(1.5px)" }}>
              {data.label}
            </Text>
          }
          sx={{ ...labelSx }}
        />
      ))}
    </FormGroup>
  );
};

MultiCheckbox.propTypes = {
  selectOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  prevSelectedOptions: PropTypes.arrayOf(PropTypes.object),
  handleSelect: PropTypes.func.isRequired,
  multi: PropTypes.bool,
  sx: PropTypes.object,
  labelSx: PropTypes.object,
};

export default memo(MultiCheckbox);
