import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Container, Card, Button } from "@mui/material";
import { Text, Icon } from "../components/atoms";
import LoginSelector from "../containers/Login/selectors/LoginSelector";

import { getCurrentLocation, getLocationDetails } from "../utils/locationUtils";
import {
  userLocationUpdate,
  userIsLocationUpdating,
} from "../containers/Login/actions/LoginActions";

import { images } from "../assets/images";
import { spacing, colors } from "../styles";

const LocationCheckModal = () => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(true);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const geoLocationPermision = () => {
    setShowModal(false);
    dispatch(userIsLocationUpdating(true));
    getCurrentLocation(
      async (location) => {
        const { lat, lng } = location;
        const locationText = await getLocationDetails(lat, lng);
        dispatch(userLocationUpdate({ ...location, locationName: locationText }));
      },
      () => {
        dispatch(userIsLocationUpdating(false));
      },
    );
  };

  const closeLocationModal = () => {
    setShowModal(false);
  };

  return (
      <Modal open={showModal} style={{ backdropFilter: "blur(2px)" }}>
        <Container sx={{ mt: spacing.xxl }}>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "384px",
              width: "293px",
              alignItems: "center",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              p: 2,
              overflowY: "auto",
            }}
          >
            <Icon
              size={16}
              name="close"
              stroke="#808080"
              onClick={closeLocationModal}
              style={{
                float: "right",
                cursor: "pointer",
                marginLeft: "auto",
              }}
            />
            <img
              src={images.locationCheck}
              width={159}
              height={141}
              alt="locationCheck"
              style={{ marginTop: spacing.s }}
            />
            <Text
              size="m"
              colors={colors.darkBlack}
              style={{
                marginTop: spacing.l,
              }}
            >
              Let EmployD access your location
            </Text>
            <Text size="m" colors={colors.darkBlack}>
              {loggedInUser?.userType === "employer"
                ? " to find jobseekers around you"
                : " to find jobs closest to you"}
            </Text>
            <Button
              variant="contained"
              onClick={geoLocationPermision}
              sx={{ width: "auto", px: spacing.xxl, marginTop: spacing.xxxl }}
            >
              Allow location services
            </Button>
          </Card>
        </Container>
      </Modal>
  );
};

export default LocationCheckModal;
