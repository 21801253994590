import * as yup from "yup";

export const addJobSchema = yup.object({
  jobTitle: yup.string().required("Please enter job title."),
  jobLocation: yup.string().required("Please enter job location."),
  note: yup.string().required("Please enter note."),
  wageExpectation: yup
    .array()
    .min(1)
    .required("Please select hourly wage rate."),
  workingHours: yup.array().min(1).required("Please select working hours."),
  min_age: yup.string().required("Please select minimum age."),
  timeSlot: yup.array().min(1).required("Please select timeslot."),
  experience: yup.array().min(1).required("Please select experience."),
  daysSelected: yup.array().min(1).required("Please select days/week."),
});


export const jobDescriptionSchema = yup.object({
  jobDescription: yup
    .string()
    .required("Please enter description.")
    .test(
      "is-empty",
      "Please enter description.",
      (val) => val !== "<p><br></p>"
    )
});
