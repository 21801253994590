import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import { Box, Button } from "@mui/material";
import { colors, spacing } from "styles";

const MultiSelect = ({
  isDisabled = false,
  handleSelect,
  name,
  prevSelectedOptions,
  selectOptions,
  variant = "outlined",
  style,
}) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  useEffect(() => {
    const daysPerWeekData = cloneDeep(selectOptions);
    // This can be used in future so commenting it.
    // const daysSelectedValues = [];

    if (prevSelectedOptions && prevSelectedOptions.length > 0) {
      daysPerWeekData.forEach((day) => {
        if (prevSelectedOptions.includes(day.value)) {
          day.isSelected = true;
          // daysSelectedValues.push(day.title);
        }
      });
    }
    setSelectedOptions(daysPerWeekData);
  }, [prevSelectedOptions, selectOptions]);

  const handleClick = (item) => {
    const allSelectObj = selectedOptions.filter(
      (option) => option.value === "all"
    );
    if (item === "all") {
      const isAllSelected = allSelectObj[0].isSelected;
      selectedOptions.forEach((data) => {
        data["isSelected"] = !isAllSelected;
      });
    } else {
      selectedOptions.forEach((data) => {
        if (
          data.value === "all" &&
          allSelectObj.length > 0 &&
          allSelectObj[0].isSelected
        ) {
          data["isSelected"] = false;
        } else if (item === data.value) {
          data["isSelected"] = !data.isSelected;
        }
      });
    }

    if (
      allSelectObj.length > 0 &&
      !allSelectObj[0].isSelected &&
      selectedOptions.filter((option) => option.isSelected).length ===
        selectedOptions.length - 1
    ) {
      selectedOptions.forEach((data) => {
        if (data.value === "all") {
          data["isSelected"] = true;
        }
      });
    }
    const values = selectedOptions
      .filter((data) => data.isSelected)
      .map((item) => item.value);

    handleSelect(values);
  };
  return (
    <>
      {selectedOptions.map((data) => {
        return (
          <Box
            key={data.id}
            style={{
              marginRight: spacing.xs,
              marginTop: spacing.xs,
              marginBottom: spacing.xs,
            }}
          >
            <Button
              disabled={isDisabled}
              sx={[
                style,
                {
                  "&:hover": {
                    backgroundColor: data.isSelected
                      ? colors.primary
                      : "transparent",
                    color: data.isSelected ? colors.white : colors.primary,
                    "& .MuiTypography-root": {
                      color: colors.primary,
                    },
                  },
                },
              ]}
              name={name}
              variant={data.isSelected ? "contained" : variant}
              onClick={() => handleClick(data.value)}
            >
              {data.label}
            </Button>
          </Box>
        );
      })}
    </>
  );
};

MultiSelect.propTypes = {
  isDisabled: PropTypes.bool,
  handleSelect: PropTypes.func,
  name: PropTypes.string,
  prevSelectedOptions: PropTypes.any,
  selectOptions: PropTypes.any,
  variant: PropTypes.oneOf(["outlined", "contained"]),
  style: PropTypes.object,
};

export default MultiSelect;
