import Add from "./add.svg";
import AppMenu from "./app-menu.svg";
import ArrowDown from "./arrow-down.svg";
import ArrowBack from "./arrow-back.svg";
import ArrowBackBlack from "./arrow-back-black.svg";
import AttachmentDownload from "./attachment-download.svg";
import AttachmentSent from "./attachment-sent.svg";
import Attachment from "./attachment.svg";
import Bell from "./bell.svg";
import Close from "./close.svg";
import CollapseCardSolid from "./collapse-card-solid.svg";
import CommentO from "./comment-o.svg";
import Copy from "./copy.svg";
import DeleteBin from "./delete.svg";
import FileMine from "./file-mine.svg";
import File from "./file.svg";
import FileUpload from "./file-upload.svg";
import Group from "./group.svg";
import HeartO from "./heart-o.svg";
import Heart from "./heart.svg";
import FetchCurrentLocation from "./fetch-current-location-icon.svg";
import Image from "./image.svg";
import Edit from "./edit.svg";
import Login from "./login.svg";
import LogoFacebook from "./logo-facebook.svg";
import LogoInstagram from "./logo-instagram.svg";
import MapMarker from "./map-marker.svg";
import MapSelectMarker from "./map-select-marker.svg";
import Map from "./map.svg";
import Mute from "./mute.svg";
import MuteMine from "./mute-mine.svg";
import NoticeActive from "./notice-active.svg";
import Phone from "./phone.svg";
import Plus from "./plus.svg";
import PlusWhite from "./plus-white.svg";
import Search from "./search.svg";
import SearchWhite from "./search-white.svg";
import Send from "./send.svg";
import PeopleLocationDropdown from "./people-loc-dropdown.svg";
import SelectDoneOption from "./select-done-option.svg";
import SelectDropdown from "./select-dropdown.svg";
import SettingsSolid from "./settings-solid.svg";
import Smile from "./smile.svg";
import SortAZ from "./sort-az.svg";
import Star from "./star.svg";
import StepperActive from "./stepper-active.svg";
import StepperFilled from "./stepper-filled.svg";
import StepperInactive from "./stepper-inactive.svg";
import User from "./user.svg";
import VideoO from "./video-o.svg";
import Video from "./video.svg";
import More from "./more.svg";
import Briefcase from "./briefcase.svg";
import Application from "./applications.svg";
import Suitcase from "./suitcase.svg";
import Eye from "./eye.svg";
import Check from "./check.svg";
import Alert from "./alert.svg";
import Money from "./money.svg";
import Clock from "./clock.svg";
import Experience from "./experience.svg";
import Distance from "./distance.svg";
import Calendar from "./calendar.svg";
import Employee from "./employee.svg";
import Timeslot from "./timeslot.svg";
import StarNew from "./star-new.svg";
import CanceDialog from "./cancel_dialog.svg";
import Duplicate from "./duplicate.svg";
import PeopleCardLock from "./lock-people.svg";
import MapNavigation from "./map-navigation.svg";
import Back from "./back.svg";
import exploreJobs from "./exploreJobs.svg";
import blog from "./blog-article.svg";
import blogAuthor from "./blog-author.svg";
import blogDate from "./blog-date.svg";
import noJobsMatch from "./noJobsMatch.svg";
import Home from "./nav/home.svg";
import Work from "./nav/work.svg";
import Person from "./nav/person.svg";
import People from "./nav/people.svg";
import Error from "./error.svg";
import GreenTick from "./green-tick.svg";
import ShareJob from "./share-job.svg";
import ProfileDescription from "./profile/description.svg";
import ProfileEmail from "./profile/email.svg";
import ProfilePhone from "./profile/phone.svg";
import ProfileEdit from "./profile/edit.svg";
import ProfileVisibility from "./profile/visibility.svg";
import ProfileCopy from "./profile/copy.svg";
import ProfilePencil from "./profile/pencil.svg";
import ProfileMore from "./profile/more.svg";
import ProfileNext from "./profile/next.svg";
import ProfileLogout from "./profile/logout.svg";
import ProfileShare from "./profile/share.svg";
import jobSeekerIcon from "./job_seeker.svg";
import closeWhite from "./close-white.svg";
import listToggleIcon from "./list-toggle.svg";
import filterByIcon from "./filter-icon.svg";
import sortArrowUp from "./sort-arrow-up.svg";
import sortArrowDown from "./sort-arrow-down.svg";
import websiteLink from "./website-link.svg";
import nearMeLoc from "./near-me.svg";
import expandLess from "./expand-less.svg";
import expandMore from "./expand-more.svg";
import bookMark from "./bookmark.svg";
import removebookMark from "./removebookmark.svg";
import checkCircle from "./checkcircle.svg";
import blockUser from "./block-user.svg";
import unblockUser from "./unblock-user.svg";
import blockedUsers from "./blocked-users.svg";
import info from "./info.svg";
import phone_Iphone from "./phone_iphone_black.svg";
import correctValidation from "./correct-validation.svg";
import errorWarning from "./error-warning.svg";
import outlinedGreenCheck from "./outlinedGreenCheck.svg";
import alertSuccess from "./alerts/success.svg";
import alertInfo from "./alerts/info.svg";
import alertWarning from "./alerts/warning.svg";
import alertError from "./alerts/error.svg";
import absentAccount from "./absent-account.svg";
import jobNotFound from "./job-not-found.svg";
import changePassword from "./changePassword.svg";

export default {
  add: Add,
  "app-menu": AppMenu,
  "arrow-down": ArrowDown,
  "arrow-back": ArrowBack,
  "arrow-back-black": ArrowBackBlack,
  "attachment-download": AttachmentDownload,
  "attachment-sent": AttachmentSent,
  "fetch-current-location": FetchCurrentLocation,
  attachment: Attachment,
  bell: Bell,
  "cancel-dialog": CanceDialog,
  noJobsMatch: noJobsMatch,
  close: Close,
  "close-white": closeWhite,
  "collapse-card-solid": CollapseCardSolid,
  "comment-o": CommentO,
  copy: Copy,
  changePassword: changePassword,
  "delete-bin": DeleteBin,
  duplicate: Duplicate,
  file: File,
  "file-mine": FileMine,
  "file-upload": FileUpload,
  group: Group,
  "heart-o": HeartO,
  heart: Heart,
  image: Image,
  edit: Edit,
  login: Login,
  "logo-facebook": LogoFacebook,
  "logo-instagram": LogoInstagram,
  "map-marker": MapMarker,
  "map-select-marker": MapSelectMarker,
  map: Map,
  mute: Mute,
  "mute-mine": MuteMine,
  "notice-active": NoticeActive,
  phone: Phone,
  plus: Plus,
  search: Search,
  send: Send,
  "people-loc-dropdown": PeopleLocationDropdown,
  "select-done-option": SelectDoneOption,
  "select-dropdown": SelectDropdown,
  "settings-solid": SettingsSolid,
  smile: Smile,
  "sort-az": SortAZ,
  "sort-arrow-up": sortArrowUp,
  "sort-arrow-down": sortArrowDown,
  star: Star,
  stepperActive: StepperActive,
  stepperFilled: StepperFilled,
  stepperInactive: StepperInactive,
  user: User,
  "video-o": VideoO,
  video: Video,
  more: More,
  briefcase: Briefcase,
  application: Application,
  suitcase: Suitcase,
  eye: Eye,
  check: Check,
  alert: Alert,
  money: Money,
  clock: Clock,
  experience: Experience,
  distance: Distance,
  calendar: Calendar,
  employee: Employee,
  timeslot: Timeslot,
  "star-new": StarNew,
  "map-navigation": MapNavigation,

  "search-white": SearchWhite,
  back: Back,
  "plus-white": PlusWhite,

  //BottomNavigationIcons
  home: Home,
  work: Work,
  person: Person,
  people: People,
  error: Error,
  greenTick: GreenTick,
  shareJob: ShareJob,

  "profile-description": ProfileDescription,
  "profile-email": ProfileEmail,
  "profile-phone": ProfilePhone,
  "profile-edit": ProfileEdit,
  "profile-visibility": ProfileVisibility,
  "profile-copy": ProfileCopy,
  "profile-pencil": ProfilePencil,
  "profile-more": ProfileMore,
  "profile-next": ProfileNext,
  "profile-logout": ProfileLogout,
  "profile-share": ProfileShare,
  exploreJobs: exploreJobs,
  blog: blog,
  "list-toggle": listToggleIcon,
  "filter-by": filterByIcon,
  "job-seeker": jobSeekerIcon,
  "poeple-card-lock": PeopleCardLock,
  "blog-author": blogAuthor,
  "blog-date": blogDate,

  "near-me-loc": nearMeLoc,
  "website-link": websiteLink,
  "expand-less": expandLess,
  "expand-more": expandMore,
  bookmark: bookMark,
  "remove-bookmark": removebookMark,
  "check-circle": checkCircle,
  "block-user": blockUser,
  "unblock-user": unblockUser,
  "blocked-users": blockedUsers,
  "info-user": info,
  "phone-employer": phone_Iphone,
  "correct-validation": correctValidation,
  "error-warning": errorWarning,
  "outlined-green-check": outlinedGreenCheck,
  "alert-success": alertSuccess,
  "alert-info": alertInfo,
  "alert-warning": alertWarning,
  "alert-error": alertError,
  "job-not-found": jobNotFound,
  "absent-account": absentAccount,
};
