import { useEffect, useState } from "react";
import { Grid, Card, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import { Icon, Text } from "../../../../../components/atoms";
import { JobCard, JobDetailCard } from "../../../../../components/molecules";
import { spacing } from "../../../../../styles";
import JobSeekerSelector from "../../../selectors/JobSeekerSelector";
import {
  JOBSEEKER_FAVOURITE_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
} from "routes/routeConstants";

const ApplicationsJobDetailsView = ({
  jobsList,
  showJobDetailView,
  onClickJob,
  toggleGridView,
  isFavourite,
}) => {
  const [searchParams] = useSearchParams();
  const [selectedJob, setSelectedJob] = useState(null);
  const [isJobAbsent, setIsJobAbsent] = useState(false);

  const jobId = searchParams.get("jobId");

  const forceUpdate = useSelector(
    JobSeekerSelector.getForceUpdateJobSeekerListing
  );

  useEffect(() => {
    if (showJobDetailView && jobId && jobsList?.length > 0) {
      setSelectedJob(jobsList.find((job) => job.jobId === jobId));
    }
  }, [showJobDetailView, jobId, forceUpdate, jobsList]);

  const renderJobs = (item, index) => {
    return (
      <Grid item key={index}>
        <JobCard
          item={item}
          isFavourite={isFavourite}
          onClickJob={onClickJob}
          cardStyle={{
            marginBottom: spacing.xl,
            marginRight: spacing.s,
          }}
        />
      </Grid>
    );
  };

  return (
    <Container sx={{ my: spacing.xxl }}>
      <IconContainer>
        <Text weight="medium"></Text>
        {jobsList && jobsList.length > 0 && (
          <Icon
            name="arrow-back"
            size={42}
            style={{ cursor: "pointer" }}
            onClick={toggleGridView}
          />
        )}
      </IconContainer>
      <Grid container columnSpacing={spacing.m} mt={spacing.xxl}>
        <Grid item md={4}>
          <Card
            sx={{
              height: "80vh",
              py: spacing.l,
              pl: spacing.s,
              display: "flex",
            }}
          >
            <JobsContainer id="scrollableDiv">
              <InfiniteScroll
                dataLength={jobsList?.length || 0}
                scrollableTarget="scrollableDiv"
              >
                {jobsList?.map(renderJobs)}
              </InfiniteScroll>
            </JobsContainer>
          </Card>
        </Grid>
        <Grid item md={8}>
          <Card
            sx={{
              height: "80vh",
            }}
          >
            {showJobDetailView && selectedJob ? (
              <JobDetailCard
                item={selectedJob}
                isFavourite={isFavourite}
                setIsJobAbsent={setIsJobAbsent}
                isJobAbsent={isJobAbsent}
                fallBackPath={`${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_FAVOURITE_PAGE_ROUTE}`}
              />
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "57%",
                }}
              >
                <Text size="xl" weight="semibold">
                  No Job Selected
                </Text>
              </div>
            )}
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ApplicationsJobDetailsView;

const JobsContainer = styled("div")`
  display: flex;
  flex: 1;
  overflow: auto;
  flex-direction: column;
`;

const IconContainer = styled("div")`
  display: flex;
  height: 3rem;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.l};
`;
