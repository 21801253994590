export const EMPLOYD = "EMPLOYD";
export const SET_YOUR_PREFERENCE = " Set your Preference";
export const SET_PROFILE =
  "Set your profile preference to get tailored job recommendations";
export const ADD_PROFILE_PHOTO = "Add Profile Photo";
export const AGE = "Age";
export const WAGE_EXPECTATION = "Hourly Wage Expectations";
export const HOURS = "Hours per week";
export const NEXT = "Next";
export const WORK_EXPERIENCE = "Work experience";
export const DAYS_WEEK = "Days per week";
export const SHIFT = "Shift";
export const NOTE_FOR_EMPLOYEE = "About Me";
export const SUBMIT = "Submit";
export const SET_PREFERENCES = "Set Preferences";
export const NOTE_FOR_EMPLOYER = "About Me";
export const EDIT_PERSONAL_INFORMATION = "Edit Personal Information";
export const PREFERENCES = "PREFERENCES";
export const EDIT = "Edit";
export const PERSONAL_INFORMATION = "PERSONAL INFORMATION";
export const CANCEL = "Cancel";
export const SAVE = "Save";
export const NAME = "Name";
export const PHONE_NUMBER = "Phone No";
export const EMAIL_ID = "Email ID";
export const ENTER_YOUR_AGE = "Age";
export const ALTERNATIVE_PHONER_NUMBER = "Alternative Phone number";
export const WORK_STATUS = "Work status";
export const REFERRALS = "REFERRALS/INVITE";
export const INVITE_FRIENDS = "Invite your friends to EmployD";
export const INVITE_FREINDS_TO_EARN =
  "Invite a friend and earn $10 when your friend uses employD";
export const TOTAL_EARNED = "Total Earned";
export const SHARE_REFERRAL_LINK = "Share referral link";
export const EMPLOYER = "employer";
export const USER_PROFILE = "User Profile";
export const DELTE_ACCOUNT_HEADER = "Delete Acount";
export const DELETE_MODAL_SUBTEXT_ONE =
  "You’re about to delete EmployD Account";
export const DELETE_MODAL_SUBTEXT_TWO = "Please select a reason for deleting";
export const DELETE_MODAL_REASON_PLACEHOLDER = "Enter reason here…";
export const INACTIVE_MODAL_BTN_CANCEL = "Cancel";
export const INACTIVE_MODAL_BTN_APPLY = "Apply";
export const WORKING_HOUR_PREFERENCE_TEXT = "hours/week";
export const PREFERENCE_SELECTION_VALIDATION_TEXT =
  "Please make at least one selection";
export const EXPERIENCE_SELECTION_VALIDATION_TEXT = "Please make one selection";

export const DELETE_USER_MODAL_OPTIONS = [
  {
    option: "1",
    value: "Too Busy/Distracting",
  },
  {
    option: "2",
    value: "Created another accountg",
  },
  {
    option: "3",
    value: "Trouble getting started",
  },
  {
    option: "4",
    value: "Privacy Concerns",
  },
  {
    option: "5",
    value: "Can’t find Jobs",
  },
  {
    option: "6",
    value: "Other",
  },
];

export const DELETE_MODAL_SUBTEXT = "Are you sure you want to delete this job?";
export const DELETE_MODAL_BTN_CANCEL = "Cancel";
export const DELETE_MODAL_BTN_APPLY = "Delete";

export const wageExpectations = [
  {
    id: 1,
    label: "$5-10",
    value: "5-10",
    isSelected: false,
  },
  {
    id: 2,
    label: "$10-15",
    value: "10-15",
    isSelected: false,
  },
  {
    id: 3,
    label: "$15-20",
    value: "15-20",
    isSelected: false,
  },
  {
    id: 4,
    label: "$20-30",
    value: "20-30",
    isSelected: false,
  },
  {
    id: 5,
    label: "$30-40",
    value: "30-40",
    isSelected: false,
  },
];

export const workingHrs = [
  {
    id: 1,
    label: "00-02hrs",
    value: "0-2",
    isSelected: false,
  },
  {
    id: 2,
    label: "02-05hrs",
    value: "2-5",
    isSelected: false,
  },
  {
    id: 3,
    label: "05-10hrs",
    value: "5-10",
    isSelected: false,
  },
  {
    id: 4,
    label: "10-20hrs",
    value: "10-20",
    isSelected: false,
  },
  {
    id: 5,
    label: "20-30hrs",
    value: "20-30",
    isSelected: false,
  },
  {
    id: 6,
    label: "30-40hrs",
    value: "30-40",
    isSelected: false,
  },
];

export const workExperience = [
  {
    id: 1,
    label: "Beginner",
    value: "Beginner",
    isSelected: false,
  },
  {
    id: 2,
    label: "01-02 Yrs",
    value: "1-2",
    isSelected: false,
  },
  {
    id: 3,
    label: "02-05 Yrs",
    value: "2-5",
    isSelected: false,
  },
  {
    id: 4,
    label: "Above 05 Yrs",
    value: ">5",
    isSelected: false,
  },
];

export const daysAvailable = [
  {
    id: 1,
    label: "Mon",
    value: "Mon",
    isSelected: false,
  },
  {
    id: 2,
    label: "Tue",
    value: "Tue",
    isSelected: false,
  },
  {
    id: 3,
    label: "Wed",
    value: "Wed",
    isSelected: false,
  },
  {
    id: 4,
    label: "Thu",
    value: "Thu",
    isSelected: false,
  },
  {
    id: 5,
    label: "Fri",
    value: "Fri",
    isSelected: false,
  },
  {
    id: 6,
    label: "Sat",
    value: "Sat",
    isSelected: false,
  },
  {
    id: 7,
    label: "Sun",
    value: "Sun",
    isSelected: false,
  },
];

export const timeSlots = [
  {
    id: 1,
    label: "Morning",
    value: "Morning",
    isSelected: false,
  },
  {
    id: 2,
    label: "Afternoon",
    value: "Afternoon",
    isSelected: false,
  },
  {
    id: 3,
    label: "Evening",
    value: "Evening",
    isSelected: false,
  },
  {
    id: 4,
    label: "Night",
    value: "Night",
    isSelected: false,
  },
];

export const TOP_JOB_ROLES = [
  "Barista",
  "Cashier",
  "Server",
  "Cook",
  "Receptionist",
  "Bartender",
  "Dishwasher",
  "Customer Service",
  "Sales Associate",
  "Stocker",
  "Delivery Driver",
  "Caregiver",
  "Teller",
  "Personal Trainer",
  "Assistant Manager",
];

export const ALL_JOB_ROLES = [
  "General manager",
  "Assistant Manager",
  "Executive Chef",
  "Sous Chef",
  "Pastry Chef",
  "Kitchen Manager",
  "Line Cook",
  "Fast Food Cook",
  "Prep Cook",
  "Server",
  "Runner",
  "Busser",
  "Host",
  "Bartender",
  "Cashier",
  "Dishwasher",
  "Inventory Associate",
  "Customer Service Representative",
  "Warehouse Clerk",
  "Assistant Store Manager",
  "Inventory control Specialist",
  "Floor Manager",
  "Store Manager",
  "Buyer",
  "Department Manager",
  "Stocker",
  "Receptionist",
  "Spa/Salon Coordinator",
  "Spa Manager",
  "Colorist",
  "Spa Attendant",
  "Nail Technician",
  "Massgae Therapist",
  "Hair Stylist",
  "Spa Concierge",
  "Salon Technician",
  "Wax Specialist",
  "Esthetician",
  "Cosmetologist",
  "Dog Groomer",
  "Counter Staff",
  "Manager",
  "Opener",
  "Child Care Attendant",
  "Sales Assistant",
  "Group Fitness Instructor",
  "Personal Trainer",
  "Chiropractor",
  "Pharmacy Clerk",
  "Pharmacy Dispenser",
  "Pharmacy Assistant",
  "Pharmacist",
  "Staff Pharmacist",
  "Chemotherapy Pharmacist",
  "Home Health Aide",
  "Nursing Assistant",
  "Medical Assistant",
  "Licensed Practical Nurse",
  "Physical therapy Assistant",
  "Registered Nurse",
  "Caregivers",
  "Nanny",
  "Tax Professional",
  "Associate Team Leader",
  "Client Relationship Consultant",
  "Tax Advisor",
  "Tax Preparer Assistant",
  "Universal Branch Associate",
  "Accounting Clerks",
  "Branch Operations Manager",
  "Banking Relationship Specialist",
  "Teller",
  "Store Customer Experience Coordinator ",
  "Financial Services Associate ",
  "Assistant Branch Manager",
  "Branch Banker",
  "Universal Banker",
  "Registered Dietician",
  "Certified Nursing Assistant",
  "Food Service Worker",
  "Documentation Specialist",
  "Training Specialist",
  "Service Order Dispatcher",
  "Operations Data Analyst",
  "Senior Eligibility Manager",
  "Construction Project Coordinator  ",
  "Project Director",
  "Operations Director",
  "Material Coordinator",
  "Valet Attendent",
  "Warehouse Specialist",
  "Certified Pest Control Technician",
  "Farm worker",
  "Grower",
  "Grain elevator operator",
  "Porter",
  "Customer Service ",
  "Automotive Technician",
  "Sales Consultant",
  "Automotive Courtesy Technician",
  "Customer Specialist",
  "Diesel Technician Mechanic",
  "Outside Parts Sales",
  "Auto Mechanic",
  "Inside Parts Counter Sales",
  "Auto Body Technician",
  "Press Apprentice Mechanic",
  "Assembly Apprentice Mechanic",
  "Lot Attendant",
  "DMV Title Clerk",
  "Uber Driver",
  "Lyft Driver",
  "Delivery Driver",
  "Food Delivery",
  "Food Courier",
  "Restaurant Delivery",
  "Truck Driver",
  "Human Services Coordinator",
  "Human Services Program Assistant",
  "Bilingual Youth Advocate",
  "Case Manager ",
  "Sales Associate",
  "Administrative Assistant",
  "Box Office",
  "Theatre Team Member",
  "Lifeguard",
  "Adventure Guide",
  "Counter Server",
  "Tree Groundsman",
  "Tree Climber",
  "Housecleaners",
  "Floor Technician",
  "Shift Supervisor",
  "HVAC Service Technician",
  "Primary Janitorial Person",
  "Legal Assistant",
  "Corporate Paralegal",
  "Executive Assistant",
  "Law Firm Accountant",
  "Security Officer",
  "Security Account Manager",
  "Security Guard",
  "Inspection Tech",
  "Installation helper",
  "Field Service Technician",
  "Handyman",
  "Field Service Representative",
  "Crew Manager",
  "Housekeeping Supervisor",
  "Night Audit",
  "Maintenance Engineer",
  "House person",
  "Cook",
  "Room Attendant",
  "Food & Beverage Manager",
  "Chef",
  "Guest Service Agent",
];
