import React, { useState, useRef, useEffect, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Avatar,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Menu,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import useIsMobile from "../../../../hooks/useIsMobile";

import {
  getBase64,
  getSanitizedFullName,
  isKeyAllowedInFullName,
} from "../../../../utils/commonUtils";
import { Icon, Text, TextInput } from "../../../../components/atoms";
import ImageUploadStatus from "../../../../components/molecules/ImageUploadStatus";
import { images } from "../../../../assets/images";
import { colors, spacing, styleUtils, textSizes } from "../../../../styles";
import * as FormConsts from "./constants";
import "./SeekerSignUpStyle.css";

const MuiPhoneNumber = lazy(() => import("material-ui-phone-number"));

/**
 * Job Seeker SignUp Form view component
 * @returns Job Seeker Sign Up Form
 */
const SeekerSignUpForm = (props) => {
  const [imgData, setImgData] = useState(null);
  const [showPwd, setShowPwd] = useState(false);
  const [showConfirmPwd, setShowConfirmPwd] = useState(false);
  const phoneInput = useRef(null);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const {
    formik,
    formDetails,
    imageStatus,
    handleImageStatus,
    socialSignUpDetails: { email, name, isSocialLogin, image, idToken },
  } = props;

  useEffect(() => {
    formik.setFieldValue("email", email);
    if (isSocialLogin) {
      formik.setFieldValue("fullName", name);
      formik.setFieldValue("password", idToken);
      formik.setFieldValue("confirmPassword", idToken);
      formik.setFieldValue("photoURL", image);
    }

    const timer = setTimeout(() => {
      setAnchorEl(null);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleBase64Change = async (targetFile) => {
    const encodedFile = await getBase64(targetFile);
    formik.setFieldValue("photoURL", encodedFile);
    setImgData(encodedFile);
  };

  useEffect(() => {
    if (!formik.values.photoURL && formDetails.image) {
      if (typeof formDetails.image === "string") {
        formik.setFieldValue("photoURL", formDetails.image);
        setImgData(formDetails.image);
      } else {
        handleBase64Change(formDetails.image);
      }
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = !imgData && !formik?.values?.photoURL ? Boolean(anchorEl) : false;
  const initialRender = useRef(true);

  const onLoad = (event) => {
    initialRender.current = false;
    setAnchorEl(event.currentTarget);
  };

  const handleImageUpload = async (target) => {
    handleImageStatus("loading");
    try {
      const encodedFile = await getBase64(target.files[0]);
      formik.setFieldValue("photoURL", encodedFile);
      setImgData(encodedFile);
      handleImageStatus("success");
    } catch (error) {
      handleImageStatus("error");
    }
  };

  const onChangePicture = ({ target }) => {
    handleImageUpload(target);
  };

  const handleShowPwd = () => {
    setShowPwd(!showPwd);
  };

  const handleShowConfirmPwd = () => {
    setShowConfirmPwd(!showConfirmPwd);
  };

  const handlePhone = (phoneValue) => {
    const {
      current: {
        state: {
          selectedCountry: { dialCode },
        },
      },
    } = phoneInput;

    formik.setFieldValue("countryCode", dialCode);
    if (dialCode) {
      formik.setFieldValue("phoneNumber", phoneValue);
    }
  };

  const handleFullName = (event) => {
    formik.setFieldValue("fullName", getSanitizedFullName(event.target.value));
  };

  const handleFullNameBlur = (event) => {
    trimFullNameOnBlur();
    formik.handleBlur(event);
  };

  const trimFullNameOnBlur = () => {
    formik.setFieldValue("fullName", formik.values.fullName.trim());
  };

  return (
    <React.Fragment>
      <ImgContainer
        {...(initialRender.current && {
          onLoad,
        })}
      >
        <Avatar
          src={imgData || formik.values?.photoURL || images.defaults.user}
          sx={{ width: 84, height: 84 }}
          imgProps={{
            referrerPolicy: "no-referrer",
          }}
        />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: spacing.xs,
          }}
        >
          <Button
            variant="outlined"
            component="label"
            sx={{
              fontSize: textSizes.s,
              marginTop: spacing.m,
              marginLeft: spacing.s,
              textTransform: "none",
              width: styleUtils.pxToRem("158px"),
              height: styleUtils.pxToRem("34px"),
            }}
            disabled={imageStatus === "loading" ? true : false}
          >
            {FormConsts.FORM_ADD_PROFILE_PHOTO}
            <input
              type="file"
              hidden
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangePicture}
            />
          </Button>
          <ImageUploadStatus status={imageStatus} />
        </div>
      </ImgContainer>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: 280,
            backgroundColor: "#F0F4FF",
            borderWidth: 0,
            padding: spacing.xs,
          },
        }}
      >
        <Text size="s" color={colors.black} style={{ textAlign: "center" }}>
          Adding a display picture has a 33% higher chance of getting contacted by an employer
        </Text>
      </Menu>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {FormConsts.FORM_NAME_LABEL}
        </FormLabel>
        <TextInput
          name="fullName"
          value={formik.values.fullName}
          onChange={handleFullName}
          onBlur={handleFullNameBlur}
          onKeyDown={(e) => {
            if (!isKeyAllowedInFullName(e.key)) {
              e.preventDefault(); // Prevent input
            }
          }}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          placeholder="Enter your full name"
          className="seeker-form-input-field"
        />
        {formik.touched.fullName && Boolean(formik.errors.fullName) && (
          <FormHelperText className="form-error-field">{formik.errors.fullName}</FormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {FormConsts.FORM_EMAIL_LABEL}
        </FormLabel>
        <TextInput
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          placeholder={FormConsts.FORM_EMAIL_PLACEHOLDER}
          inputProps={{ readOnly: true }}
          className="seeker-form-input-field"
        />
        {formik.touched.email && Boolean(formik.errors.email) && (
          <FormHelperText className="form-error-field">{formik.errors.email}</FormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {FormConsts.FORM_PHONE_LABEL}
        </FormLabel>
        <Suspense fallback={null}>
          <MuiPhoneNumber
            autoFormat={false}
            countryCodeEditable={false}
            defaultCountry={"us"}
            onlyCountries={["us", "in"]}
            disableAreaCodes={true}
            name="phoneNumber"
            onChange={handlePhone}
            onBlur={formik.handleBlur}
            placeholder="Enter your phone number"
            ref={phoneInput}
            value={formik.values.phoneNumber}
            sx={{
              "& .MuiInputBase-root": {
                border: "1px solid #E0E0E0",
                borderRadius: styleUtils.pxToRem("4px"),
              },
              "& .MuiInputBase-root:hover": {
                border: "1px solid #2B4895",
                borderRadius: styleUtils.pxToRem("4px"),
              },
              "& .MuiInputBase-root:before": {
                borderBottom: "none",
                transition: "none",
              },
              "& .MuiInputBase-root:after": {
                borderBottom: "none",
              },
              "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
              "& .MuiButtonBase-root": {
                borderRight: `1px solid ${colors.stroke}`,
                paddingRight: spacing.s,
                borderRadius: 0,
              },
            }}
          />
        </Suspense>
        {formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) && (
          <FormHelperText className="form-error-field">{formik.errors.phoneNumber}</FormHelperText>
        )}
      </FormControl>
      {!isSocialLogin && (
        <>
          <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {FormConsts.FORM_PWD_LABEL}
            </FormLabel>
            <TextInput
              name="password"
              type={showPwd ? "text" : "password"}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPwd} sx={{ cursor: "pointer" }}>
                    {showPwd ? (
                      <Visibility sx={{ color: "#848484" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#848484" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Enter the password"
              className="seeker-form-input-field"
            />
            {formik.touched.password && Boolean(formik.errors.password) && (
              <FormHelperText className="form-error-field">{formik.errors.password}</FormHelperText>
            )}
          </FormControl>
          <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
            <FormLabel
              sx={{
                color: colors.labelBlack,
                marginBottom: styleUtils.pxToRem("6px"),
              }}
            >
              {FormConsts.FORM_CONFIRM_PWD_LABEL}
            </FormLabel>
            <TextInput
              name="confirmPassword"
              type={showConfirmPwd ? "text" : "password"}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleShowConfirmPwd} sx={{ cursor: "pointer" }}>
                    {showConfirmPwd ? (
                      <Visibility sx={{ color: "#848484" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "#848484" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              placeholder="Enter the password"
              className="seeker-form-input-field"
            />
            {formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword) && (
              <FormHelperText className="form-error-field">
                {formik.errors.confirmPassword}
              </FormHelperText>
            )}
          </FormControl>
        </>
      )}
      <FormControl required variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            marginBottom: styleUtils.pxToRem("6px"),
            color: colors.labelBlack,
          }}
        >
          {FormConsts.FORM_AGE_LABEL}
        </FormLabel>
        <TextInput
          placeholder={FormConsts.FORM_AGE_PLACEHOLDER}
          name="age"
          value={formik.values.age}
          onChange={formik.handleChange}
          inputProps={{ type: "number" }}
          onWheel={(e) => e.target.blur()}
          onBlur={formik.handleBlur}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value, 10)).toString().slice(0, 2);
          }}
          className="seeker-form-input-field"
        />
        {formik.touched.age && Boolean(formik.errors.age) && (
          <FormHelperText className="profile-form-error-field">{formik.errors.age}</FormHelperText>
        )}
      </FormControl>

      <FormsBtnContainer>
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Button
          variant="contained"
          onClick={formik.handleSubmit}
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "161px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
        >
          {FormConsts.SIGNUP_NEXT_BTN}
        </Button>
      </FormsBtnContainer>
    </React.Fragment>
  );
};

SeekerSignUpForm.propTypes = {
  formik: PropTypes.object.isRequired,
  formDetails: PropTypes.object.isRequired,
  imageStatus: PropTypes.string,
  handleImageStatus: PropTypes.func,
  socialSignUpDetails: PropTypes.object,
};

export default SeekerSignUpForm;

const ImgContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: ${styleUtils.pxToRem("64px")};
`;
