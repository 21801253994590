import { useState, useRef, useEffect, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import {
  Avatar,
  Button,
  Menu,
  FormControl,
  FormLabel,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import SignUpSelector from "containers/SignUp/selectors/SignUpSelector";

import useIsMobile from "../../../../../../hooks/useIsMobile";
import ImageUploadStatus from "../../../../../../components/molecules/ImageUploadStatus";
import { Icon, Text, WebsiteInput, TextInput } from "../../../../../../components/atoms";
import { images } from "../../../../../../assets/images";
import { colors, spacing, textSizes, styleUtils } from "../../../../../../styles";
import {
  getBase64,
  getSanitizedFullName,
  isKeyAllowedInFullName,
} from "../../../../../../utils/commonUtils";
import { employerVeirfierApi } from "configs/network/apis";
import { EMPLOYER_SIGNUP_FORM_ONE } from "../../constants";

const MuiPhoneNumber = lazy(() => import("material-ui-phone-number"));

/**
 * Employer SignUp Form One view component
 */
const EmployerSignUpFormOne = (props) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const phoneInput = useRef(null);
  const initialRender = useRef(true);

  const [imgData, setImgData] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const {
    formik,
    imageStatus,
    isPhoneSignUp,
    countryCodeValue,
    phoneNumValue,
    existingEmpValidations,
    handleImageStatus,
    handleCompanyValidation,
    websiteInputRef,
    socialSignUpDetails: { email, name, isSocialLogin, image },
    showValidationIcon,
    setShowValidationIcon,
  } = props;

  const showCompanyValidationLoader = useSelector(SignUpSelector.showEmployerVerificationLoader);
  const isEmployerVerificationSuccess = useSelector(SignUpSelector.isEmployerVerificationSuccess);

  /******* Keeping it commented if later on we need to make button disabled *********/
  /*
    const disableSubmit =
    showCompanyValidationLoader ||
    existingEmpValidations == null ||
    existingEmpValidations?.isCompanyExist;
  */

  useEffect(() => {
    formik.setFieldValue("email", email);
    if (isSocialLogin) {
      formik.setFieldValue("fullName", name);
      if (image) {
        handleBase64Change(image);
      }
    } else if (isPhoneSignUp) {
      formik.setFieldValue("countryCode", countryCodeValue);
      formik.setFieldValue("phoneNumber", phoneNumValue);
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnchorEl(null);
    }, 4000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleBase64Change = async (targetFile) => {
    const encodedFile = await getBase64(targetFile);
    formik.setFieldValue("photoURL", encodedFile);
    setImgData(encodedFile);
  };

  const handlePhone = (phoneValue) => {
    const {
      current: {
        state: {
          selectedCountry: { dialCode },
        },
      },
    } = phoneInput;
    formik.setFieldValue("countryCode", dialCode);

    if (dialCode) {
      formik.setFieldValue("phoneNumber", phoneValue);
    }
  };

  const onChangePicture = ({ target }) => {
    handleImageUpload(target);
  };

  const handleImageUpload = async (target) => {
    handleImageStatus("loading");
    try {
      const encodedFile = await getBase64(target.files[0]);
      formik.setFieldValue("photoURL", encodedFile);
      setImgData(encodedFile);
      handleImageStatus("success");
    } catch (error) {
      handleImageStatus("error");
    }
  };

  const onLoad = (event) => {
    initialRender.current = false;
    setAnchorEl(event.currentTarget);
  };

  const handleFullName = (event) => {
    formik.setFieldValue("fullName", getSanitizedFullName(event.target.value));
  };

  const handleFullNameBlur = (event) => {
    trimFullNameOnBlur();
    formik.handleBlur(event);
  };

  const handleEmailBlur = (event) => {
    formik.handleBlur(event);
    handleEmailExist();
  };

  const handleCompanyNameBlur = (event) => {
    formik.handleBlur(event);
    formik?.values?.companyName ? handleCompanyValidation() : handleEmptyCompany();
  };

  const trimFullNameOnBlur = () => {
    formik.setFieldValue("fullName", formik.values.fullName.trim());
  };

  const handleEmailExist = async () => {
    const { data } = await employerVeirfierApi({
      email: formik?.values?.email,
    });
    const { isEmailExist, message } = data;
    if (isEmailExist) {
      await formik.setFieldTouched("email", true);
      await formik.setFieldError("email", message);
    } else {
      //No code here
      await formik.setFieldError("email", "");
    }
  };

  const getCompanyValidationAdorment = () => {
    const isExistingCompany = existingEmpValidations?.isCompanyExist;

    const verificationIcon = isExistingCompany ? (
      <Icon size="14" name="error-warning" />
    ) : (
      <Icon size="20" name="outlined-green-check" />
    );

    if (showCompanyValidationLoader) {
      return <CircularProgress size={19} />;
    }
    if (showValidationIcon && existingEmpValidations && isEmployerVerificationSuccess) {
      return verificationIcon;
    }
    return null;
  };

  const handleEmptyCompany = () => {
    formik.setFieldTouched("companyName", true);
  };

  const handleCompanyNameChange = (e) => {
    setShowValidationIcon(false);
    formik.handleChange(e);
  };

  return (
    <>
      <ImgContainer
        {...(initialRender.current && {
          onLoad,
        })}
      >
        <Avatar
          src={imgData || images.defaults.job}
          sx={{ width: 84, height: 84 }}
          imgProps={{
            referrerPolicy: "no-referrer",
          }}
        />
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: spacing.xs,
          }}
        >
          <Button
            variant="outlined"
            component="label"
            style={{
              fontSize: textSizes.s,
              marginTop: spacing.m,
              marginLeft: spacing.s,
              textTransform: "none",
              width: "fit-content",
              height: "fit-content",
            }}
            disabled={imageStatus === "loading"}
          >
            {EMPLOYER_SIGNUP_FORM_ONE.ADD_COMPANY_LOGO}
            <input
              type="file"
              hidden
              accept=".jpg, .jpeg, .png, .webp"
              onChange={onChangePicture}
            />
          </Button>
          <ImageUploadStatus status={imageStatus} />
        </div>
      </ImgContainer>
      <Menu
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        anchorEl={anchorEl}
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: 280,
            backgroundColor: "#F0F4FF",
            borderWidth: 0,
            padding: spacing.xs,
          },
        }}
      >
        <Text size="s" color={colors.black} style={{ textAlign: "center" }}>
          {EMPLOYER_SIGNUP_FORM_ONE.COMPANY_LOGO_TOOLTIP_TEXT}
        </Text>
      </Menu>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_ONE.LABEL_FULL_NAME}
        </FormLabel>
        <EmployerFormInput
          name="fullName"
          value={formik.values.fullName}
          onChange={handleFullName}
          onBlur={handleFullNameBlur}
          onKeyDown={(e) => {
            if (!isKeyAllowedInFullName(e.key)) {
              e.preventDefault(); // Prevent input
            }
          }}
          error={formik.touched.fullName && Boolean(formik.errors.fullName)}
          placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_FULL_NAME}
        />
        {formik.touched.fullName && Boolean(formik.errors.fullName) && (
          <EmployerFormHelperText>{formik.errors.fullName}</EmployerFormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_ONE.LABEL_EMAIL}
        </FormLabel>
        <EmployerFormInput
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          onBlur={handleEmailBlur}
          placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_EMAIL}
          inputProps={{ readOnly: !isPhoneSignUp }}
          sx={{
            "& .Mui-disabled": {
              WebkitTextFillColor: colors.textPrimary,
            },
          }}
        />
        {formik.touched.email && Boolean(formik.errors.email) && (
          <EmployerFormHelperText>{formik.errors.email}</EmployerFormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_ONE.LABEL_PHONE_NUMBER}
        </FormLabel>
        <Suspense fallback={null}>
          <MuiPhoneNumber
            placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_PHONE_NUMBER}
            autoFormat={false}
            countryCodeEditable={false}
            defaultCountry={"us"}
            onlyCountries={["us", "in"]}
            disableAreaCodes={true}
            name="phoneNumber"
            onChange={handlePhone}
            onBlur={formik.handleBlur}
            ref={phoneInput}
            value={formik.values.phoneNumber}
            inputProps={{ readOnly: isPhoneSignUp }}
            sx={{
              "& .MuiInputBase-root": {
                border: "1px solid #E0E0E0",
                borderRadius: styleUtils.pxToRem("4px"),
              },
              "& .MuiInputBase-root:hover": {
                border: "1px solid #2B4895",
                borderRadius: styleUtils.pxToRem("4px"),
              },
              "& .MuiInputBase-root:before": {
                borderBottom: "none",
                transition: "none",
              },
              "& .MuiInputBase-root:after": {
                borderBottom: "none",
              },
              "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                borderBottom: "none",
              },
              "& .MuiButtonBase-root": {
                borderRight: `1px solid ${colors.stroke}`,
                paddingRight: spacing.s,
                borderRadius: 0,
              },
            }}
          />
        </Suspense>
        {formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) && (
          <EmployerFormHelperText>{formik.errors.phoneNumber}</EmployerFormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" required sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_ONE.LABEL_COMPANY}
        </FormLabel>
        <EmployerFormInput
          name="companyName"
          value={formik.values.companyName}
          onChange={handleCompanyNameChange}
          error={formik.touched.companyName && Boolean(formik.errors.companyName)}
          placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_COMPANY}
          onBlur={handleCompanyNameBlur}
          endAdornment={getCompanyValidationAdorment()}
        />
        {formik.touched.companyName && Boolean(formik.errors.companyName) && (
          <EmployerFormHelperText>{formik.errors.companyName}</EmployerFormHelperText>
        )}
        {Boolean(!formik.errors.companyName) && existingEmpValidations?.isCompanyExist && (
          <EmployerFormHelperText>{existingEmpValidations?.message}</EmployerFormHelperText>
        )}
      </FormControl>
      <FormControl variant="standard" sx={{ marginTop: styleUtils.pxToRem("24px") }}>
        <FormLabel
          sx={{
            color: colors.labelBlack,
            marginBottom: styleUtils.pxToRem("6px"),
          }}
        >
          {EMPLOYER_SIGNUP_FORM_ONE.LABEL_WEBSITE}
        </FormLabel>
        <EmployerFormWebsiteInput
          ref={websiteInputRef}
          placeholder={EMPLOYER_SIGNUP_FORM_ONE.PLACEHOLDER_WEBSITE}
        />
      </FormControl>
      <FormsBtnContainer>
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Button
          variant="contained"
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
          onClick={formik.handleSubmit}
        >
          {EMPLOYER_SIGNUP_FORM_ONE.BUTTON_NEXT}
        </Button>
      </FormsBtnContainer>
    </>
  );
};

EmployerSignUpFormOne.propTypes = {
  formik: PropTypes.object.isRequired,
  imageStatus: PropTypes.string.isRequired,
  isPhoneSignUp: PropTypes.bool.isRequired,
  countryCodeValue: PropTypes.string.isRequired,
  phoneNumValue: PropTypes.string.isRequired,
  existingEmpValidations: PropTypes.object.isRequired,
  handleImageStatus: PropTypes.func.isRequired,
  handleCompanyValidation: PropTypes.func.isRequired,
  websiteInputRef: PropTypes.object.isRequired,
  socialSignUpDetails: PropTypes.shape({
    email: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isSocialLogin: PropTypes.bool.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
  showValidationIcon: PropTypes.bool.isRequired,
  setShowValidationIcon: PropTypes.func.isRequired,
};

export default EmployerSignUpFormOne;

const ImgContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;
const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: ${styleUtils.pxToRem("72px")};
`;
const EmployerFormInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const EmployerFormWebsiteInput = styled(WebsiteInput)`
  border: 1px solid #e0e0e0;
  border-radius: 4px;
`;
const EmployerFormHelperText = styled(FormHelperText)`
  color: #ff0000;
`;
