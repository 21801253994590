import React from "react";
import { Divider, Grid, styled } from "@mui/material";
import { useSelector } from "react-redux";

import useIsMobile from "hooks/useIsMobile";
import { useScrollRestoration } from "hooks/useScrollRestoration";

import { images } from "assets/images";
import { spacing, styleUtils } from "styles";
import { JobCard } from "components/molecules";
import { Text } from "components/atoms";

import LoginSelector from "containers/Login/selectors/LoginSelector";
import { JobBoardConstants } from "../Constants";

const JobResults = (props) => {
  const {
    finalJobsDataToRender,
    isShowingSuggestedJobs,
    onClickJob,
    onClickBrandLogo,
    isJobNotFound,
    handleJobNotFound,
    showEmptyResultView,
  } = props;

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const isMobile = useIsMobile();
  const jobCardsContainerStyle = !isMobile && {
    maxHeight: isShowingSuggestedJobs ? "58vh" : "68vh",
    overflow: "auto",
    width: "100%",
  };

  const scrollRef = useScrollRestoration();

  const renderJobs = (item, index) => {
    const isFavourite = item?.jobSeekers?.includes(loggedInUser?.uid);

    return (
      <Grid
        key={index}
        item
        md={4}
        mb={spacing.m}
        xs={isMobile ? 12 : 0}
        sm={isMobile ? 12 : 0}
      >
        <JobCard
          item={item}
          isFavourite={isFavourite}
          onClickJob={onClickJob}
          onClickBrandLogo={onClickBrandLogo}
          isJobNotFound={isJobNotFound}
          handleJobNotFound={handleJobNotFound}
        />
      </Grid>
    );
  };

  return (
    <>
      {isShowingSuggestedJobs && (
        <EmptyResultsContainer>
          <ImgContainer src={images.undrawJobOffers} />
          <Text
            weight="medium"
            numberOfLines={2}
            style={{
              textAlign: "center",
              marginTop: styleUtils.pxToRem("15px"),
            }}
          >
            {!showEmptyResultView ? (
              <>
                {JobBoardConstants.NO_RECOMMENDED_JOBS_TITLE}
                {isMobile && <br />}
                {JobBoardConstants.SIMILAR_JOBS_HERE}
              </>
            ) : (
              JobBoardConstants.ENTER_SEARCH_FILED_TEXT
            )}
          </Text>
        </EmptyResultsContainer>
      )}
      {isShowingSuggestedJobs && (
        <Divider flexItem sx={{ mx: spacing.s, my: spacing.s }} />
      )}
      <Grid
        container
        columnSpacing={!isMobile ? spacing.s : 0}
        mt={spacing.xxs}
        ref={scrollRef}
        spacing={isMobile ? spacing.xl : 0}
        sx={{ ...jobCardsContainerStyle }}
      >
        {finalJobsDataToRender.map(renderJobs)}
      </Grid>
    </>
  );
};

export default JobResults;

const EmptyResultsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: ${styleUtils.pxToRem("30px")};
`;

const ImgContainer = styled("img")`
  margin-bottom: ${spacing.s};
  margin-top: ${styleUtils.pxToRem("60px")};
  width: ${styleUtils.pxToRem("197px")};
  height: ${styleUtils.pxToRem("145px")};
`;
