//Constants for Deactivating Job
export const INACTIVE_MODAL_HEADER = "Deactivate job";
export const INACTIVE_MODAL_SUBTEXT_ONE =
  "You’re about to Inactive the Job Post";
export const INACTIVE_MODAL_SUBTEXT_TWO =
  "Please select a reason before you make this job inactive";
export const INACTIVE_MODAL_REASON_PLACEHOLDER = "Enter your reason here";
export const INACTIVE_MODAL_BTN_CANCEL = "Cancel";
export const INACTIVE_MODAL_BTN_APPLY = "Okay";

export const DEACTIVATING_JOB_REASON_CONSTANTS = [
  {
    option: "1",
    value: "I have hired for this role via EmployD",
  },
  {
    option: "2",
    value: "I have hired for this role via other modes",
  },
  {
    option: "3",
    value: "I don’t want to hire for this role anymore",
  },
  {
    option: "4",
    value: "The details in this job post are incorrect",
  },
  {
    option: "5",
    value: "Other",
  },
];

//Constants for Deleting Job
export const DELETE_JOB_MODAL_HEADER = "Delete this job";
export const DELETE_JOB_MODAL_SUBTEXT_TWO =
  "Please select a reason before you delete this job";
export const DELETE_JOB_MODAL_REASON_PLACEHOLDER = "Enter your reason here";
export const DELETE_MODAL_SUBTEXT = "Are you sure you want to delete this job?";
export const DELETE_MODAL_BTN_CANCEL = "Cancel";
export const DELETE_MODAL_BTN_APPLY = "Delete";

export const DELETING_JOB_REASON_CONSTANTS = [
  {
    option: "1",
    value: "I have hired for this role via EmployD",
  },
  {
    option: "2",
    value: "I have hired for this role via other modes",
  },
  {
    option: "3",
    value: "I don’t want to hire for this role anymore",
  },
  {
    option: "4",
    value: "The details in this job post are incorrect",
  },
  {
    option: "5",
    value: "Other",
  },
];

export const AGE_VALUES = [14, 15, 16, 17, 18, 19, 20];

export const DUPLICATE_JOB_ALERT = {
  DUPLICATE_JOB_SUBTEXT_ONE: "You have already posted this job.",
  DUPLICATE_JOB_SUBTEXT_TWO: "Would you like to continue to post anyway?",
  BUTTON_CANCEL: "Cancel",
  BUTTON_POST: "Post",
};

//Cancel or Leave Page Constants
export const CANCEL_LEAVE_MODAL_SUBTEXT1 = "You have unsaved changes!";
export const CANCEL_LEAVE_MODAL_SUBTEXT2 = "Are you sure you want to leave?";
export const CANCEL_LEAVE_MODAL_BUTTON_CANCEL = "Cancel";
export const CANCEL_LEAVE_MODAL_BUTTON_LEAVE = "Leave";
