import {
  Avatar,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { Icon, Text } from "../../atoms";
import { colors, spacing } from "../../../styles";
import { renderImage } from "../../../utils/imageUtils";
import { images } from "../../../assets/images";
import LoginSelector from "../../../containers/Login/selectors/LoginSelector";
import {
  getFormattedJobLocation,
  getProcessedExperienceValue,
  getProcessedJobDetailValue,
} from "../../../utils/commonUtils";
import { getDateDiff } from "../../../utils/dateUtils";
import {
  EMPLOYER_PAGE_ROUTE,
  EDIT_JOB_PAGE_ROUTE,
} from "../../../routes/routeConstants";

const EmployerJobCard = ({ item, cardStyle, size = "m", onClickJob }) => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const navigate = useNavigate();
  const onClickCard = () => {
    onClickJob(item);
  };

  const onClickEdit = async (e) => {
    e.stopPropagation();
    navigate(`${EMPLOYER_PAGE_ROUTE}/${EDIT_JOB_PAGE_ROUTE}`, {
      state: {
        data: item,
        isEditPress: true,
      },
    });
  };

  return (
    <Card
      sx={{
        transform: `scale(${size === "m" ? 1 : 0.875})`,
        cursor: "pointer",
        ...cardStyle,
      }}
      onClick={onClickCard}
    >
      <div>
        <TopContainer>
          <Avatar
            src={renderImage(loggedInUser?.photoURL, "job")}
            variant="rounded"
            sx={{
              width: 57,
              height: 57,
              borderRadius: 57,
              opacity: item?.status === "active" ? 1 : 0.3,
            }}
          >
            <img
              alt={item?.listingPosition}
              src={images.defaults.job}
              style={{ width: 57, height: 57, borderRadius: 57 }}
            />
          </Avatar>
          <TitleContainer sx={{ opacity: item?.status === "active" ? 1 : 0.3 }}>
            <Row>
              <Text weight="bold" numberOfLines={1}>
                {item?.listingPosition}
              </Text>
            </Row>
            <Row>
              <Text size="xs" color={colors.placeholder} numberOfLines={1}>
                {item?.brandName}
              </Text>
            </Row>
            <Row>
              <Text size="xs" color={colors.placeholder} numberOfLines={1}>
                {getFormattedJobLocation(item?.city)}
              </Text>
            </Row>
          </TitleContainer>
          <IconContainer>
            <Icon
              name="edit"
              size={24}
              onClick={onClickEdit}
              style={{ cursor: "pointer" }}
            />
          </IconContainer>
        </TopContainer>
        <TableContainer
          sx={{
            padding: `0 ${spacing.s}`,
            opacity: item?.status === "active" ? 1 : 0.3,
          }}
        >
          <Table>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell
                  sx={{
                    border: 0,
                    borderRight: `1px solid ${colors.stroke}`,
                    padding: `${spacing.xs} 0`,
                    paddingRight: spacing.xs,
                    width: "30%",
                  }}
                >
                  <Icon name="money" alt={"money"} size={20} />
                  <Text weight="medium" size="xs" numberOfLines={1}>
                    ${getProcessedJobDetailValue(item?.wage)}/hr
                  </Text>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    borderRight: `1px solid ${colors.stroke}`,
                    padding: `${spacing.xs} 0`,
                    paddingRight: spacing.xs,
                    width: "40%",
                  }}
                >
                  <Icon
                    name="clock"
                    alt={"clock"}
                    size={20}
                    style={{ marginLeft: spacing.s }}
                  />
                  <Text
                    weight="medium"
                    size="xs"
                    style={{ marginLeft: spacing.s }}
                    numberOfLines={1}
                  >
                    {`${getProcessedJobDetailValue(item?.hoursListed)} hr/week`}
                  </Text>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: `${spacing.xs} 0`,
                    width: "30%",
                  }}
                >
                  <Icon
                    name="experience"
                    alt={"experience"}
                    size={20}
                    style={{ marginLeft: spacing.s }}
                  />
                  <Text
                    weight="medium"
                    size="xs"
                    style={{ marginLeft: spacing.s }}
                    numberOfLines={1}
                  >
                    {getProcessedExperienceValue(item?.experience)}
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Divider
          style={{
            marginLeft: spacing.s,
            marginRight: spacing.s,
            marginTop: spacing.s,
          }}
        />
        <Text
          color={colors.placeholder}
          style={{
            fontSize: "11px",
            margin: spacing.s,
            opacity: item?.status === "active" ? 1 : 0.3,
          }}
        >
          Posted {getDateDiff(item?.createdAt)}
        </Text>
      </div>
    </Card>
  );
};

export default EmployerJobCard;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
  padding: ${spacing.s};
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${spacing.s};
`;

const IconContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-left: ${spacing.s};
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
