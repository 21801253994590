import * as yup from "yup";
import { isValidPhoneNumber } from "../../../utils/commonUtils";

export const emailOtpSchema = yup.object({
  emailOtp: yup
    .string()
    .required("Otp is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be 6 digit")
    .max(6, "Must be 6 digit"),
});

export const phoneOtpSchema = yup.object({
  phoneOtp: yup
    .string()
    .required("Otp is required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(6, "Must be 6 digit")
    .max(6, "Must be 6 digit"),
});

export const editEmailDetailsSchema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Enter a valid email"),
});

export const editPhoneDetailsSchema = yup.object({
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .test(
      "validate-phone",
      "Please enter valid phone number.",
      async (value, context) => {
        if (value) {
          return await isValidPhoneNumber({
            countryCode: context.parent.countryCode,
            phoneNumber: value,
          });
        }
        return true;
      }
    ),
});
