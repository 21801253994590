import { takeLatest, put, all, call } from "redux-saga/effects";
import * as jobSeekerActionTypes from "../actions/actionTypes";
import {
  jobSeekerListingApi,
  schedulerGetAvailabilityList,
  schedulerBookSlot,
} from "../../../configs/network/apis";
import { logEvent } from "../../../configs/firebase";
import { hideLoader, showNotification } from "../../../utils/commonUtils";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import noop from "lodash/noop";

function* handleJobSeekerListing(action) {
  const {setIsLocationAllowed = noop, ...apiPayload} = action.payload;
  try {
    const { data } = yield call(jobSeekerListingApi, apiPayload);
    logEvent(FirebaseEvents.JS_JOBS_SEARCH_LISTING, {
      Is_SignedIn: apiPayload?.userId ? "Signed_In" : "Non_signed_in",
    });

    yield put({
      type: jobSeekerActionTypes.JOB_SEEKER_LISTING_SUCCESS,
      data,
    });
    setIsLocationAllowed(true);
  } catch (error) {
    if(error?.response?.status == 405) {
      setIsLocationAllowed(false);
    }
    yield put({ type: jobSeekerActionTypes.JOB_SEEKER_LISTING_FAILURE });
  }
}

function* watchJobSeekerListingRequest() {
  yield takeLatest(
    jobSeekerActionTypes.JOB_SEEKER_LISTING_REQUEST,
    handleJobSeekerListing
  );
}

function* handleSchedulerGetEmployerAvailabilityList(action) {
  const { payload } = action;
  try {
    const { availabilities } = yield call(
      schedulerGetAvailabilityList,
      payload
    );
    yield put({
      type: jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_SUCCESS,
      availabilities,
    });
    hideLoader();
  } catch (error) {
    hideLoader();
    yield put({
      type: jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_FAILED,
    });
  }
}

function* watchSchedulerGetEmployerAvailabilityList() {
  yield takeLatest(
    jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_GET_EMPLOYER_AVAILABILITY_REQUEST,
    handleSchedulerGetEmployerAvailabilityList
  );
}

function* handleSchedulerBookSlot(action) {
  const { payload } = action;
  try {
    const res = yield call(schedulerBookSlot, payload);
    const { availabilities, message } = res;
    yield put({
      type: jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_BOOK_SLOT_SUCCESS,
      availabilities,
    });
    hideLoader();
    showNotification({
      alertProps: {
        severity: "success",
        children: message,
      },
    });
  } catch (error) {
    hideLoader();
    yield put({
      type: jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_BOOK_SLOT_FAILED,
    });
  }
}

function* watchSchedulerBookSlot() {
  yield takeLatest(
    jobSeekerActionTypes.JOB_SEEKER_SCHEDULER_BOOK_SLOT_REQUEST,
    handleSchedulerBookSlot
  );
}

export function* jobSeekerSagas() {
  yield all([
    watchJobSeekerListingRequest(),
    watchSchedulerGetEmployerAvailabilityList(),
    watchSchedulerBookSlot(),
  ]);
}
