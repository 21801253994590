import * as signUpActions from "./actionTypes";

/**
 * Function to dispatch job seeker sign up request
 */
export const jobSeekerSignUpRequest = (payload) => ({
  type: signUpActions.JOB_SEEKER_SIGNUP_REQUEST,
  payload,
});

/**
 * Function to dispatch job seeker verifier request
 */
export const jobSeekerVerifyRequest = (payload) => ({
  type: signUpActions.JOB_SEEKER_VERIFY_REQUEST,
  payload,
});

/**
 * Function to dispatch employer sign up request
 */
export const employerSignUpRequest = (payload) => ({
  type: signUpActions.EMPLOYER_SIGNUP_REQUEST,
  payload,
});

/**
 * Function to dispatch employer details verification request
 */
export const employerVerifyRequest = (payload) => ({
  type: signUpActions.EMPLOYER_VERIFY_REQUEST,
  payload,
});

/**
 * Function to dispatch reset sign up state request
 */
export const resetSignUpRequest = () => ({
  type: signUpActions.RESET_SIGN_UP,
});
