import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { AppBar, Box, Container, Divider, Drawer, Toolbar } from "@mui/material";
import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";

import { images } from "../../../assets/images";
import { colors, spacing, styleUtils } from "../../../styles";

import { Icon, Text } from "../../../components/atoms";
import AuthenticatedNav from "./AuthenticatedNav/AuthenticatedNav";
import UnAuthenticatedNav from "./UnAuthenticatedNav/UnAuthenticatedNav";
import LoginButton from "../LoginButton/LoginButton";
import { NavContext } from "./NavContext";
import { FooterConstants } from "containers/Footer/Constants";
import useScrollYAnimationResp from "hooks/useScrollYAnimationResp";
import { checkIsDetailView } from "utils/commonUtils";

const NavBar = ({ isAuthenticated, onClickLogo, isCountryCheckError }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { y: yPos, hideAnimation } = useScrollYAnimationResp();
  const location = useLocation();
  const isDetailView = checkIsDetailView(location);
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  useEffect(() => {
    handleDrawerClose();
  }, [isAuthenticated]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginBottom: "58px",
        animation: `${hideAnimation} 0.4s ease`,
        opacity: yPos < 10 || !isDetailView ? 1 : 0,
      }}
    >
      <AppBar sx={{ background: colors.white, borderTopWidth: 0 }} position="fixed">
        <Container>
          <Toolbar disableGutters>
            <MenuIcon
              htmlColor={colors.primary}
              onClick={handleDrawerOpen}
              sx={{ mr: spacing.s }}
            />
            <Logo src={images.logo} onClick={onClickLogo} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1,
                alignItems: "center",
              }}
            >
              <LoginButton isCountryCheckError={isCountryCheckError} />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        sx={{
          width: "100%",
          "& .MuiDrawer-paper": {
            borderWidth: 0,
            borderRadius: 0,
            width: "100%",
            backgroundColor: colors.white,
            paddingLeft: styleUtils.pxToRem("20px"),
            paddingRight: styleUtils.pxToRem("20px"),
            paddingTop: styleUtils.pxToRem("23px"),
            paddingBottom: styleUtils.pxToRem("30px"),
          },
        }}
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerClose}
      >
        <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: styleUtils.pxToRem("17px"),
              }}
            >
              <Logo src={images.logo} />
              <Icon name="close" size={18} color={colors.textPrimary} onClick={handleDrawerClose} />
            </div>
            <NavContext.Provider value={{ handleDrawerClose: handleDrawerClose }}>
              {isAuthenticated ? <AuthenticatedNav /> : <UnAuthenticatedNav />}
            </NavContext.Provider>
          </div>
          <div>
            <Divider
              sx={{
                borderColor: "#D0D0D0",
                paddingRight: styleUtils.pxToRem("3px"),
                paddingLeft: styleUtils.pxToRem("2px"),
              }}
            />
            <Text
              size="xs"
              style={{
                textAlign: "center",
                paddingTop: styleUtils.pxToRem("22px"),
              }}
            >
              {FooterConstants.COPYRIGHT_RIGHTS_RESERVED}
            </Text>
          </div>
        </div>
      </Drawer>
    </Box>
  );
};

export default NavBar;

const Logo = styled("img")`
  display: flex;
  max-width: 100px;
  width: auto;
  height: auto;
`;
