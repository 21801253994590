import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";

import * as loginActionTypes from "../actions/actionTypes";
import {
  getBlockedUsersList,
  getRecentlyUnblockedUsersList,
  shareForgotPasswordOtp,
  verifyForgotPasswordOtp,
  userSignInApi,
  updateUserDetails,
} from "../../../configs/network/apis";
import { hideLoader, showLoader, showNotification } from "../../../utils/commonUtils";
import { JOB_SEEKER, EMPLOYER } from "../../../appConstants";
import { noop } from "lodash";

/**
 * Job Seeeker Login Saga handler
 */
function* handleLogin(action) {
  const { onCallback, type, email, password } = action.payload;
  if (type === JOB_SEEKER) {
    try {
      const { data } = yield call(userSignInApi, { email, password, type: JOB_SEEKER });
      let userData = data;
      onCallback({ type, uid: userData.user.uid });
    } catch (error) {
      hideLoader();
      if (error?.response?.data?.message === "auth/too-many-requests") {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Too many failed attempts. Please try again later",
          },
        });
      } else if (error?.response?.data?.code === "credential_mismatch") {
        //If credentials didn't matched
        onCallback({ isPasswordInvalid: true });
      } else {
        //Generic case
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again",
          },
        });
      }
      yield put({ type: loginActionTypes.LOGIN_FAILED });
    }
  } else {
    try {
      const { data } = yield call(userSignInApi, { email, password, type: EMPLOYER });
      let userData = data;
      onCallback({ type, uid: userData.user.uid });
    } catch (error) {
      hideLoader();
      if (error?.response?.data?.message === "auth/too-many-requests") {
        showNotification({
          alertProps: {
            severity: "error",
            children: "Too many failed attempts. Please try again later",
          },
        });
      } else if (error?.response?.data?.code === "credential_mismatch") {
        //If credentials didn't matched
        onCallback({ isPasswordInvalid: true });
      } else {
        //Generic case
        showNotification({
          alertProps: {
            severity: "error",
            children: "Something went wrong please try again",
          },
        });
      }
      yield put({ type: loginActionTypes.LOGIN_FAILED });
    }
  }
}

function* handleSocialLogin(action) {
  const { onCallback, image, ...payload } = action.payload;
  try {
    const { data } = yield call(userSignInApi, payload);
    const userData = data.user;

    onCallback({
      status: true,
      loginType: payload.loginType,
      type: payload.type,
      uid: userData.uid,
      social_id: payload.social_id,
      name: payload.name,
    });
  } catch (error) {
    hideLoader();
    showNotification({
      alertProps: {
        severity: "error",
        children: error?.response?.data?.message,
      },
    });
    if (error?.response?.data?.message === "user not exist!") {
      onCallback({
        status: false,
        name: payload.name,
        email: payload.email,
        image: image,
        loginType: payload.loginType,
        idToken: payload.idToken,
        social_id: payload.social_id,
      });
    }
  }
}

function* handleUserUpdate(action) {
  const { userType } = action;
  const { successCallback = noop, errorCallback = noop, ...apiPayload } = action.payload;
  try {
    const res = yield call(updateUserDetails, apiPayload);
    const { data } = res;
    hideLoader();
    showNotification({
      alertProps: {
        severity: "success",
        children: res.data.message,
      },
    });
    let userData = data?.user;
    userData["type"] = userType;
    yield put({ type: loginActionTypes.USER_DETAILS_UPDATE_SUCCESS, userData });
    successCallback();
  } catch (error) {
    //add something to show error
    hideLoader();
    yield put({ type: loginActionTypes.USER_DETAILS_UPDATE_FAIL });
    errorCallback();
  }
}

function* handleUnblockedUsersListing(action) {
  const { payload } = action;
  try {
    const {
      data: { unblock_users },
    } = yield call(getRecentlyUnblockedUsersList, payload);
    yield put({
      type: loginActionTypes.GET_RECENT_UNBLOCKED_USERS_LIST_SUCCESS,
      unblock_users,
    });
  } catch (error) {
    yield put({
      type: loginActionTypes.GET_RECENT_UNBLOCKED_USERS_LIST_FAILED,
    });
  }
}

/**
 * Share Otp's with user for Forgot Password
 */
export function* shareForgotPasswordOtpRequest(action) {
  const { onCallback = () => {} } = action.payload;
  showLoader();
  try {
    const res = yield call(shareForgotPasswordOtp, action.payload);
    const { message, phoneNumber, countryCode } = res?.data || {};
    if (
      message === "Verification code has been sent to your registered phone number and email ID"
    ) {
      yield put({
        type: loginActionTypes.FORGOT_PASSWORD_OTP_SUCCESS,
      });
      onCallback({
        status: true,
        email: action?.payload?.email,
        phoneNumber: `${countryCode}-${phoneNumber}`,
      });
    } else {
      yield put({
        type: loginActionTypes.FORGOT_PASSWORD_OTP_FAILED,
      });
      onCallback({ status: false, message });
    }
    hideLoader();
  } catch (error) {
    yield put({
      type: loginActionTypes.FORGOT_PASSWORD_OTP_FAILED,
    });
    hideLoader();
    onCallback({ status: false });
  }
}

/**
 * Verify Otp's shared with user for Forgot Password
 */
export function* verifyForgotPasswordOtpRequest(action) {
  const { onCallback = () => {} } = action.payload;
  showLoader();
  try {
    const res = yield call(verifyForgotPasswordOtp, action.payload);
    const { verifyOtpToken } = res;
    let payload = {
      verifiedStatus: true,
      forgotPasswordVerificationToken: verifyOtpToken,
    };
    yield put({
      type: loginActionTypes.VERIFY_FORGOT_PASSWORD_OTP_SUCCESS,
      payload,
    });
    onCallback({ verifiedStatus: true });
    hideLoader();
  } catch (error) {
    if (error?.response?.status === 401) {
      onCallback({ verifiedStatus: false, message: error?.response?.message });
    } else {
      //No code here
    }
    yield put({
      type: loginActionTypes.VERIFY_FORGOT_PASSWORD_OTP_FAILED,
      verifiedStatus: false,
    });
    hideLoader();
  }
}

function* handleBlockedUsersListing(action) {
  const { payload } = action;
  try {
    const {
      data: { blocked_users },
    } = yield call(getBlockedUsersList, payload);
    yield put({
      type: loginActionTypes.GET_BLOCKED_USERS_LIST_SUCCESS,
      blocked_users,
      blockedIds: blocked_users?.map((blockedUser) => blockedUser?.userId),
    });
  } catch (error) {
    yield put({ type: loginActionTypes.GET_BLOCKED_USERS_LIST_FAILED });
  }
}

function* watchBlockedUsersRequest() {
  yield takeLatest(loginActionTypes.GET_BLOCKED_USERS_LIST_REQUEST, handleBlockedUsersListing);
  yield takeLatest(
    loginActionTypes.GET_RECENT_UNBLOCKED_USERS_LIST_REQUEST,
    handleUnblockedUsersListing,
  );
}

function* watchLoginRequest() {
  yield takeLatest(loginActionTypes.LOGIN_REQUEST, handleLogin);
  yield takeLatest(loginActionTypes.SOCIAL_LOGIN_REQUEST, handleSocialLogin);
  yield takeLatest(loginActionTypes.FORGOT_PASSWORD_OTP_REQUEST, shareForgotPasswordOtpRequest);
  yield takeLatest(
    loginActionTypes.VERIFY_FORGOT_PASSWORD_OTP_REQUEST,
    verifyForgotPasswordOtpRequest,
  );
}

function* watchUserUpdate() {
  yield takeEvery(loginActionTypes.USER_DETAILS_UPDATE, handleUserUpdate);
}

export function* loginSagas() {
  yield all([watchLoginRequest(), watchUserUpdate(), watchBlockedUsersRequest()]);
}
