import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { Box, Card, Container, Grid, Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

import ApplicationsJobDetailsContainer from "./ApplicationsJobDetailsContainer";
import JobsGridView from "./views/JobsGridView";

import useIsMobile from "hooks/useIsMobile";

import { Icon } from "../../../../components/atoms";

import LoginSelector from "../../../Login/selectors/LoginSelector";
import JobSeekerSelector from "../../selectors/JobSeekerSelector";

import {
  colors,
  spacing,
  styleUtils,
  textSizes,
  typography,
} from "../../../../styles";

import {
  getAppliedJobs,
  getBookmarkedJobs,
} from "../../../../configs/network/apis";
import { hideLoader, showLoader } from "../../../../utils/commonUtils";

const ApplicationsViewContainer = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState(0);
  const [bookmarkedJobs, setBookmarkedJobs] = useState();
  const [appliedJobs, setAppliedJobs] = useState();
  const isMobile = useIsMobile();

  const showJobDetailView = searchParams.get("showJobDetailView");

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const forceUpdate = useSelector(
    JobSeekerSelector.getForceUpdateJobSeekerListing
  );

  useEffect(() => {
    init();
  }, [forceUpdate, activeTab]);

  const fetchAppliedJobs = async () => {
    const res = await getAppliedJobs();
    setAppliedJobs(res?.data?.jobs || []);
  };

  const fetchBookmarkedJobs = async () => {
    const res = await getBookmarkedJobs({ userId: loggedInUser.uid });
    setBookmarkedJobs(res?.data?.jobs || []);
  };

  const init = () => {
    try {
      showLoader();
      activeTab === 0 ? fetchAppliedJobs() : fetchBookmarkedJobs();
      hideLoader();
    } catch (e) {
      console.log(e);
    }
  };

  const toggleGridView = () => {
    setSearchParams({});
  };

  const onClickJob = (job) => {
    setSearchParams({
      showJobDetailView: true,
      jobId: job.jobId,
      brandName: job.brandName,
    });
  };

  const handleTabToggle = () => {
    setActiveTab(activeTab === 0 ? 1 : 0);
  };

  const jobsGridListView = (
    <Container
      sx={{
        alignItems: "center",
        flex: 1,
        height: "85vh",
        justifyContent: "center",
        marginBottom: spacing.xxl,
        marginTop: spacing.m,
        pl: spacing.s,
        pt: spacing.s,
        maxWidth: isMobile ? styleUtils.pxToRem("390px") : "auto",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "#E8E8E8" }}>
          <Tabs
            value={activeTab}
            onChange={handleTabToggle}
            indicatorcolor="#2B4894"
            sx={{ height: styleUtils.pxToRem("62px") }}
            TabIndicatorProps={{ sx: { height: "4px", borderRadius: "4px" } }}
          >
            <StyledTab
              label="Applied"
              icon={
                <Icon
                  name="check-circle"
                  size={16}
                  color={activeTab === 0 ? "#2B4894" : colors.disableGrey}
                />
              }
              iconPosition="start"
              textColor={
                activeTab === 0 ? colors.blackText : colors.disableGrey
              }
              sx={{
                borderRight: 1,
                borderColor: "#E8E8E8",
                width: styleUtils.pxToRem(isMobile ? "160px" : "179px"),
              }}
            />
            <StyledTab
              label="Bookmarked"
              icon={
                <Icon
                  name="bookmark"
                  size={16}
                  color={activeTab === 1 ? "#2B4894" : colors.disableGrey}
                />
              }
              iconPosition="start"
              textColor={
                activeTab === 1 ? colors.blackText : colors.disableGrey
              }
              sx={{ width: styleUtils.pxToRem(isMobile ? "160px" : "179px") }}
            />
          </Tabs>
        </Box>
        <div
          role="tabpanel"
          hidden={activeTab !== 0}
          style={{ marginLeft: spacing.xxl }}
        >
          <Grid container columnSpacing={spacing.l} mt={spacing.xxxs}>
            <JobsGridView
              isFavourite={true}
              jobsList={appliedJobs}
              onClickJob={onClickJob}
            />
          </Grid>
        </div>
        <div
          role="tabpanel"
          hidden={activeTab !== 1}
          style={{ marginLeft: spacing.xxl }}
        >
          <Grid container columnSpacing={spacing.l} mt={spacing.xxxs}>
            <JobsGridView
              isFavourite={false}
              jobsList={bookmarkedJobs}
              onClickJob={onClickJob}
            />
          </Grid>
        </div>
      </Card>
    </Container>
  );

  const detailsView = (
    <ApplicationsJobDetailsContainer
      isFavourite={activeTab === 0}
      jobsList={activeTab === 0 ? appliedJobs : bookmarkedJobs}
      onClickJob={onClickJob}
      showJobDetailView={showJobDetailView}
      toggleGridView={toggleGridView}
    />
  );

  let content = showJobDetailView ? detailsView : jobsGridListView;

  return content;
};

export default ApplicationsViewContainer;

const StyledTab = styled(Tab)`
  font-size: ${textSizes.m};
  font-weight: ${typography.medium};
  text-transform: none;
`;
