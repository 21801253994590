import React from "react";
import PropTypes from "prop-types";
import { Button, Chip, Divider } from "@mui/material";
import styled from "@emotion/styled";

import {
  colors,
  spacing,
  textSizes,
  typography,
  styleUtils,
} from "../../../../../styles";
import * as JobSeekerConstants from "../constants";
import { Icon, Text } from "../../../../../components/atoms";

const Preferences = ({ userDetails, onClickEditPreferences }) => {
  return (
    <>
      <TopContainer>
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            right: spacing.l,
            top: spacing.s,
            width: "auto",
            pl: spacing.xl,
            pr: spacing.xl,
            fontSize: textSizes.l,
            fontWeight: typography.semibold,
            color: colors.primary,
          }}
          onClick={() => onClickEditPreferences("preferences", "editPref")}
        >
          Edit
        </Button>
        <Icon name="money" alt={"money"} size={22} />
        <Text weight="medium">
          {userDetails?.wageExpectation
            .map((wageValue) => `$${wageValue}`)
            .join(", ")}
          /hr
        </Text>
        <Icon
          name="clock"
          alt={"clock"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">
          {userDetails?.workingHours.map((hours) => `${hours}`).join(", ")}{" "}
          {JobSeekerConstants.WORKING_HOUR_PREFERENCE_TEXT}
        </Text>
        <Icon
          name="experience"
          alt={"experience"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">
          {userDetails?.experience.map((hours) =>
            hours === "Beginner" ? hours : hours + " yrs"
          )}
        </Text>
        <Icon
          name="calendar"
          alt={"calendar"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">{userDetails?.daysPerWeek?.join(", ")}</Text>
        <Icon
          name="timeslot"
          alt={"timeslot"}
          size={22}
          style={{ marginTop: spacing.s }}
        />
        <Text weight="medium">{userDetails?.timeSlot?.join(", ")}</Text>
      </TopContainer>
      <Divider />
      <TopContainer>
        <Text
          size="m"
          weight="semibold"
          color={colors.labelBlack}
          style={{ marginBottom: spacing.xs }}
        >
          About Me
        </Text>
        <Text size="s" color={colors.placeholder}>
          {userDetails?.note}
        </Text>
      </TopContainer>
      <Divider />
      <TopContainer>
        <Button
          variant="outlined"
          sx={{
            position: "absolute",
            right: spacing.l,
            top: spacing.s,
            width: "auto",
            pl: spacing.xl,
            pr: spacing.xl,
            fontSize: textSizes.l,
            fontWeight: typography.semibold,
            color: colors.primary,
          }}
          onClick={() => onClickEditPreferences("preferences", "editinter")}
        >
          Edit
        </Button>
        <Text
          size="m"
          weight="semibold"
          color={colors.labelBlack}
          sx={{ marginBottom: spacing.xs, maxWidth: "8rem" }}
        >
          My Job Interests
        </Text>
        <InterestsFlexBox>
          {userDetails?.job_interests?.map((jobRole, index) => {
            return (
              <Chip
                label={jobRole}
                key={index}
                variant="outlined"
                onClick={() => {}}
                sx={{
                  height: 34,
                  width: "auto",
                  margin: "0 0.75rem 0.75rem 0",
                  pointerEvents: "none",
                  borderRadius: styleUtils.pxToRem("17px"),
                  "& .MuiChip-label": {
                    fontSize: textSizes.s,
                    fontWeight: typography.medium,
                    color: "#2C4895",
                  },
                  border: "1px solid #2C4895",
                  "& .MuiChip-clickable:hover": {
                    backgroundColor: colors.white,
                  },
                }}
              />
            );
          })}
        </InterestsFlexBox>
      </TopContainer>
    </>
  );
};

Preferences.propTypes = {
  userDetails: PropTypes.object.isRequired,
  onClickEditPreferences: PropTypes.func.isRequired,
};

export default Preferences;

const TopContainer = styled("div")`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: ${spacing.xl};
`;

const InterestsFlexBox = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;
