import { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import { styled } from "@mui/material/styles";
import {
  Button,
  FormHelperText,
  Grid,
  Container,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import {
  Text,
  TextInput,
  GoogleInput,
  Icon,
  MultiSelect,
  RichTextViewer,
} from "../../../../../components/atoms";
import { colors, spacing } from "../../../../../styles";
import * as ProfileConstants from "../../../../SignUp/views/JobSeeker/constants";
import * as AddJobsConts from "../AddJobConstants";

import DeleteModal from "../views/DeleteModal";
import DuplicateJobAlert from "./DuplicateJobAlert";
import { JobDescriptionInput } from "../../../../../components/molecules";
import CancelModal from "../../../../../components/atoms/cancelModal";
import { decodeToHtml } from "../../../../../utils/commonUtils";

const AddJobMobileView = (props) => {
  const {
    activeStepAddEditJob,
    city,
    isCopy,
    isEditPress,
    formik,
    jobDescriptionFormik,
    googleInputRef,
    jobLocationFormattedAddress,
    invalidLocation,
    loggedInUser,
    minAge,
    showDeleteJob,
    showDuplicateJobAlert,
    showCancelLeaveModal,
    clearInput,
    onBlurJobTitle,
    onPressCancel,
    onPlaceSelected,
    handleAgeChange,
    handleDaysSelectedChange,
    handleDeleteModal,
    handleExpChange,
    handleHoursChange,
    handleTimeSlotChange,
    handleWageChange,
    handleJobDescriptionChange,
    handleCancelLeaveModal,
    setShowDeleteJob,
    setShowDuplicateJobAlert,
    setActiveStepAddEditJob,
  } = props;

  const [isJDGenerationComplete, setIsJDGenerationComplete] = useState(true);
  const navigate = useNavigate();
  const copyTitle = isCopy ? "Duplicate Job" : "Add Job Post";
  const title = isEditPress ? "Edit Job Post" : copyTitle;
  const headerTitle = activeStepAddEditJob === 2 ? "Job Description" : title;

  const onClickBack = () => {
    if (activeStepAddEditJob === 1) {
      navigate(-1);
    } else {
      setActiveStepAddEditJob((prev) => prev - 1);
    }
  };

  const renderAgeDropDown = () => {
    return (
      <div>
        <Text size="s" weight="medium" sx={{ marginTop: spacing.m }}>
          Minimum Age for the job
        </Text>

        <FormControl
          fullWidth
          sx={{
            mt: 1,
            "& .MuiOutlinedInput-root:hover": {
              borderColor: "transparent",
            },
          }}
          size="small"
        >
          <Select
            id="demo-select-small"
            displayEmpty
            renderValue={
              minAge !== ""
                ? undefined
                : () => (
                    <Text size="xs" colors="colors.lightGray">
                      Select the minimum age for the job
                    </Text>
                  )
            }
            value={minAge}
            onChange={handleAgeChange}
            disabled={invalidLocation}
            sx={{
              padding: 0,
              "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
                marginTop: spacing.xxs,
                border: "1px solid #E0E0E0",
                borderRadius: "4px",
              },
              "&.Mui-disabled": {
                pointerEvents: "none",
                "& .MuiSelect-select": {
                  color: "rgba(0, 0, 0, 0.26)",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(0, 0, 0, 0.12)",
                },
              },
            }}
          >
            {AddJobsConts.AGE_VALUES.map((data, index) => {
              return (
                <MenuItem key={index} value={data}>
                  {data} years
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {formik.touched.min_age && Boolean(formik.errors.min_age) && (
          <FormHelperText sx={{ color: colors.red, marginBottom: spacing.s }}>
            {formik.errors.min_age}
          </FormHelperText>
        )}
      </div>
    );
  };

  return (
    <Container sx={{ mt: spacing.xxl, mb: spacing.xxxxl }}>
      <DuplicateJobAlert
        show={showDuplicateJobAlert}
        handleCancel={() => setShowDuplicateJobAlert(false)}
        handlePost={formik.handleSubmit}
      />
      <Row>
        <Row sx={{ alignItems: "center", gap: "16px" }}>
          <Icon
            name="arrow-back"
            size={42}
            style={{
              marginBottom: spacing.s,
            }}
            onClick={onClickBack}
          />
          <Text
            size="l"
            weight="medium"
            color={colors.titleBlack}
            style={{ marginBottom: spacing.m }}
          >
            {headerTitle}
          </Text>
        </Row>
        {isEditPress && activeStepAddEditJob === 1 && (
          <ActiveBlockContainer>
            <Icon
              size={26}
              name="delete-bin"
              onClick={() => setShowDeleteJob(true)}
              color={colors.primary}
              style={{ marginRight: spacing.s, cursor: "pointer" }}
            />
          </ActiveBlockContainer>
        )}
      </Row>
      <Grid container columnSpacing={spacing.m}>
        <DeleteModal showDeleteJob={showDeleteJob} handleDeleteModal={handleDeleteModal} />
        <CancelModal isOpen={showCancelLeaveModal} handleClose={handleCancelLeaveModal} />
        {activeStepAddEditJob === 1 && (
          <>
            <Grid item md={6} xs={12} sm={12}>
              <Text size="s" weight="medium" color={colors.labelBlack}>
                Job Title
              </Text>
              <TextInput
                name="jobTitle"
                value={formik.values.jobTitle}
                onChange={formik.handleChange}
                error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                placeholder={"enter job title here"}
                onBlur={onBlurJobTitle}
                sx={{
                  marginTop: spacing.xs,
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                }}
              />

              {formik.touched.jobTitle && Boolean(formik.errors.jobTitle) && (
                <FormHelperText sx={{ color: colors.red }}>{formik.errors.jobTitle}</FormHelperText>
              )}
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.m }}
              >
                Job Location
              </Text>
              <GoogleInput
                ref={googleInputRef}
                placeholder={"enter location"}
                onPlaceSelected={onPlaceSelected}
                defaultValue={isEditPress ? city : ""}
                onClear={clearInput}
                style={{
                  marginTop: spacing.xs,
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                }}
              />
              {invalidLocation && (
                <div style={{ display: "flex" }}>
                  <Icon
                    size={26}
                    name="error"
                    onClick={() => setShowDeleteJob(true)}
                    color={colors.primary}
                    style={{ marginRight: spacing.s, cursor: "pointer" }}
                  />
                  <Text size="s" color={colors.red2}>
                    We’re currently available only in the Bucks County region, Pennsylvania. We will
                    notify you when we are in {jobLocationFormattedAddress}
                  </Text>
                </div>
              )}
              {formik.touched.jobLocation && Boolean(!formik.values.jobLocation) && (
                <FormHelperText sx={{ color: colors.red }}>
                  {formik.errors.jobLocation}
                </FormHelperText>
              )}
              {renderAgeDropDown()}
            </Grid>
            <Grid item md={6} xs={12} sm={12}>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Hourly Wage Rate
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.wageExpectations}
                    name="wageExpectation"
                    prevSelectedOptions={formik.values.wageExpectation}
                    handleSelect={handleWageChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.wageExpectation && Boolean(formik.errors.wageExpectation) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.wageExpectation}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Hours per week
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.workingHrs}
                    name="workingHours"
                    prevSelectedOptions={formik.values.workingHours}
                    handleSelect={handleHoursChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.workingHours && Boolean(formik.errors.workingHours) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.workingHours}
                  </FormHelperText>
                )}
              </>

              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                style={{ marginTop: spacing.xs }}
              >
                Experience Required
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.workExperience}
                    name="experience"
                    prevSelectedOptions={formik.values.experience}
                    handleSelect={handleExpChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.experience && Boolean(formik.errors.experience) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.experience}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Shift
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    selectOptions={ProfileConstants.timeSlots}
                    name="timeSlot"
                    prevSelectedOptions={formik.values.timeSlot}
                    handleSelect={handleTimeSlotChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.timeSlot && Boolean(formik.errors.timeSlot) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.timeSlot}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.xs }}
              >
                Days per week
              </Text>
              <>
                <SelectContainer>
                  <MultiSelect
                    name="daysSelected"
                    selectOptions={ProfileConstants.dayAvailable}
                    prevSelectedOptions={formik.values.daysSelected}
                    handleSelect={handleDaysSelectedChange}
                    isDisabled={invalidLocation}
                  />
                </SelectContainer>
                {formik.touched.daysSelected && Boolean(formik.errors.daysSelected) && (
                  <FormHelperText sx={{ color: colors.red }}>
                    {formik.errors.daysSelected}
                  </FormHelperText>
                )}
              </>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.m, marginBottom: spacing.s }}
              >
                Job Description
              </Text>
              <JobDescriptionDiv
                onFocus={() => setActiveStepAddEditJob((prev) => prev + 1)}
                tabIndex="0"
              >
                {jobDescriptionFormik.values.jobDescription ? (
                  <RichTextViewer style={{ height: 84 }}>
                    {decodeToHtml(jobDescriptionFormik.values.jobDescription)}
                  </RichTextViewer>
                ) : (
                  <Text size="14px" sx={{ color: colors.descriptionGray }}>
                    Please provide a job description
                  </Text>
                )}
              </JobDescriptionDiv>
              {Boolean(jobDescriptionFormik.errors.jobDescription) && (
                <FormHelperText sx={{ color: colors.red }}>
                  {jobDescriptionFormik.errors.jobDescription}
                </FormHelperText>
              )}
            </Grid>

            <Grid item xs={12}>
              <Text
                size="s"
                weight="medium"
                color={colors.labelBlack}
                sx={{ marginTop: spacing.m }}
              >
                Write a Short Note
              </Text>
              <TextInput
                multiline
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
                error={formik.touched.note && Boolean(formik.errors.note)}
                placeholder="Tell job seekers about perks, benefits, work environment, etc. For Example - Fun work environment & free smoothies!"
                inputProps={{ style: { height: 84, overflow: "auto" } }}
                sx={{
                  marginTop: spacing.xs,
                  border: "1px solid #E0E0E0",
                  borderRadius: "4px",
                }}
                isDisabled={invalidLocation}
              />
              {formik.touched.note && Boolean(formik.errors.note) && (
                <FormHelperText sx={{ color: colors.red }}>{formik.errors.note}</FormHelperText>
              )}
            </Grid>
          </>
        )}
        {activeStepAddEditJob === 2 && (
          <Grid
            item
            xs={12}
            sx={{
              marginTop: spacing.s,
            }}
          >
            <JobDescriptionInput
              formik={formik}
              jobDescriptionFormik={jobDescriptionFormik}
              handleJobDescriptionChange={handleJobDescriptionChange}
              companyName={loggedInUser?.companyName}
              labelStyle={{ marginBottom: spacing.xs }}
              isJDGenerationComplete={isJDGenerationComplete}
              setIsJDGenerationComplete={setIsJDGenerationComplete}
              isDisabled={invalidLocation}
            />
            {Boolean(jobDescriptionFormik.errors.jobDesscription) && (
              <FormHelperText sx={{ color: colors.red }}>
                {jobDescriptionFormik.errors.jobDesscription}
              </FormHelperText>
            )}
          </Grid>
        )}

        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: spacing.xxl,
          }}
        >
          <Button style={{ width: "50%" }} size="large" onClick={onPressCancel}>
            Cancel
          </Button>

          <Button
            variant="contained"
            style={{ width: "50%", marginLeft: 30 }}
            size="large"
            onClick={activeStepAddEditJob === 2 ? onClickBack : formik.handleSubmit}
            disabled={!isJDGenerationComplete || (!formik.dirty && !jobDescriptionFormik.dirty)}
          >
            {isEditPress || activeStepAddEditJob === 2 ? "Save" : "Post"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

AddJobMobileView.propTypes = {
  activeStepAddEditJob: PropTypes.number.isRequired,
  city: PropTypes.string,
  clearInput: PropTypes.func.isRequired,
  formik: PropTypes.object.isRequired,
  googleInputRef: PropTypes.object.isRequired,
  handleAgeChange: PropTypes.func.isRequired,
  handleCancelLeaveModal: PropTypes.func.isRequired,
  handleDaysSelectedChange: PropTypes.func.isRequired,
  handleDeleteModal: PropTypes.func.isRequired,
  handleExpChange: PropTypes.func.isRequired,
  handleHoursChange: PropTypes.func.isRequired,
  handleJobDescriptionChange: PropTypes.func.isRequired,
  handleTimeSlotChange: PropTypes.func.isRequired,
  handleWageChange: PropTypes.func.isRequired,
  invalidLocation: PropTypes.bool.isRequired,
  isCopy: PropTypes.bool,
  isEditPress: PropTypes.bool,
  jobDescriptionFormik: PropTypes.object.isRequired,
  jobLocationFormattedAddress: PropTypes.string.isRequired,
  loggedInUser: PropTypes.object.isRequired,
  minAge: PropTypes.any,
  onBlurJobTitle: PropTypes.func.isRequired,
  onPlaceSelected: PropTypes.func.isRequired,
  onPressCancel: PropTypes.func.isRequired,
  setActiveStepAddEditJob: PropTypes.func.isRequired,
  setShowDeleteJob: PropTypes.func.isRequired,
  setShowDuplicateJobAlert: PropTypes.func.isRequired,
  showCancelLeaveModal: PropTypes.bool.isRequired,
  showDeleteJob: PropTypes.bool.isRequired,
  showDuplicateJobAlert: PropTypes.bool.isRequired,
};

export default AddJobMobileView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const SelectContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
`;

const ActiveBlockContainer = styled("div")`
  display: flex;
`;

const JobDescriptionDiv = styled("div")`
  font-size: textSizes.s;
  line-height: "1.5";
  margin-top: spacing.xs;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 100px;
  padding-left: 15px;
  padding-top: 10px;
  overflow: auto;
`;
