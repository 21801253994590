import { useRef, useState } from "react";
import { AI_GENERATION_TYPES, DONE_CHUNK, fetchAIData } from "utils/aiUtils";

import Message from "./components/Message/Message.tsx";
import Input from "./components/Input/Input.tsx";
import History from "./components/History/History.tsx";

import "./Askhr.css";

function AskHr() {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [reply, setReply] = useState(null);

  const [history, setHistory] = useState([]);
  const abortControllerRef = useRef();

  const updateAskHrWithChunk = ({ chunk }) => {
    if (chunk === DONE_CHUNK) {
      // setIsGenerationComplete(true);
      return;
    }
    // setGeneratedText((prevText) => `${prevText}${chunk}`);
    setReply((prevText) => `${prevText}${chunk}`);

    setMessages((messages) => [...messages, { role: "bot", content: chunk }]);
  };

  const handleSubmit = async () => {
    const prompt = {
      role: "user",
      content: input,
    };

    setMessages([...messages, prompt]);
    setHistory((history) => [{ question: input }]);

    abortControllerRef.current = new AbortController();
    try {
      await fetchAIData({
        aiGenerationType: AI_GENERATION_TYPES.ASKHR,
        aiGeneratorInput: { prompt: input },
        dataUpdaterWithChunk: updateAskHrWithChunk,
        abortController: abortControllerRef.current,
      });

      // setReply(data.message);
    } catch (e) {
      console.log(e);
    }

    setInput("");
  };

  return (
    <div className="App">
      <div className="Column">
        <h3 className="Title">History</h3>
        <div className="Content">
          {history.map((el, i) => (
            <History
              key={i}
              question={el.question}
              onClick={() =>
                setMessages([
                  { role: "user", content: history[i].question },
                  { role: "bot", content: history[i].answer },
                ])
              }
            />
          ))}
        </div>
      </div>
      <div className="Column">
        <h3 className="Title">HR Chatbot</h3>
        <div className="Content">
          {messages.map((msg) => (
            <Message key={msg} role={msg.role} content={msg.content} />
          ))}
        </div>
        <Input
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onClick={input ? handleSubmit : undefined}
        />
      </div>
    </div>
  );
}

export default AskHr;
