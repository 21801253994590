import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, FormControl } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import OtpSelector from "../selectors/OtpSelectors";
import useIsMobile from "../../../hooks/useIsMobile";
import { resetEmailValidation, sendOtpRequest, verifyOtpRequest } from "../actions/OtpActions";

import { showNotification } from "../../../utils/commonUtils";
import { Icon, Text, OtpInput } from "../../../components/atoms";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import { colors, spacing, styleUtils, textSizes } from "../../../styles";

import * as OtpConsts from "./OtpConstants";
import "./OtpStyleSheet.css";
import { FORGOT_PASSWORD } from "containers/Account/constants";
import { FormHelperErrorText, ResendButton, ResendContainer } from "./styledConstants";

/**
 * Verification form for email otp validation
 */
const EmailVerificationForm = ({
  editEmail,
  handleBack,
  handleOtpSubmit,
  isDetailsEdited,
  isPhoneSignUp,
  setIsDetailsEdited,
  setMarkAsCompleted,
  sourceDetails: { email },
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const [emailSeconds, setEmailSeconds] = useState(60);

  const isOtpShareSuccess = useSelector(OtpSelector.getIsOtpShareSuccess);
  const IsOtpShareFailed = useSelector(OtpSelector.getIsOtpShareFailed);
  const emailValidation = useSelector(OtpSelector.getIsEmailValidation);
  const isEmailOtpValid = useSelector(OtpSelector.getIsEmailOtpValid);
  const isVerifying = useSelector(OtpSelector.getIsVerifying);

  const validate = (values) => {
    const errors = {};
    if (values.emailOtp.trim().length === 0 || values.emailOtp.trim().length < 6) {
      errors.emailOtp = FORGOT_PASSWORD.ERROR_OTP_LENGTH;
    } else if (values.emailOtp.trim().length === 6 && !isEmailOtpValid && !isVerifying) {
      errors.emailOtp = FORGOT_PASSWORD.ERROR_OTP_MISMATCH;
    }
    return errors;
  };

  const sendEmailOtp = (callbackFn) => {
    dispatch(
      sendOtpRequest({
        target: "email",
        email: email,
        onCallback: callbackFn,
      }),
    );
  };

  const otpNotification = () => {
    const message = isOtpShareSuccess
      ? OtpConsts.RESEND_SUCCESS
      : IsOtpShareFailed
      ? OtpConsts.RESEND_FAILED
      : "";
    const status = isOtpShareSuccess ? "success" : IsOtpShareFailed ? "error" : "";

    if (isOtpShareSuccess || IsOtpShareFailed) {
      showNotification({
        alertProps: {
          severity: status,
          children: message,
        },
      });
    } else {
      //No code here
    }
  };

  useEffect(() => {
    if (!isDetailsEdited) {
      sendEmailOtp(() => {});
    } else {
      // No code here
    }

    return () => {
      if (!isDetailsEdited) {
        dispatch(resetEmailValidation());
      } else {
        // No code here
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    if (emailSeconds !== 0) {
      timer = setInterval(() => {
        setEmailSeconds(emailSeconds - 1);
      }, 1000);
      if (emailSeconds === 0) {
        setEmailSeconds(60);
        clearInterval(timer);
      }
    }
    return () => clearInterval(timer);
  }, [emailSeconds]);

  const emailOtpFormik = useFormik({
    initialValues: {
      emailOtp: "",
    },
    validate,
    onSubmit: () => {
      if (emailValidation && isEmailOtpValid) {
        if (isPhoneSignUp) {
          logEvent(FirebaseEvents.EMP_PHONE_SIGNUP_PAGE3);
          handleBack(true);
        } else {
          handleOtpSubmit();
          logEvent(FirebaseEvents.EMP_EMAIL_SIGNUP_PAGE3);
        }
      }
    },
  });

  useEffect(() => {
    if (!isPhoneSignUp && emailValidation && isEmailOtpValid) {
      setMarkAsCompleted(true);
    }
  }, [emailValidation]);

  useEffect(() => {
    if (emailOtpFormik.values.emailOtp && emailOtpFormik.values.emailOtp.trim().length === 6) {
      dispatch(
        verifyOtpRequest({
          email: email,
          target: "email",
          emailOtp: emailOtpFormik.values.emailOtp,
        }),
      );
    }
  }, [emailOtpFormik.values.emailOtp]);

  useEffect(() => {
    if (emailValidation && isEmailOtpValid && !isPhoneSignUp) {
      handleOtpSubmit();
      logEvent(FirebaseEvents.EMP_EMAIL_SIGNUP_PAGE3);
    }
  }, [emailValidation, isEmailOtpValid]);

  const showEmailFormError =
    emailOtpFormik.touched.emailOtp && Boolean(emailOtpFormik.errors.emailOtp);
  const invalidEmailOtp = emailValidation && !isEmailOtpValid;

  const handleResend = () => {
    setEmailSeconds(60);
    sendEmailOtp(otpNotification);
  };
  const showZero = emailSeconds < 10 ? "0" : "";

  return (
    <EmailVerificationBox>
      <Text variant="h5" weight="semibold" size="l" color={colors.titleBlack}>
        {OtpConsts.OTP_EMAIL_VERIFY_HEADER}
      </Text>
      <Text
        size="s"
        color={colors.subtextGray}
        sx={{
          marginBottom: styleUtils.pxToRem("32px"),
        }}
      >
        {`${OtpConsts.OTP_EMAIL_VERIFY_SUBTEXT} ${email}`}
      </Text>
      <Text color={colors.labelBlack}>{OtpConsts.OTP_EMAIL_LABEL} *</Text>
      <FormControl required>
        <OtpInput
          value={emailOtpFormik.values.emailOtp}
          onChange={(text) => emailOtpFormik.setFieldValue("emailOtp", text)}
          isCodeVerified={isEmailOtpValid}
          hasError={showEmailFormError || invalidEmailOtp}
          isVerifying={isVerifying}
        />
        <ResendContainer>
          {showEmailFormError ||
            (invalidEmailOtp && (
              <>
                <Icon name="error" size={11} />
                <FormHelperErrorText>{emailOtpFormik.errors.emailOtp}</FormHelperErrorText>
              </>
            ))}

          <ResendButton
            variant="text"
            disabled={emailSeconds !== 0}
            onClick={handleResend}
            size="xs"
            className="otp-resend-link"
            sx={{
              color: emailSeconds !== 0 ? colors.timerGray : colors.link,
              border: "none",
              boxShadow: "none",
              position: "absolute",
              left: emailSeconds !== 0 ? "160px" : "210px",
            }}
          >
            {emailSeconds !== 0
              ? `Resend code in ${"00"}:${showZero}${emailSeconds}`
              : OtpConsts.RESEND_CODE_TEXT}
          </ResendButton>
        </ResendContainer>
      </FormControl>
      <Button
        variant="text"
        onClick={() => {
          editEmail(true);
          setIsDetailsEdited(true);
        }}
        sx={{
          mt: spacing.s,
          border: "none",
          boxShadow: "none",
          fontSize: textSizes.m,
          textDecoration: "underline",
          width: "fit-content",
          p: 0,
          "&:hover": {
            backgroundColor: colors.white,
            textDecoration: "underline",
          },
        }}
      >
        {OtpConsts.OTP_EMAIL_EDIT_LINK}
      </Button>
      <FormsBtnContainer
        sx={{
          marginTop: styleUtils.pxToRem(isMobile ? "330px" : "190px"),
        }}
      >
        <Icon
          name="arrow-back"
          size={42}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setMarkAsCompleted(false);
            handleBack(false);
          }}
        />
        <Button
          variant="contained"
          disabled={!isEmailOtpValid}
          onClick={emailOtpFormik.handleSubmit}
          sx={{
            marginLeft: "auto",
            width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
            height: styleUtils.pxToRem("42px"),
            fontSize: textSizes.m,
          }}
        >
          {isPhoneSignUp ? OtpConsts.OTP_NEXT_BUTTON : OtpConsts.OTP_SUBMIT_BUTTON}
        </Button>
      </FormsBtnContainer>
    </EmailVerificationBox>
  );
};

EmailVerificationForm.propTypes = {
  editEmail: PropTypes.func,
  handleBack: PropTypes.func,
  handleOtpSubmit: PropTypes.func,
  isDetailsEdited: PropTypes.bool,
  isPhoneSignUp: PropTypes.bool,
  setIsDetailsEdited: PropTypes.func,
  setMarkAsCompleted: PropTypes.func,
  sourceDetails: PropTypes.any,
};

export default EmailVerificationForm;

const EmailVerificationBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: auto;
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;
