export const EMPLOYER_FORM_SIGN_UP_LABEL = "Employer Sign Up";

export const EMPLOYER_FORM_DESIGNATION_LABEL = "Your Position";
export const EMPLOYER_FORM_DESIGNATION_PLACEHOLDER = "enter position";
export const EMPLOYER_FORM_MULTI_LOCATIONS_LABEL = "Are you present in multiple locations?";
export const EMPLOYER_FORM_MULTI_LOCATIONS_PLACEHOLDER = "Select from the dropdown";

export const EMPLOYER_FORM_NAME_LABEL = "Name";
export const EMPLOYER_FORM_NAME_PLACEHOLDER = "enter employer's full name";
export const EMPLOYER_FORM_TERMS_TEXT = "I accept the Terms of service";
export const EMPLOYER_REGISTRATION_FORM_LABEL = "Employer Registration Form";
export const EMPLOYER_REGISTRATION_FORM_SUBMIT_LABEL = "Submit";

export const EMPLOYER_NEXT_BTN = "Next";

export const EMPLOYER_DETAILS_HEADER = "Employer Account";
export const EMPLOYER_DETAILS_SUBTEXT = "On registering you can";
export const EMPLOYER_DETAILS_INFO_ONE = "Post your job free";
export const EMPLOYER_DETAILS_INFO_TWO = "Search for nearby job seekers";
export const EMPLOYER_DETAILS_INFO_THREE = "Reach out to them directly";
export const EMPLOYER_DETAILS_INFO_FOUR = "Fill open positions quickly";

export const EMPLOYER_SIGNUP_FORM_ONE = {
  ADD_COMPANY_LOGO: "Add Company Logo",
  COMPANY_LOGO_TOOLTIP_TEXT: "Add your company’s logo to make your job posting standout",
  LABEL_FULL_NAME: "Full Name",
  PLACEHOLDER_FULL_NAME: "Enter your name",
  LABEL_EMAIL: "Email",
  PLACEHOLDER_EMAIL: "enter your email",
  LABEL_PHONE_NUMBER: "Phone number",
  PLACEHOLDER_PHONE_NUMBER: "enter phone number",
  LABEL_COMPANY: "Company",
  PLACEHOLDER_COMPANY: "enter company name",
  LABEL_WEBSITE: "Company Website (Optional)",
  PLACEHOLDER_WEBSITE: "Enter the website url link",
  BUTTON_NEXT: "Next",
};

export const EMPLOYER_SIGNUP_FORM_TWO = {
  LABEL_EMPLOYEESIZE: "How many employees do you currently have?",
  PLACEHOLDER_EMPLOYEESIZE: "Select from the dropdown",
  LABEL_DESIGNATION: "Enter your position in the company",
  PLACEHOLDER_DESIGNATION: "enter position",
  PLACEHOLDER_OTHER_DESIGNATION: "Your Position",
  LABEL_MULTI_LOCATIONS: "Are you present in multiple locations?",
  PLACEHOLDER_MULTI_LOCATIONS: "Select from the dropdown",
  LABEL_PASSWORD: "Password",
  PLACEHOLDER_PASSWORD: "Enter the password",
  LABEL_CONFIRM_PASSWORD: "Confirm Password",
  BUTTON_NEXT: "Next",
  EMPLOYEE_DESIGNATION_OPTIONS: [
    "Business Owner",
    "Manager",
    "HR/Talent Professional",
    "Director/VP",
    "Admin",
    "C-Level",
    "Other",
  ],
  EMPLOYEE_MULTI_LOCATION_OPTIONS: ["Yes", "No"],
  EMPLOYEE_SIZE_OPTIONS: ["1-10", "11-50", "51-100", "101-250", "251-500", "501-1000", "1000+"],
};

export const SIGN_UP_EMPLOYER_KEY = "employer";

export const EMPLOYER_FORM_ONE_DETAILS = {
  photoURL: "",
  fullName: "",
  email: "",
  countryCode: "",
  phoneNumber: "",
  companyName: "",
  website: "",
  referrerId: "",
  referrerType: "",
};

export const EMPLOYEE_SIZE_OPTIONS = [
  "1-10",
  "11-50",
  "51-100",
  "101-250",
  "251-500",
  "501-1000",
  "1000+",
];

export const EMPLOYER_FORM_TWO_DETAILS = {
  employee_size: "",
  designation: "",
  multiple_location: null,
  password: "",
  confirmPassword: "",
  tandc: false,
};

export const EMPLOYEE_DESIGNATION_OPTIONS = [
  "Business Owner",
  "Manager",
  "HR/Talent Professional",
  "Director/VP",
  "Admin",
  "C-Level",
  "Other",
];

export const EMPLOYEE_MULTI_LOCATION_OPTIONS = ["Yes", "No"];

export const EMPLOYER_REGISTRATION_FORM_DETAILS = {
  companyName: "",
  employee_size: "",
  website: "",
  fullName: "",
  designation: "",
  phoneNumber: "",
  email: "",
  multiple_location: null,
  loginType: "OL",
};

export const EMPLOYER_SIGNUP_UI_ERROR_MESSAGES = {
  //PAGE 1
  REQUIRED_NAME_TEXT: "Please enter your name",
  REQUIRED_EMAIL_TEXT: "Please enter your email id",
  EMAIL_INVALID: "Please enter a valid email id",
  EMAIL_ALREADY_EXISTS: "User with this email id already exists",
  REQUIRED_PHONE_TEXT: "Please enter your phone number",
  PHONE_INVALID: "Please enter a valid phone number",
  REQUIRED_COMPANY_NAME_TEXT: "Please enter your company name",

  //PAGE 2
  REQUIRED_EMPLOYEE_SIZE_TEXT: "Please specify number of employees",
  REQUIRED_DESIGNATION_TEXT: "Please specify your position",
  REQUIRED_MULTIPLE_LOCATION_TEXT: "Please specify if you are available in multiple locations",
  REQUIRED_PASSWORD_TEXT: "Please enter your password",
  PASSWORD_MIN: "Password should be a minimum of 6 characters",
  REQUIRED_CONFIRM_PASSWORD_TEXT: "Please confirm your password",
  PASSWORD_DO_NOT_MATCH: "Your passwords do not match",
};

export const REGISTRATION_FLOW_UI_ERROR_MESSAGES = {
  REQUIRED_EMPLOYER_NAME_TEXT: "Please enter employer's name",
  EMAIL_INVALID: "Please enter a valid email",
  REQUIRED_EMAIL_TEXT: "Please enter employer's email id",
  REQUIRED_COMPANY_NAME_TEXT: "Please enter employer's company name",
  REQUIRED_DESIGNATION_TEXT: "Please specify employer's position",
  REQUIRED_OTHER_DESIGNATION_TEXT: "Please enter employer's designation",
  REQUIRED_EMPLOYEE_SIZE_TEXT: "Please specify number of employees",
  REQUIRED_MULTIPLE_LOCATION_TEXT:
    "Please specify if the employer is available in multiple locations",
  REQUIRED_PHONE_TEXT: "Please enter employer's phone number",
  PHONE_INVALID: "Please enter a valid phone number",
};
