import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Avatar,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import useIsMobile from "../../../hooks/useIsMobile";
import { isRecentAppliedJob } from "utils/dateUtils";
import { renderImage } from "../../../utils/imageUtils";
import { logEvent } from "../../../configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";
import {
  getProcessedJobDetailValue,
  getProcessedExperienceValue,
} from "../../../utils/commonUtils";
import { usePeopleCardHelpers } from "./PeopleCardHelpers";

import { Icon, Text } from "../../atoms";
import { colors, spacing, styleUtils } from "../../../styles";

import LoginSelector from "../../../containers/Login/selectors/LoginSelector";
import { images } from "../../../assets/images";
import { PEOPLE_CARD_CONSTS } from "./constants";

const PeopleCard = ({
  item,
  cardStyle,
  size = "m",
  hideBottomContainer = false,
  onClickPeople,
}) => {
  const isMobile = useIsMobile();
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const isResumeRequested =
    item?.resumeEmpIds && item?.resumeEmpIds.includes(loggedInUser?.uid);

  const isAppliedUser = item?.favoriteJobs?.some(
    (job) => job.companyId === loggedInUser?.uid
  );

  const recentAppliedUser = item?.favoriteJobs
    ?.filter((job) => job?.companyId === loggedInUser?.uid)
    .sort((a, b) => b?.jobAppliedDate - a?.jobAppliedDate)[0];

  const showNewAppliedTag =
    isAppliedUser && isRecentAppliedJob(recentAppliedUser?.jobAppliedDate);
  const appliedJobs =
    item?.favoriteJobs?.filter((job) => job.companyId === loggedInUser?.uid) ||
    [];
  const [appliedJobDetail] = appliedJobs;

  const { onClickRequestResume } = usePeopleCardHelpers({
    loggedInUser,
    item,
    isResumeRequested,
  });

  const onClickCard = (e) => {
    e.stopPropagation();
    if (!hideBottomContainer) {
      onClickPeople(item);
    }
  };

  const handleViewResume = (e) => {
    e.stopPropagation();
    logEvent(FirebaseEvents.EMP_CLICKS_VIEW_RESUME);
  };

  return (
    <Card
      sx={{
        transform: `scale(${size === "m" ? 1 : 0.875})`,
        cursor: "pointer",
        ...cardStyle,
      }}
      onClick={onClickCard}
    >
      <div>
        <TopContainer
          sx={{ padding: isMobile ? spacing.s : styleUtils.pxToRem("12px") }}
        >
          <Avatar
            src={renderImage(item?.photoURL, "user")}
            variant="rounded"
            sx={{ width: 57, height: 57, borderRadius: 57 }}
          >
            <img
              alt={item?.fullName}
              src={images.defaults.user}
              style={{ width: 57, height: 57, borderRadius: 57 }}
            />
          </Avatar>
          <TitleContainer>
            <Row sx={{ justifyContent: "space-between" }}>
              <Text weight="bold" numberOfLines={1} disableScroll>
                {item?.fullName}
              </Text>
              {showNewAppliedTag && (
                <AppliedStatusContainer>
                  <Text weight="semibold" color="#2B9433" size="xxs">
                    New Applicant
                  </Text>
                </AppliedStatusContainer>
              )}
            </Row>
            <Row>
              <Text size="xxs" color={colors.brownText}>
                {item?.age} yrs
              </Text>
            </Row>
            {isAppliedUser && (
              <Text
                weight="medium"
                size="xxs"
                component="span"
                color={colors.brownText}
              >
                {PEOPLE_CARD_CONSTS.LABEL_APPLIED_FOR}
                <Text
                  size="xxs"
                  weight="medium"
                  component="span"
                  color={colors.brownText}
                  sx={{ backgroundColor: "#FFF6EA", marginRight: spacing.xxs }}
                >
                  {appliedJobDetail?.jobTitle.length > 10
                    ? `${appliedJobDetail.jobTitle.substring(0, 10)}...`
                    : appliedJobDetail.jobTitle}
                </Text>
                {appliedJobs.length > 1 && (
                  <Text
                    weight="medium"
                    size="xxs"
                    component="span"
                    color="#2B4894"
                    sx={{ backgroundColor: "#F2F6FF", width: spacing.xs }}
                  >
                    {PEOPLE_CARD_CONSTS.LABEL_APPLIED_JOBS_COUNT}
                    {appliedJobs.length - 1}
                  </Text>
                )}
              </Text>
            )}
          </TitleContainer>
        </TopContainer>
        <TableContainer sx={{ padding: `0 ${spacing.s}` }}>
          <Table>
            <TableBody>
              <TableRow sx={{ border: 0 }}>
                <TableCell
                  sx={{
                    border: 0,
                    borderRight: `1px solid ${colors.stroke}`,
                    padding: `${spacing.xs} 0`,
                    paddingRight: spacing.xs,
                    width: "25%",
                  }}
                >
                  <Icon name="experience" alt={"experience"} size={20} />
                  <Text weight="medium" size="xs" numberOfLines={1}>
                    {getProcessedExperienceValue(item?.experience)}
                  </Text>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    borderRight: `1px solid ${colors.stroke}`,
                    padding: `${spacing.xs} 0`,
                    paddingRight: spacing.xs,
                    width: "40%",
                  }}
                >
                  <Icon
                    name="clock"
                    alt={"clock"}
                    size={20}
                    style={{ marginLeft: spacing.s }}
                  />
                  <Text
                    weight="medium"
                    size="xs"
                    style={{ marginLeft: spacing.s }}
                    numberOfLines={1}
                  >
                    {item?.workingHours &&
                      `${getProcessedJobDetailValue(
                        item?.workingHours
                      )} hr/week`}
                  </Text>
                </TableCell>
                <TableCell
                  sx={{
                    border: 0,
                    padding: `${spacing.xs} 0`,
                    width: "30%",
                  }}
                >
                  <Icon
                    name="money"
                    alt={"money"}
                    size={20}
                    style={{ marginLeft: spacing.s }}
                  />
                  <Text
                    weight="medium"
                    size="xs"
                    style={{ marginLeft: spacing.s }}
                    numberOfLines={1}
                  >
                    ${getProcessedJobDetailValue(item?.wageExpectation)}/hr
                  </Text>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {!item?.resumeUrl && !isResumeRequested && (
          <RequestResumeContainer href="#" onClick={onClickRequestResume}>
            <Text size="s" weight="bold" color={colors.white}>
              Request Resume
            </Text>
          </RequestResumeContainer>
        )}
        {!item?.resumeUrl && isResumeRequested && (
          <RequestedResumeContainer href="#">
            <Text size="s" weight="bold" color={colors.disableGrey}>
              Requested Resume
            </Text>
          </RequestedResumeContainer>
        )}
        {item?.resumeUrl && (
          <RequestedResumeContainer
            target="_blank"
            rel="noreferrer"
            download
            onClick={handleViewResume}
            href={item?.resumeUrl}
          >
            <Text size="s" weight="bold" color={colors.primary}>
              View Resume
            </Text>
          </RequestedResumeContainer>
        )}
      </div>
    </Card>
  );
};

export default PeopleCard;

PeopleCard.propTypes = {
  item: PropTypes.object.isRequired,
  cardStyle: PropTypes.object,
  size: PropTypes.string,
  hideBottomContainer: PropTypes.bool,
  onClickPeople: PropTypes.func.isRequired,
};

const TopContainer = styled("div")`
  display: flex;
  flex-direction: row;
`;

const TitleContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: ${spacing.s};
`;

const AppliedStatusContainer = styled("div")`
  border: 0.5px solid #2b9433;
  border-radius: 2px;
  background-color: #effff0;
  height: ${styleUtils.pxToRem("18px")};
  width: ${styleUtils.pxToRem("82px")};
  padding: ${styleUtils.pxToRem("2px")};
  margin-top: ${spacing.xxs};
  text-align: center;
  animation: fading 1.9s linear infinite;
  -webkit-animation: fading 1.9s linear infinite;
  @keyframes fading {
    0% {
      background-color: #b9f4bd;
    }
    50% {
      background-color: #fff;
    }
    100% {
      background-color: #b9f4bd;
    }
  }
  @-webkit-keyframes fading {
    0% {
      background-color: #b9f4bd;
    }
    50% {
      background-color: #fff;
    }
    100% {
      background-color: #b9f4bd;
    }
  }
`;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
`;

const RequestResumeContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.primary};
  margin-top: ${spacing.s};
  padding: ${spacing.xs} 0;
`;

const RequestedResumeContainer = styled("a")`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.disableGrey};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  margin-top: ${spacing.s};
  padding: ${spacing.xs} 0;
  text-decoration: none;
`;
