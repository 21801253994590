import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import isEmpty from "lodash/isEmpty";
import JobBoardContainer from "./views/JobBoard/JobBoardContainer";

import JobSeekerSelector from "../../selectors/JobSeekerSelector";
import LoginSelector from "../../../Login/selectors/LoginSelector";
import { jobSeekerListingRequest, jobSeekerReset } from "../../actions/JobSeekerActions";
import { searchLocationUpdate } from "../../../Login/actions/LoginActions";
import usePreviousRoute from "../../../../hooks/usePreviousRoute";

import { hideLoader, setLoginRedirection, showNotification } from "../../../../utils/commonUtils";
import { getSeekersJobsSuggestions } from "../../../../configs/network/apis";
import useIsMobile from "hooks/useIsMobile";
import { EMPLOYD, JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS } from "appConstants/titleTagsConstant";

const ExploreContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const previousRoute = usePreviousRoute();
  const googleInputRef = useRef();
  const { state } = useLocation();
  const isMobile = useIsMobile();

  const [searchParams, setSearchParams] = useSearchParams();
  const [sortingOrder, setSortingOrder] = useState({});
  const [isLocationAllowed, setIsLocationAllowed] = useState(true);
  const [jobListPage, setJobListPage] = useState(1);

  const [jobSuggestions, setJobSuggestions] = useState([]);
  const [suggestedLocationAddressValuesForTitle, setSuggestedLocationAddressValuesForTitle] =
    useState("");

  const showMapView = searchParams.get("showMapView");
  const showJobDetailView = searchParams.get("showJobDetailView");
  const previousJobId = searchParams.get("jobId");
  const previousBrandName = searchParams.get("brandName");
  const showCompanyDetailView = searchParams.get("showCompanyDetailView");

  const jobSeekerListing = useSelector(JobSeekerSelector.getJobSeekerListing);
  const totalJobsCount = useSelector(JobSeekerSelector.getTotalJobsCount);
  const isJobListFetching = useSelector(JobSeekerSelector.isJobListFetching);
  const jobSeekerListingPageLimit = useSelector(JobSeekerSelector.getPageLimitJobSeekerListing);
  const jobSeekerSuggestedResults = useSelector(JobSeekerSelector.getSuggestedJobs);
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);
  const filterSettings = useSelector(
    LoginSelector.getLoggedInUserSettings("jobSeekerListingFilter"),
  );

  const userLocation = useSelector(LoginSelector.getLoggedInUserCurrentLocation);
  const searchLocation = useSelector(LoginSelector.getSearchLocation);

  const location = {
    lat: searchLocation?.lat || userLocation?.lat,
    lng: searchLocation?.lng || userLocation?.lng,
    locationName: searchLocation?.locationName || userLocation?.locationName,
  };

  const searchLatValue = searchLocation?.lat ? searchLocation.lat : userLocation?.lat;

  const searchLngValue = searchLocation?.lng ? searchLocation.lng : userLocation?.lng;

  const searchTerm = searchParams.get("searchTerm") || "";

  const { values, handleChange, setFieldValue } = useFormik({
    initialValues: {
      searchTerm: searchTerm,
      lat: location?.lat,
      lng: location?.lng,
      locationName: location?.locationName,
    },
  });

  let mobileSearchValues = {
    term: values?.searchTerm,
    location: values?.locationName,
  };

  useEffect(() => {
    if (location?.locationName) {
      mobileSearchValues = { ...mobileSearchValues, location: location?.locationName };
    }
  }, [location]);

  useEffect(() => {
    if (state?.handleInactiveLink) {
      showNotification({
        alertProps: {
          severity: "info",
          children: "This job is not active anymore. Here are more jobs in the same location",
        },
      });
      onLocationChange(state?.locationDetails);
      location["lat"] = state?.locationDetails?.lat;
      location["lng"] = state?.locationDetails?.lng;
      location["locationName"] = state?.locationDetails?.locationName;
      onClickSearch(values?.searchTerm)();
    }
  }, [state?.handleInactiveLink]);

  useEffect(() => {
    if (
      previousRoute !== "/login" &&
      !showJobDetailView &&
      !showMapView &&
      !isJobListFetching &&
      searchLatValue
    ) {
      init();
    }
  }, [previousRoute, searchLatValue]);

  useEffect(() => {
    if (searchLocation && isLocationAllowed) {
      handleExploreJobsTitleTags();
    } else {
      document.title = JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.JOBSEEKER_NO_LOCATION_SEARCHED_TITLE_TAG;
    }
  }, [searchLocation, searchTerm, isLocationAllowed, previousRoute]);

  const init = async () => {
    //to reset the state when job listing is loaded fully once
    try {
      dispatch(jobSeekerReset());

      dispatch(
        jobSeekerListingRequest({
          userId: loggedInUser?.uid || "",
          searchTerm,
          userLat: userLocation?.lat,
          userLng: userLocation?.lng,
          searchedLat: searchLatValue,
          searchedLng: searchLngValue,
          email: loggedInUser?.email,
          status: "active",
          distance: filterSettings?.distance || 50,
          wage: filterSettings?.wage || [],
          hrsPerWeek: filterSettings?.hrsPerWeek || [],
          experience: filterSettings?.experience || "",
          days: filterSettings?.days || [],
          timeSlot: filterSettings?.timeSlot || [],
          locationString: mobileSearchValues?.location,
          page: jobListPage,
          limit: 20,
          totalRecords: totalJobsCount,
          source: isMobile ? "resp" : "web",
          setIsLocationAllowed,
        }),
      );
    } catch (error) {
      console.log(error.message);
    } finally {
      hideLoader();
    }
  };

  const handleExploreJobsTitleTags = () => {
    const searchedLocationName = searchLocation?.locationName;
    const [localityForTitleTag, AdminLevel_1_ForTitleTag] = searchedLocationName?.split(",") || [];
    const titleCurrentLocAndSearchedVal = `${localityForTitleTag}, ${AdminLevel_1_ForTitleTag}`;

    let finalLocationTitleToShow =
      suggestedLocationAddressValuesForTitle || titleCurrentLocAndSearchedVal;

    if (searchedLocationName === userLocation?.locationName) {
      finalLocationTitleToShow = searchedLocationName?.split(",").slice(-3, -1).join(",");
    }

    if (!searchedLocationName) {
      document.title = JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.JOBSEEKER_NO_LOCATION_SEARCHED_TITLE_TAG;
      return;
    }

    //Case1: if  search term and searched location is there
    if (previousRoute === "/" || (searchedLocationName && searchTerm)) {
      document.title = `${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.PART_TIME_TITLE}${searchTerm}${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.JOBS_TITLE_TEXT}${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.IN_TITLE_TEXT}${finalLocationTitleToShow} | ${EMPLOYD}`;
      return;
    }

    //Case2: if only searched location is there
    if (previousRoute === "/" || (searchedLocationName && !searchTerm)) {
      document.title = `${JOBSEEKER_EXPLORE_JOBS_TITLE_TAGS.JOBSEEKER_LOCATION_ALLOWED_TITLE_TAG_PREFIX}${finalLocationTitleToShow} | ${EMPLOYD}`;
    }
  };

  const toggleExploreView = () => {
    if (showMapView || showJobDetailView) {
      navigate("/jobSeeker/explorejobs", {
        replace: true,
        preventScrollReset: true,
      });
    } else {
      setLoginRedirection(loggedInUser?.uid, "", "?showMapView=true");
      setSearchParams({ showMapView: true });
    }
  };

  const onLocationChange = (location) => {
    dispatch(
      searchLocationUpdate({
        lat: location.lat,
        lng: location.lng,
        locationName: location.locationName,
      }),
    );
  };

  const onClickSearch = (searchText) => () => {
    if (isEmpty(searchText)) {
      setSearchParams({
        ...(showMapView && {
          showMapView,
        }),
        ...(showJobDetailView && {
          showJobDetailView,
          jobId: previousJobId,
          brandName: previousBrandName,
        }),
      });
    } else {
      setSearchParams({
        searchTerm: searchText,
        ...(showMapView && {
          showMapView,
        }),
        ...(showJobDetailView && {
          showJobDetailView,
          jobId: previousJobId,
          brandName: previousBrandName,
        }),
      });
    }
    dispatch(
      searchLocationUpdate({
        lat: values.lat,
        lng: values.lng,
        locationName: values.locationName,
      }),
    );
    setJobListPage(1);
    dispatch(jobSeekerReset());
    dispatch(
      jobSeekerListingRequest({
        userId: loggedInUser?.uid || "",
        searchTerm: searchText,
        userLat: userLocation?.lat,
        userLng: userLocation?.lng,
        searchedLat: values?.lat,
        searchedLng: values?.lng,
        email: loggedInUser?.email,
        status: "active",
        distance: filterSettings?.distance || 50,
        wage: filterSettings?.wage || [],
        hrsPerWeek: filterSettings?.hrsPerWeek || [],
        experience: filterSettings?.experience || [],
        days: filterSettings?.days || [],
        timeSlot: filterSettings?.timeSlot || [],
        locationString: values.locationName,
        page: 1,
        limit: 20,
        totalRecords: 0,
        source: isMobile ? "resp" : "web",
        setIsLocationAllowed,
      }),
    );
  };

  const onClickJob = (job) => {
    setLoginRedirection(
      loggedInUser?.uid,
      "",
      `?showJobDetailView=true&jobId=${job.jobId}&brandName=${job.brandName}`,
    );
    setSearchParams({
      searchTerm,
      showJobDetailView: true,
      jobId: job.jobId,
      brandName: job.brandName,
    });
  };

  const onClickBrandLogo = (job) => {
    setLoginRedirection(
      loggedInUser?.uid,
      "",
      `?showCompanyDetailView=true&showJobDetailView=true&brandName=${job.brandName}&jobId=${job.jobId}&recruiterId=${job.recruiterId}`,
    );
    setSearchParams({
      showCompanyDetailView: true,
      showJobDetailView: false,
      brandName: job.brandName,
      jobId: job.jobId,
      recruiterId: job.recruiterId,
    });
  };

  const handleSorting = (key, sortOrder) => {
    setJobListPage(1);
    dispatch(jobSeekerReset());
    if (key === "clear") {
      dispatch(
        jobSeekerListingRequest({
          userId: loggedInUser?.uid || "",
          email: loggedInUser?.email,
          searchTerm,
          userLat: userLocation?.lat,
          userLng: userLocation?.lng,
          searchedLat: searchLatValue,
          searchedLng: searchLngValue,
          status: "active",
          distance: filterSettings?.distance || 50,
          wage: filterSettings?.wage || [],
          hrsPerWeek: filterSettings?.hrsPerWeek || [],
          experience: filterSettings?.experience || [],
          days: filterSettings?.days || [],
          timeSlot: filterSettings?.timeSlot || [],
          locationString: location.locationName,
          totalRecords: totalJobsCount,
          source: isMobile ? "resp" : "web",
        }),
      );
      setSortingOrder({});
    } else {
      dispatch(
        jobSeekerListingRequest({
          userId: loggedInUser?.uid || "",
          searchTerm,
          userLat: userLocation?.lat,
          userLng: userLocation?.lng,
          searchedLat: searchLatValue,
          searchedLng: searchLngValue,
          locationString: location.locationName,
          email: loggedInUser?.email,
          status: "active",
          distance: filterSettings?.distance || 50,
          wage: filterSettings?.wage || [],
          hrsPerWeek: filterSettings?.hrsPerWeek || [],
          experience: filterSettings?.experience || [],
          days: filterSettings?.days || [],
          timeSlot: filterSettings?.timeSlot || [],
          sortKey: key,
          sortOrder: sortOrder,
          totalRecords: totalJobsCount,
          source: isMobile ? "resp" : "web",
        }),
      );
      setSortingOrder({ sortKey: key, order: sortOrder });
    }
  };

  const handleFilter = (values) => {
    setJobListPage(1);
    dispatch(jobSeekerReset());
    dispatch(
      jobSeekerListingRequest({
        userId: loggedInUser?.uid || "",
        searchTerm,
        userLat: userLocation?.lat,
        userLng: userLocation?.lng,
        searchedLat: searchLatValue,
        searchedLng: searchLngValue,
        locationString: location?.locationName,
        email: loggedInUser?.email,
        sortKey: sortingOrder?.sortKey,
        sortOrder: sortingOrder?.order,
        page: isEmpty(values) ? 1 : undefined,
        limit: isEmpty(values) ? 20 : undefined,
        totalRecords: 0,
        status: "active",
        distance: isEmpty(values) ? 50 : values?.distance,
        ...values,
        source: isMobile ? "resp" : "web",
      }),
    );
  };

  const handleLoadMore = () => {
    if (jobListPage + 1 <= jobSeekerListingPageLimit) {
      dispatch(
        jobSeekerListingRequest({
          userId: loggedInUser?.uid || "",
          searchTerm,
          userLat: userLocation?.lat,
          userLng: userLocation?.lng,
          searchedLat: searchLatValue,
          searchedLng: searchLngValue,
          email: loggedInUser?.email,
          sortKey: sortingOrder?.sortKey,
          sortOrder: sortingOrder?.order,
          status: "active",
          distance: filterSettings?.distance || 50,
          wage: filterSettings?.wage || [],
          hrsPerWeek: filterSettings?.hrsPerWeek || [],
          experience: filterSettings?.experience || [],
          days: filterSettings?.days || [],
          timeSlot: filterSettings?.timeSlot || [],
          locationString: location.locationName,
          page: jobListPage + 1,
          limit: 20,
          totalRecords: totalJobsCount,
          source: isMobile ? "resp" : "web",
        }),
      );
      setJobListPage(jobListPage + 1);
    }
  };

  const fetchJobSuggestions = async (value) => {
    if (!value) {
      setJobSuggestions([]);
      return;
    }
    try {
      const res = await getSeekersJobsSuggestions({
        searchTerm: value,
      });
      setJobSuggestions(res.data);
    } catch (error) {
      setJobSuggestions([]);
    }
  };

  return (
    <JobBoardContainer
      filterSettings={isLocationAllowed ? filterSettings : {}}
      handleFilter={handleFilter}
      handleLoadMore={handleLoadMore}
      handleSorting={handleSorting}
      isLocationAllowed={isLocationAllowed}
      jobListPage={jobListPage}
      jobSeekerListing={jobSeekerListing}
      jobSeekerSuggestedResults={jobSeekerSuggestedResults}
      location={location}
      loggedInUser={loggedInUser}
      mobileSearchValues={mobileSearchValues}
      onClickSearch={onClickSearch}
      onClickJob={onClickJob}
      onClickBrandLogo={onClickBrandLogo}
      onChangeSearchTerm={handleChange}
      searchTerm={values.searchTerm}
      showLoadMore={jobListPage < jobSeekerListingPageLimit}
      showMapView={showMapView}
      showJobDetailView={showJobDetailView}
      showCompanyDetailView={showCompanyDetailView}
      toggleExploreView={toggleExploreView}
      totalJobsCount={totalJobsCount}
      googleInputRef={googleInputRef}
      setFieldValue={setFieldValue}
      jobSuggestions={jobSuggestions}
      fetchJobSuggestions={fetchJobSuggestions}
      setSuggestedLocationAddressValuesForTitle={setSuggestedLocationAddressValuesForTitle}
    />
  );
};

export default ExploreContainer;
