import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import InputBase from "@mui/material/InputBase";
import { usePlacesWidget } from "react-google-autocomplete";
import isEmpty from "lodash/isEmpty";
import ButtonIcon from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";

import LoginSelector from "containers/Login/selectors/LoginSelector";

import IconButton from "../IconButton";
import CurrentLocation from "components/molecules/CurrentLocation";
import { noop } from "lodash";

import {
  BASE_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
} from "routes/routeConstants";

import { colors, spacing, styleUtils, textSizes } from "../../../styles";

const GoogleInput = forwardRef(
  (
    {
      onPlaceSelected,
      onClear,
      setSearchValue = noop,
      disableSearch,
      onClickSearch,
      showSearchIcon = false,
      retainValue,
      ...props
    },
    fRef,
  ) => {
    const places = import.meta.env.VITE_APP_ENVIRONMENT === "PRODUCTION" ? ["us"] : ["us", "in"];
    const isCurrentLocationUpdating = useSelector(LoginSelector.getIsUserLocationUpdating);
    const userCurrentLocation = useSelector(LoginSelector.getLoggedInUserCurrentLocation);
    const locationName = userCurrentLocation?.locationName;

    const { ref } = usePlacesWidget({
      apiKey: import.meta.env.VITE_APP_GOOGLE_PLACES_API_KEY,
      options: {
        fields: ["formatted_address", "geometry", "name", "address_components"],
        strictBounds: false,
        types: ["establishment", "geocode"],
        componentRestrictions: {
          country: places,
        },
      },
      onPlaceSelected: (place) =>
        onPlaceSelected({
          ...place,
          addressComponents: getAddressComponents(place),
          inputValue: ref.current.value,
        }),
    });

    useImperativeHandle(fRef, () => ({
      changeValue: (newValue) => {
        ref.current.value = newValue;
        if (!isEmpty(newValue)) {
          setShowClearIcon(true);
        }
      },
    }));

    const [showClearIcon, setShowClearIcon] = useState(false);

    useEffect(() => {
      if (!isCurrentLocationUpdating) {
        ref.current.value = retainValue || locationName || "";
        setSearchValue("locationName", locationName);
      }
    }, [isCurrentLocationUpdating]);

    useEffect(() => {
      if (!ref?.current?.value) {
        ref.current.value = retainValue || "";
      }
    }, []);

    useEffect(() => {
      if (isEmpty(ref.current?.value)) {
        setShowClearIcon(false);
      } else {
        setShowClearIcon(true);
      }
    }, [ref.current?.value]);

    const { pathname } = useLocation();

    const isShowCurrentLocationIcon =
      pathname === BASE_PAGE_ROUTE ||
      pathname === `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`;

    const inputEndAdornment = () => {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {isShowCurrentLocationIcon && (
            <CurrentLocation googleInputRef={ref} setSearchValue={setSearchValue} />
          )}
          {showClearIcon && (
            <ButtonIcon style={{ padding: "0" }} aria-label="delete" onClick={onClear}>
              <ClearIcon fontSize="small" />
            </ButtonIcon>
          )}
          {showSearchIcon && (
            <IconButton
              disabled={disableSearch}
              name={disableSearch ? "search" : "search-white"}
              onClick={onClickSearch}
              style={{
                borderRadius: "50%",
                width: styleUtils.pxToRem("56px"),
                height: styleUtils.pxToRem("56px"),
                border: "1px solid #2C4894",
                backgroundColor: disableSearch ? colors.white : "#2C4894",
              }}
            />
          )}
        </div>
      );
    };

    return (
      <InputBase
        inputRef={ref}
        autoComplete="off"
        sx={{
          "& .MuiInputBase-input": {
            fontSize: showSearchIcon ? textSizes.l : textSizes.s,
            "&::placeholder": {
              opacity: 1,
              fontSize: showSearchIcon ? textSizes.l : textSizes.s,
              color: colors.border,
            },
          },
          borderTopRightRadius: 40,
          borderBottomRightRadius: 40,
          paddingRight: spacing.xxs,
        }}
        {...props}
        endAdornment={inputEndAdornment()}
      />
    );
  },
);

const getAddressComponents = (place) => {
  const addressComponentsList = place.address_components;
  const addressComponents = {};
  addressComponentsList.forEach((component) => {
    const componentType = component.types[0];
    addressComponents[componentType] = component.short_name;
  });
  return addressComponents;
};

GoogleInput.propTypes = {
  onPlaceSelected: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  setSearchValue: PropTypes.func,
  disableSearch: PropTypes.bool,
  onClickSearch: PropTypes.func.isRequired,
  showSearchIcon: PropTypes.bool,
  retainValue: PropTypes.string,
};

export default GoogleInput;
