import { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Card, Container, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import debounce from "lodash/debounce";
import InfiniteScroll from "react-infinite-scroll-component";

import {
  EmploydSearchInput,
  Icon,
  Text,
} from "../../../../../../../components/atoms";
import {
  EmployerJobCard,
  EmployerJobDetailCard,
} from "../../../../../../../components/molecules";
import { colors, spacing } from "../../../../../../../styles";
import { MapViewConstants } from "../../Constants";
import Filter from "./Filter";
import { images } from "../../../../../../../assets/images";
import RatingsModal from "components/molecules/Ratings";

import LoginSelector from "containers/Login/selectors/LoginSelector";

const MapView = ({
  toggleExploreView,
  employerListing,
  filterSettings,
  onClickSearch,
  onClickJob,
  searchTerm,
  onChangeSearchTerm,
  onClickPostNewJob,
  handleFilter,
  setSearchValue,
  postedJobsSuggestions,
  fetchPostedJobsSuggestions,
  showRatingModal,
  setShowRatingModal,
}) => {

  const isRatingDone = useSelector(LoginSelector.getUserRatingStatus);

  useEffect(() => {
    if (!isRatingDone) {
      setShowRatingModal(true);
    }
  }, []);
  const renderJobs = (item, index) => {
    return (
      <EmployerJobCard
        key={index}
        item={item}
        onClickJob={onClickJob}
        cardStyle={{
          marginBottom: spacing.xl,
          marginRight: spacing.s,
        }}
      />
    );
  };

  //#region - Debounce Search
  const initialRender = useRef(true);

  useEffect(() => {
    if (!initialRender.current) {
      onSearch(searchTerm);
    }
    initialRender.current = false;
  }, [searchTerm]);

  const onSearch = useCallback(
    debounce((text) => {
      onClickSearch(text)();
    }, 1500),
    []
  );
  //#endregion

  return (
    <Container sx={{ my: spacing.xxl }}>
      <Grid container columnSpacing={spacing.m}>
        <Grid item md={3.5}>
          <Card
            sx={{
              height: "86vh",
              py: spacing.l,
              pl: spacing.s,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Row>
              <EmploydSearchInput
                name="searchTerm"
                placeholder={MapViewConstants.SEARCH_INPUT_PLACEHOLDER}
                onChangeSearchTerm={onChangeSearchTerm}
                fetchSuggestions={fetchPostedJobsSuggestions}
                setSearchValue={setSearchValue}
                suggesstions={postedJobsSuggestions}
                suggestionValue={searchTerm}
                inputSx={{
                  height: 44,
                }}
              />
              <Filter
                handleFilter={handleFilter}
                filterSettings={filterSettings}
              />
            </Row>
            <JobsContainer id="scrollableDiv">
              {employerListing?.length > 0 ? (
                <InfiniteScroll
                  dataLength={employerListing?.length}
                  scrollableTarget="scrollableDiv"
                >
                  {employerListing?.map(renderJobs)}
                </InfiniteScroll>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  flex={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={images.empty.search}
                    style={{ width: 100, height: 100 }}
                    alt="search"
                  />
                  <Text size="l" style={{ marginTop: spacing.s }}>
                    No results found for
                  </Text>
                  <Text size="l">this search</Text>
                </Box>
              )}
            </JobsContainer>
          </Card>
        </Grid>
        <Grid
          item
          md={8.5}
          sx={{ display: "flex", flexDirection: "column", height: "86vh" }}
        >
          <IconContainer>
            <Icon
              name="arrow-back"
              size={42}
              style={{ cursor: "pointer" }}
              onClick={toggleExploreView}
            />
            <Button
              variant="contained"
              startIcon={<Icon size={30} name="plus" color={colors.white} />}
              onClick={onClickPostNewJob}
              sx={{ width: "auto" }}
            >
              {MapViewConstants.POST_NEW_JOB}
            </Button>
          </IconContainer>
          <Card
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
            }}
          >
            <EmployerJobDetailCard />
          </Card>
        </Grid>
      </Grid>
      <RatingsModal
        isOpen={showRatingModal}
        setShowRatingModal={setShowRatingModal}
      />
    </Container>
  );
};

export default MapView;

const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: ${spacing.xl};
  padding-right: ${spacing.s};
`;

const IconContainer = styled("div")`
  display: flex;
  height: 3rem;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${spacing.l};
`;

const JobsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;
