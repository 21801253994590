import * as yup from "yup";
import { isValidPhoneNumber } from "../../../../utils/commonUtils";
import { JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES } from "./constants";

export const jobSeekerDetailsSchema = yup.object({
  photoURL: yup.string(),
  fullName: yup.string().required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_NAME_TEXT),
  email: yup
    .string()
    .email(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.EMAIL_INVALID)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_EMAIL_TEXT),
  phoneNumber: yup
    .string()
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_PHONE_TEXT)
    .test(
      "validate-phone",
      JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.PHONE_INVALID,
      async (value, context) => {
        if (value) {
          return await isValidPhoneNumber({
            countryCode: context.parent.countryCode,
            phoneNumber: value,
          });
        }
        return true;
      },
    ),
  password: yup
    .string()
    .min(6, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.PASSWORD_MIN)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_PASSWORD_TEXT),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.PASSWORDS_DO_NOT_MATCH)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_CONFIRM_PASSWORD_TEXT),
  age: yup
    .number()
    .min(14, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.AGE_MIN)
    .max(99, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.AGE_MAX)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_AGE_TEXT),
});

export const jobSeekerPreferenceSchema = yup.object({
  wageExpectation: yup
    .array()
    .min(1, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_WAGE_EXPECTATION_TEXT)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_WAGE_EXPECTATION_TEXT),
  workingHours: yup
    .array()
    .min(1, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_WORKING_HOURS_TEXT)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_WORKING_HOURS_TEXT),
  experience: yup
    .array()
    .min(1, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_EXPERIENCE_TEXT)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_EXPERIENCE_TEXT),
  daysPerWeek: yup
    .array()
    .min(1, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_WORKING_DAYS_TEXT)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_WORKING_DAYS_TEXT),
  timeSlot: yup
    .array()
    .min(1, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_TIME_SLOT_TEXT)
    .required(JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.REQUIRED_TIME_SLOT_TEXT),
});

export const jobSeekerInterestsSchema = yup.object({
  job_interests: yup
    .array()
    .min(3, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.JOB_INTERESTS_MIN)
    .max(6, JOBSEEKER_SIGNUP_UI_ERROR_MESSAGES.JOB_INTERESTS_MAX),
});
