import { Container, Divider, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";
import { NavLink } from "react-router-dom";
import { TERMS_CONDITION, PRIVACY_POLICY, BLOGS } from "../../../routes/routeConstants";

import { images } from "../../../assets/images";
import { Text } from "../../../components/atoms";
import { colors, spacing, styleUtils } from "../../../styles";
import { SOCIAL_LINKS } from "../../../appConstants";
import { FooterConstants } from "../Constants";
import DownloadApp from "./DownloadApp";

const Footer = () => {
  return (
    <div
      style={{
        backgroundColor: colors.primary,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Container sx={{ py: spacing.xxxl }}>
        <Grid container columnSpacing={spacing.xxxl}>
          <Grid item md={5}>
            <Text color={colors.white} size={30} weight="bold" style={{ lineHeight: 1.2 }}>
              {FooterConstants.EMPLOYD_TAG}
            </Text>
            <DownloadApp />
          </Grid>
          <Divider orientation="vertical" flexItem sx={{ borderColor: "#214093" }} />
          <Grid item md={4}>
            <NavLink to={BLOGS} style={{ textDecoration: "none" }}>
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.BLOGS}
              </Text>
            </NavLink>
            {/* TODO-replace all occurences of "care@employd.us" with it's constant value in a constant file */}
            <a href="mailto:care@employd.us" style={{ textDecoration: "none" }}>
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.CONTACT_US}
              </Text>
            </a>
            <NavLink to={PRIVACY_POLICY} style={{ textDecoration: "none" }}>
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.PRIVACY_POLICY}
              </Text>
            </NavLink>
            <NavLink to={TERMS_CONDITION} style={{ textDecoration: "none" }}>
              <Text
                weight="medium"
                size="l"
                color={colors.white}
                sx={{
                  marginBottom: spacing.xl,
                }}
              >
                {FooterConstants.TERMS_AND_CONDITION}
              </Text>
            </NavLink>
          </Grid>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ borderColor: "#214093", marginRight: spacing.xxxl }}
          />
          <Grid item md>
            <QRBox>
              <QRImage src={images.home.app.qr} alt="qr" />
              <Text
                weight="medium"
                size="xxs"
                style={{
                  textAlign: "center",
                  marginTop: spacing.s,
                }}
              >
                Scan with your phone
              </Text>
              <Text
                weight="medium"
                size="xxs"
                style={{
                  textAlign: "center",
                }}
              >
                camera to download the app
              </Text>
            </QRBox>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: "#214093", marginTop: spacing.xxl }} />
        <FooterLinksContainer>
          <a rel="noopener noreferrer" target="_blank" href={SOCIAL_LINKS.facebook}>
            <SocialImage src={images.social.facebook} alt="Facebook" />
          </a>
          <a rel="noopener noreferrer" target="_blank" href={SOCIAL_LINKS.twitter}>
            <SocialImage src={images.social.twitter} alt="Twitter" />
          </a>
          <a rel="noopener noreferrer" target="_blank" href={SOCIAL_LINKS.instagram}>
            <SocialImage src={images.social.instagram} alt="Instagram" />
          </a>
          <a rel="noopener noreferrer" target="_blank" href={SOCIAL_LINKS.linkedin}>
            <SocialImage src={images.social.linkedin} alt="LinkedIn" />
          </a>
          <a rel="noopener noreferrer" target="_blank" href={SOCIAL_LINKS.tiktok}>
            <SocialImage src={images.social.tiktok} alt="TikTok" />
          </a>
          <Text size="s" color={colors.white} sx={{ marginLeft: "auto" }}>
            {FooterConstants.COPYRIGHT_RIGHTS_RESERVED}
          </Text>
        </FooterLinksContainer>
      </Container>
    </div>
  );
};

export default Footer;

const SocialImage = styled("img")`
  width: ${styleUtils.pxToRem("30px")};
  height: ${styleUtils.pxToRem("30px")};
`;
const QRImage = styled("img")`
  width: ${styleUtils.pxToRem("109px")};
  height: ${styleUtils.pxToRem("109px")};
`;
const FooterLinksContainer = styled("div")`
  align-items: center;
  display: flex;
  gap: ${styleUtils.pxToRem("26px")};
  justify-content: space-between;
  margin-top: ${spacing.l};
  margin-bottom: ${spacing.l};
`;
const QRBox = styled("div")`
  align-items: center;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 8px #0000001c;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: ${styleUtils.pxToRem("204px")};
  margin-left: ${spacing.l};
  padding: ${spacing.s} 0;
  width: ${styleUtils.pxToRem("169px")};
`;
