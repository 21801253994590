import { BottomNavigation } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import LoginSelector from "../../Login/selectors/LoginSelector";
import { EMPLOYER } from "../../../appConstants";
import { Text } from "../../../components/atoms";
import { colors } from "../../../styles";
import Indicator from "./Indicator";

import HomeIcon from "@mui/icons-material/Home";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import WorkIcon from "@mui/icons-material/Work";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";

import PeopleIcon from "@mui/icons-material/People";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";

import PersonIcon from "@mui/icons-material/Person";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";

import {
  JOBSEEKER_PAGE_ROUTE,
  JOBSEEKER_PROFILE_PAGE_ROUTE,
  JOBSEEKER_FAVOURITE_PAGE_ROUTE,
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  EMPLOYER_PROFILE_PAGE_ROUTE,
  PEOPLE_PAGE_ROUTE,
} from "../../../routes/routeConstants";

const BottomTab = () => {
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const isEmployer =
    (loggedInUser && loggedInUser?.userType === EMPLOYER) || false;

  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.white,
        zIndex: 999,
      }}
    >
      <BottomNavigation>
        <StyledNavLink
          to={
            isEmployer
              ? `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`
              : ""
          }
        >
          {({ isActive }) => (
            <>
              {isActive && <Indicator />}
              <Item>
                {isEmployer ? (
                  <>
                    {isActive ? (
                      <WorkIcon htmlColor={colors.primary} />
                    ) : (
                      <WorkOutlineIcon color="primary" />
                    )}
                  </>
                ) : (
                  <>
                    {isActive ? (
                      <HomeIcon htmlColor={colors.primary} />
                    ) : (
                      <HomeOutlinedIcon color="primary" />
                    )}
                  </>
                )}
                <Text
                  size="s"
                  color={isActive ? colors.primary : colors.placeholder}
                >
                  {isEmployer ? "Jobs" : "Home"}
                </Text>
              </Item>
            </>
          )}
        </StyledNavLink>

        <StyledNavLink
          to={
            isEmployer
              ? `${EMPLOYER_PAGE_ROUTE}/${PEOPLE_PAGE_ROUTE}`
              : `${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_FAVOURITE_PAGE_ROUTE}`
          }
        >
          {({ isActive }) => (
            <>
              {isActive && <Indicator />}
              <Item>
                {isEmployer ? (
                  <>
                    {isActive ? (
                      <PeopleIcon htmlColor={colors.primary} />
                    ) : (
                      <PeopleOutlinedIcon color="primary" />
                    )}
                  </>
                ) : (
                  <>
                    {isActive ? (
                      <WorkIcon htmlColor={colors.primary} />
                    ) : (
                      <WorkOutlineIcon color="primary" />
                    )}
                  </>
                )}
                <Text
                  size="s"
                  color={isActive ? colors.primary : colors.placeholder}
                >
                  {isEmployer ? "Job Seekers" : "Applications"}
                </Text>
              </Item>
            </>
          )}
        </StyledNavLink>
        <StyledNavLink to={"chat"}>
          {({ isActive }) => (
            <>
              {isActive && <Indicator />}
              <Item>
                <>
                  {isActive ? (
                    <ChatBubbleIcon htmlColor={colors.primary} />
                  ) : (
                    <ChatBubbleOutlineIcon color="primary" />
                  )}
                </>

                <Text
                  size="s"
                  color={isActive ? colors.primary : colors.placeholder}
                >
                  Chat
                </Text>
              </Item>
            </>
          )}
        </StyledNavLink>

        <StyledNavLink
          to={
            isEmployer
              ? `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_PROFILE_PAGE_ROUTE}`
              : `${JOBSEEKER_PAGE_ROUTE}/${JOBSEEKER_PROFILE_PAGE_ROUTE}`
          }
        >
          {({ isActive }) => (
            <>
              {isActive && <Indicator />}
              <Item>
                <>
                  {isActive ? (
                    <PersonIcon htmlColor={colors.primary} />
                  ) : (
                    <PersonOutlineIcon color="primary" />
                  )}
                </>
                <Text
                  size="s"
                  color={isActive ? colors.primary : colors.placeholder}
                >
                  Account
                </Text>
              </Item>
            </>
          )}
        </StyledNavLink>
      </BottomNavigation>
    </Box>
  );
};

export default BottomTab;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Item = styled("div")`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
