import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Avatar, Box, Button, Card, Divider, Grid, Link } from "@mui/material";
import { styled } from "@mui/material/styles";
import { usePlacesWidget } from "react-google-autocomplete";

import { logEvent } from "../../../configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import JobSeekerSelector from "../../../containers/JobSeeker/selectors/JobSeekerSelector";
import LoginSelector from "../../../containers/Login/selectors/LoginSelector";
import useIsMobile from "../../../hooks/useIsMobile";

import { getBrandJobsApi, getYelpBusinessReview } from "../../../configs/network/apis";

import JobCard from "../JobCard";

import {
  showLoader,
  hideLoader,
  setLoginRedirection,
  showNotification,
} from "../../../utils/commonUtils";
import { renderImage } from "../../../utils/imageUtils";

import { EMPLOYER } from "../../../appConstants";
import { images } from "../../../assets/images";

import { Icon, LocationsDropDown, Text } from "../../atoms";
import { colors, spacing, styleUtils } from "../../../styles";
import { CHAT_PAGE_ROUTE } from "routes/routeConstants";

const fieldsToShow = [
  "formatted_address",
  "geometry",
  "name",
  "address_components",
  "business_status",
  "icon_mask_base_uri",
  "icon_background_color",
  "place_id",
  "plus_code",
  "editorial_summary",
  "type",
  "url",
  "utc_offset_minutes",
  "vicinity",
  "wheelchair_accessible_entrance",
  "formatted_phone_number",
  "photos",
  "reviews",
  "rating",
  "icon",
  "opening_hours",
  "website",
  "international_phone_number",
  "current_opening_hours",
  "secondary_opening_hours",
  "curbside_pickup",
  "delivery",
  "dine_in",
  "editorial_summary",
  "price_level",
  "reservable",
  "user_ratings_total",
];

/**
 * Component displaying employer company details
 */
const CompanyDetailCard = ({ onClickBrandLogo, navigateToCompanyLoc, onClickJob }) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const [searchParams, setSearchParams] = useSearchParams();
  const jobId = searchParams.get("jobId");
  const recruiterId = searchParams.get("recruiterId");
  const navMap = searchParams.get("navMap");

  const brandName = searchParams.get("brandName");
  const forceUpdate = useSelector(JobSeekerSelector.getForceUpdateJobSeekerListing);

  const [item, setItem] = useState(null);
  const [jobsData, setJobsData] = useState(null);
  const [locations, setLocations] = useState(null);
  const employerState = {
    photoURL: item?.photoURL,
    companyName: item?.companyName,
    designation: item?.designation,
    fullName: item?.fullName,
  };

  const searchState = `?uid=${recruiterId}&uType=${EMPLOYER}`;

  useEffect(() => {
    init();
  }, [jobId, brandName, forceUpdate]);

  const init = async () => {
    try {
      showLoader();
      const res = await getBrandJobsApi({
        employerUid: recruiterId,
        status: ["active"],
      });

      setItem(res?.data?.empDetails || "Not found");
      setJobsData(res?.data?.data || "Not found");
      setLocations(res?.data?.locationDetails || []);
      hideLoader();
    } catch (e) {
      setItem("Not found");
      hideLoader();
    }
  };

  const onClickChat = (e) => {
    e.stopPropagation();
    logEvent(FirebaseEvents.JS_JOB_DETAILS_CARD_CHAT_NOW_CLICK, {
      Is_SignedIn: loggedInUser?.uid ? "Signed_In" : "Non_signed_in",
    });

    navigate(
      {
        pathname: CHAT_PAGE_ROUTE,
        search: searchState,
      },
      {
        state: employerState,
      },
    );
  };

  const handleJobCardSelect = (job) => {
    if (navMap) {
      setLoginRedirection(
        loggedInUser?.uid,
        "",
        `?showJobDetailView=true&jobId=${job.jobId}&brandName=${job.brandName}&navMap=true`,
      );
      setSearchParams({
        showJobDetailView: true,
        jobId: job.jobId,
        brandName: job.brandName,
        navMap: true,
      });
    } else {
      onClickJob(job);
    }
  };

  const renderJobs = (item, index) => {
    const isFavourite = item?.jobSeekers.includes(loggedInUser?.uid);
    return (
      <Grid item md={3} key={index}>
        <JobCard
          key={index}
          cardStyle={{
            marginBottom: spacing.s,
          }}
          item={item}
          showChat={false}
          isFavourite={isFavourite}
          onClickJob={handleJobCardSelect}
          onClickBrandLogo={onClickBrandLogo}
        />
      </Grid>
    );
  };

  const handleLocNavigation = (selectedCity) => {
    navigateToCompanyLoc(locations.filter((loc) => loc?.city === selectedCity)[0]);
  };

  return (
    <>
      {import.meta.env.VITE_APP_ENVIRONMENT === "DEVELOPMENT" && <EmployerReviewCard />}
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: styleUtils.pxToRem("774px"),
          width: styleUtils.pxToRem("1180px"),
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: spacing.xs,
            marginRight: spacing.xs,
          }}
        >
          <Text
            size="m"
            weight={isMobile ? "semibold" : "medium"}
            color={isMobile ? colors.textPrimary : colors.labelBlack}
            style={{
              marginLeft: isMobile ? spacing.xs : spacing.s,
              marginBottom: spacing.s,
              marginTop: spacing.l,
            }}
          >
            About Company
          </Text>

          {!isMobile && (
            <>
              <TopContainer container direction="row">
                <ColumnGrid item xs={1.5}>
                  <Avatar
                    variant="circular"
                    src={renderImage(item?.photoURL, "job")}
                    sx={{ width: 91, height: 91, marginBottom: spacing.s }}
                  >
                    <img
                      alt={item?.companyName}
                      src={images.defaults.job}
                      style={{ width: 100, height: 100 }}
                    />
                  </Avatar>
                  <Text size="m" weight="semibold" color={colors.blackText}>
                    {brandName}
                  </Text>
                </ColumnGrid>
                <Divider orientation="vertical" flexItem sx={{ mx: spacing.l }} />
                <ColumnGrid item xs={3}>
                  <Text size="s" color={colors.descriptionGray}>
                    Company Website
                  </Text>
                  <WebsiteDetailsContainer>
                    <Text
                      size="l"
                      weight="semibold"
                      color={item?.website ? colors.midnightBlue : colors.darkGray}
                      sx={{
                        fontStyle: item?.website ? "normal" : "italic",
                        textDecoration: item?.website ? "underline" : "none",
                        textUnderlineOffset: "4px",
                        lineHeight: 1.2,
                      }}
                    >
                      {item?.website ? (
                        <Link rel="noopener noreferrer" href={item?.website} target="_blank">
                          {item?.website}
                        </Link>
                      ) : (
                        "Not Available"
                      )}
                    </Text>
                    {item?.website && (
                      <Icon name="website-link" size={24} style={{ marginLeft: spacing.xs }} />
                    )}
                  </WebsiteDetailsContainer>
                </ColumnGrid>
                <Divider orientation="vertical" flexItem sx={{ mx: spacing.s }} />
                <ColumnGrid item xs={3}>
                  <Text size="s" color={colors.descriptionGray}>
                    Job Location
                  </Text>
                  <LocationsDropDown
                    dropDownOptions={locations?.map((cities) => cities?.city)}
                    handleLocNavigation={handleLocNavigation}
                  />
                </ColumnGrid>
                <Divider orientation="vertical" flexItem sx={{ mx: spacing.s }} />
                <ColumnGrid item xs={3} sx={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ padding: spacing.xs, marginRight: spacing.xs }}>
                    <Avatar
                      variant="circular"
                      sx={{ width: 51, height: 51 }}
                      src={renderImage(item?.photoURL, "job")}
                    >
                      <img
                        alt={item?.companyName}
                        src={images.defaults.job}
                        style={{ width: 100, height: 100 }}
                      />
                    </Avatar>
                  </div>
                  <div>
                    <Text size="s" color={colors.descriptionGray}>
                      Hiring Manager
                    </Text>
                    <Text size="l" weight="semibold" color={colors.blackText}>
                      {item?.fullName}
                    </Text>
                    <Button
                      variant="outlined"
                      sx={{
                        height: styleUtils.pxToRem("36px"),
                        width: styleUtils.pxToRem("108px"),
                        marginTop: spacing.s,
                        borderRadius: "19px",
                      }}
                      onClick={onClickChat}
                    >
                      Chat Now
                    </Button>
                  </div>
                </ColumnGrid>
              </TopContainer>
              <Divider orientation="horizontal" flexItem sx={{ mx: spacing.s }} />
              <Text
                size="m"
                weight="medium"
                color={colors.labelBlack}
                style={{
                  marginLeft: spacing.s,
                  marginBottom: spacing.s,
                  marginTop: spacing.xl,
                }}
              >
                Current Jobs at {brandName}
              </Text>
              {jobsData && jobsData?.length > 0 && (
                <Grid
                  container
                  columnSpacing={spacing.l}
                  sx={{
                    overflow: "scroll",
                    display: "flex",
                    flex: 1,
                    paddingLeft: spacing.s,
                    paddingRight: spacing.l,
                    marginBottom: spacing.l,
                  }}
                >
                  {jobsData?.map(renderJobs)}
                </Grid>
              )}
            </>
          )}
          {isMobile && (
            <div>
              <ImageDetailsContainer>
                <Avatar
                  variant="circular"
                  sx={{
                    width: 74,
                    height: 74,
                  }}
                  src={renderImage(item?.photoURL, "job")}
                >
                  <img
                    alt={item?.companyName}
                    src={images.defaults.job}
                    style={{ width: 50, height: 50 }}
                  />
                </Avatar>
                <Text size="m" weight="medium" style={{ marginTop: spacing.s }}>
                  {brandName}
                </Text>
              </ImageDetailsContainer>
              <Divider sx={{ my: spacing.s }} />
              <Text
                size="m"
                weight="medium"
                color={colors.descriptionGray}
                style={{ marginLeft: spacing.xs }}
              >
                Company Website
              </Text>
              <WebsiteDetailsContainer sx={{ marginBottom: spacing.xxl, marginLeft: spacing.xs }}>
                <Text
                  size="l"
                  weight="medium"
                  color={item?.website ? colors.midnightBlue : colors.darkGray}
                  sx={{
                    textDecoration: item?.website ? "underline" : "none",
                    textUnderlineOffset: "4px",
                    lineHeight: 1.2,
                    marginBottom: spacing.m,
                    fontStyle: item?.website ? "normal" : "italic",
                  }}
                >
                  <Link rel="noopener noreferrer" href={item?.website} target="_blank">
                    {item?.website}
                  </Link>
                </Text>
                {item?.website && (
                  <Icon name="website-link" size={24} style={{ marginLeft: spacing.xs }} />
                )}
              </WebsiteDetailsContainer>
              <LocationsMobileContainer>
                <Text size="s" color={colors.descriptionGray}>
                  Job Location
                </Text>
                <LocationsDropDown
                  dropDownOptions={locations?.map((cities) => cities?.city)}
                  handleLocNavigation={handleLocNavigation}
                />
              </LocationsMobileContainer>
              <EmployerInfoMobileContainer>
                <div style={{ padding: spacing.xs, marginRight: spacing.xxs }}>
                  <Avatar
                    variant="circular"
                    sx={{ width: 51, height: 51 }}
                    src={renderImage(item?.photoURL, "job")}
                  >
                    <img
                      alt={item?.companyName}
                      src={images.defaults.job}
                      style={{ width: 100, height: 100 }}
                    />
                  </Avatar>
                </div>
                <div>
                  <Text size="s" color={colors.descriptionGray}>
                    Hiring Manager
                  </Text>
                  <Text size="l" weight="semibold" color={colors.blackText}>
                    {item?.fullName}
                  </Text>
                  <Button
                    variant="outlined"
                    sx={{
                      height: styleUtils.pxToRem("38px"),
                      width: styleUtils.pxToRem("119px"),
                      marginTop: spacing.s,
                      borderRadius: "19px",
                    }}
                    onClick={onClickChat}
                  >
                    Chat Now
                  </Button>
                </div>
              </EmployerInfoMobileContainer>
              <Divider
                sx={{
                  mb: spacing.m,
                }}
              />
              <Text
                size="m"
                weight="medium"
                color={colors.labelBlack}
                style={{ marginBottom: spacing.s, marginLeft: spacing.xs }}
              >
                Current Jobs at {item?.companyName}
              </Text>
              <JobsMobileContainer>
                {jobsData && jobsData?.length > 0 && <>{jobsData.map(renderJobs)}</>}
              </JobsMobileContainer>
            </div>
          )}
        </Box>
      </Card>
    </>
  );
};

//TO-DO:  Need to optimize it once it is ready for PRODUCTION
const EmployerReviewCard = () => {
  const [googleReviewDetails, setGoogleReviewDetails] = useState({});
  const [yelpReviewDetails, setYelpReviewDetails] = useState({});
  const [isShowGoogleReview, setIsShowGoogleReview] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [businessURL, setBusinessURL] = useState("");

  const {
    geometry,
    formatted_address,
    icon,
    icon_mask_base_uri,
    icon_background_color,
    current_opening_hours,
    formatted_phone_number,
    international_phone_number,
    editorial_summary,
    name,
    place_id,
    price_level,
    photos,
    rating,
    reviews,
    types,
    url,
    utc_offset_minutes,
    vicinity,
    user_ratings_total,
    website,
  } = googleReviewDetails;

  const { business, reviews: yelpReviews } = yelpReviewDetails;
  const { ref } = usePlacesWidget({
    apiKey: import.meta.env.VITE_APP_GOOGLE_PLACES_API_KEY,
    onPlaceSelected: (place) => {
      setGoogleReviewDetails(place);
    },
    options: {
      types: ["establishment"],
      fields: fieldsToShow,
      componentRestrictions: { country: ["in", "us"] },
    },
  });

  const handlePhotoFocus = (photo) => {
    setSelectedPhoto(photo);
  };
  const handleYelpBusinessReview = async (businessURL) => {
    if (businessURL === "") {
      showNotification({
        alertProps: {
          severity: "info",
          children: "Add business url to get yelp review",
        },
      });
      return;
    }
    const businessAlias = businessURL.split("/").pop();

    showLoader();
    try {
      const res = await getYelpBusinessReview(businessAlias);
      setYelpReviewDetails(res?.data);
    } catch (err) {
      console.error(err);
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong. Please try again",
        },
      });
    } finally {
      hideLoader();
    }
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: styleUtils.pxToRem("20px"),
        maxWidth: "80%",
      }}
    >
      <div style={{ flex: "1" }}>
        <div style={{ display: "flex", gap: "20px" }}>
          <Button
            variant={isShowGoogleReview ? "contained" : "outlined"}
            sx={{ width: "fit-content", marginBottom: "20px" }}
            onClick={() => setIsShowGoogleReview(true)}
          >
            {"Show Google Review"}
          </Button>
          <Button
            variant={isShowGoogleReview ? "outlined" : "contained"}
            sx={{ width: "fit-content", marginBottom: "20px" }}
            onClick={() => setIsShowGoogleReview(false)}
          >
            {"Show Yelp Review"}
          </Button>
        </div>
        <SearchInputForBusiness
          ref={ref}
          isShowGoogleReview={isShowGoogleReview}
          placeholder="Enter your company name"
        />
        {!isShowGoogleReview && (
          <FlexWrapper style={{ width: "50%", gap: "20px" }}>
            <input
              placeholder="Enter your business url"
              style={{
                height: "20px",
                width: "400px",
                padding: "20px",
                outline: "none",
                border: `1px solid ${colors.royalBlue}`,
                borderRadius: "10px",
              }}
              value={businessURL}
              onChange={(e) => setBusinessURL(e.target.value)}
            />
            <Button
              sx={{ width: "max-content", padding: "10px" }}
              onClick={() => handleYelpBusinessReview(businessURL)}
            >
              {"Submit"}
            </Button>
          </FlexWrapper>
        )}
        {isShowGoogleReview && Object.entries(googleReviewDetails)?.length > 0 && (
          <Card sx={{ height: "400px", maxWidth: "60%", overflow: "auto", padding: "14px" }}>
            <Row>
              <Text weight="bold" size="l">
                {"Address"}
              </Text>
              <Text>{formatted_address}</Text>
            </Row>
            {/* Company Image,Name and Rating */}
            <CompanyDetailsWrapper>
              <img style={{ width: "50px", height: "50px" }} src={icon} alt="business logo" />
              <div>
                <Text weight="bold" size="m">
                  {name}
                </Text>
                <div style={{ display: "flex", float: "right", gap: "5px" }}>
                  <Text weight="semibold" size="m" color={colors.descriptionGray}>
                    {rating}
                  </Text>
                  <Icon name="star" sx={{ border: "1px solid" }} />
                </div>
              </div>
            </CompanyDetailsWrapper>
            {/* vicinity */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Vicinity"}
              </Text>
              <Text>{vicinity}</Text>
            </DataWrapper>
            {/* Reviews */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Reviews"}
              </Text>
              {reviews?.map((review) => {
                return (
                  <DataWrapper key={review?.author_name}>
                    <FlexWrapper>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={review.profile_photo_url}
                          alt="user profile"
                        />
                        <Text weight="semibold" size="m" sx={{ borderBottom: "1px solid" }}>
                          {review?.author_name}
                        </Text>
                      </div>

                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <Text weight="semibold" size="l" color={colors.descriptionGray}>
                          {review.rating}
                        </Text>
                        <Icon name="star" width={12} sx={{ border: "1px solid" }} />
                      </div>
                    </FlexWrapper>
                    <Text>{review.text}</Text>
                  </DataWrapper>
                );
              })}
            </DataWrapper>
            {/* Types */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Types"}
              </Text>
              {types?.map((type) => (
                <Text key={type}>{type}</Text>
              ))}
            </DataWrapper>
            {/* Navigation url */}
            <DataWrapper style={{ cursor: "pointer" }}>
              <Text weight="bold" size="l">
                {"Navigation url"}
              </Text>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {url}
              </a>
            </DataWrapper>
            {/* userratigs total */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"User ratings total"}
              </Text>
              <Text>{user_ratings_total}</Text>
            </DataWrapper>
            {/* website */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Website"}
              </Text>
              <a href={website} target="_blank" rel="noopener noreferrer">
                {website}
              </a>
            </DataWrapper>
            {/* geometry */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Geometry"}
              </Text>
              <Text>{JSON.stringify(geometry)}</Text>
            </DataWrapper>
            {/* formatted phone number */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Formatted phone number"}
              </Text>
              <Text>{formatted_phone_number}</Text>
            </DataWrapper>
            {/* international_phone_number */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"International phone number"}
              </Text>
              <Text>{international_phone_number}</Text>
            </DataWrapper>
            {/* place_id */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Place id"}
              </Text>
              <Text>{place_id}</Text>
            </DataWrapper>
            {/* price_level */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Price level"}
              </Text>
              <Text>{price_level}</Text>
            </DataWrapper>
            {/* icon background color */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Icon background color"}
              </Text>
              <Text sx={{ backgroundColor: icon_background_color, width: "fit-content" }}>
                {icon_background_color}
              </Text>
            </DataWrapper>
            {/* icon mask base uri */}
            <DataWrapper>
              <a href={icon_mask_base_uri} target="_blank" rel="noopener noreferrer">
                {"Icon mask base uri"}
              </a>
            </DataWrapper>
            {/* Photos */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Photos"}
              </Text>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {photos?.map((photo) => (
                  <img
                    src={photo.getUrl()}
                    key={photo.getUrl()}
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                      transform: selectedPhoto === photo ? "scale(1.5)" : "scale(1)",
                      transition: "all 0.3s ease-in-out",
                      zIndex: selectedPhoto === photo ? "999" : "0",
                    }}
                    onMouseEnter={() => handlePhotoFocus(photo)}
                    onMouseLeave={() => handlePhotoFocus(null)}
                    alt="user profile"
                  />
                ))}
              </div>
            </DataWrapper>
            {/* Editorial summary destructure language and overview */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Overview"}
              </Text>
              <Text>
                {editorial_summary?.overview
                  ? editorial_summary?.overview
                  : "NO OVERVIEW AVAILABLE"}
              </Text>
            </DataWrapper>

            {/* Current Opening Hours */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Current Opening Hours"}
              </Text>
              <Text>Open Now: {current_opening_hours?.open_now ? "True" : "False"}</Text>
              <div style={{ padding: "0 10px" }}>
                <Text weight="bold" size="m">
                  {"Periods:"}
                </Text>
                <ul>
                  {current_opening_hours?.periods?.map((period, index) => (
                    <li key={period}>
                      <Text>{JSON.stringify(period)}</Text>
                    </li>
                  ))}
                </ul>
              </div>
              <div style={{ padding: "0 10px" }}>
                <Text weight="bold" size="m">
                  {"Weekday Text:"}
                </Text>
                <ul>
                  {current_opening_hours?.weekday_text?.map((text, index) => {
                    return (
                      <li key={index}>
                        <Text>{text}</Text>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </DataWrapper>
            {/* utc_offset_minutes */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Utc offset minutes"}
              </Text>
              <Text>{utc_offset_minutes}</Text>
            </DataWrapper>
          </Card>
        )}
        {!isShowGoogleReview && Object.entries(yelpReviewDetails)?.length > 0 && (
          <Card
            sx={{
              height: "400px",
              maxWidth: "60%",
              overflow: "auto",
              padding: "14px",
              marginTop: "20px",
            }}
          >
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Yelp company name"}
              </Text>
              <Text>{business?.name}</Text>
            </DataWrapper>
            {/* alias */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Alias"}
              </Text>
              <Text>{business?.alias}</Text>
            </DataWrapper>
            {/* is_closed */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Is Closed"}
              </Text>
              <Text>{business?.is_closed ? "True" : "False"}</Text>
            </DataWrapper>
            {/* yelpUrl */}
            <DataWrapper>
              <a href={business?.url} target="_blank" rel="noopener noreferrer">
                {"Yelp url"}
              </a>
            </DataWrapper>
            {/* yelp rating */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Yelp Rating"}
              </Text>
              <Text>{business?.rating}</Text>
            </DataWrapper>
            {/* categories */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Categories"}
              </Text>
              {business?.categories.map((category, index) => (
                <span key={category.title}>
                  {category.title}
                  {index < business?.categories.length - 1 ? ", " : ""}
                </span>
              ))}
            </DataWrapper>
            {/* location */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Location"}
              </Text>
              <div style={{ padding: "0 10px" }}>
                <ul>
                  {Object.entries(business?.location).map(([key, value]) => {
                    const valueIsArray = Array.isArray(value) ? value.length > 0 : true;
                    return (
                      <li key={key}>
                        {value && valueIsArray ? `${key}: ${value}` : `${key}: N/A`}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </DataWrapper>
            {/* yelpReview */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Reviews"}
              </Text>
              {yelpReviews?.reviews?.map((review) => {
                const { url, rating, user, text } = review;
                return (
                  <DataWrapper key={url}>
                    <FlexWrapper>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          style={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={user?.image_url}
                          alt="user profile"
                        />
                        <Text weight="semibold" size="m" sx={{ borderBottom: "1px solid" }}>
                          {user?.name}
                        </Text>
                      </div>

                      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                        <Text weight="semibold" size="l" color={colors.descriptionGray}>
                          {rating}
                        </Text>
                        <Icon name="star" width={12} sx={{ border: "1px solid" }} />
                      </div>
                    </FlexWrapper>
                    <Text>{text}</Text>
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: "none", borderBottom: "1px solid blue" }}
                    >
                      {"Url"}
                    </a>
                  </DataWrapper>
                );
              })}
            </DataWrapper>
            {/* Yelp photo */}
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Photos"}
              </Text>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                {business?.photos?.map((photo, index) => (
                  <img
                    src={photo}
                    key={photo}
                    alt="business photo"
                    style={{
                      width: "100px",
                      height: "100px",
                      cursor: "pointer",
                      transform: selectedPhoto === photo ? "scale(1.5)" : "scale(1)",
                      transition: "all 0.3s ease-in-out",
                      zIndex: selectedPhoto === photo ? "999" : "0",
                    }}
                    onMouseEnter={() => handlePhotoFocus(photo)}
                    onMouseLeave={() => handlePhotoFocus(null)}
                  />
                ))}
              </div>
            </DataWrapper>
            <DataWrapper>
              <Text weight="bold" size="l">
                {"Total Review"}
              </Text>
              <Text>{yelpReviews?.total}</Text>
            </DataWrapper>
          </Card>
        )}
      </div>
    </div>
  );
};

CompanyDetailCard.propTypes = {
  onClickBrandLogo: PropTypes.func.isRequired,
  navigateToCompanyLoc: PropTypes.func.isRequired,
  onClickJob: PropTypes.func.isRequired,
};

export default CompanyDetailCard;

const TopContainer = styled(Grid)`
  padding: ${spacing.s};
  margin-bottom: ${spacing.s};
`;
const ColumnGrid = styled(Grid)`
  padding: ${spacing.xxxs};
  margin-left: ${spacing.xxxs};
`;
const WebsiteDetailsContainer = styled("div")`
  display: inline-flex;
  margin-top: ${spacing.xs};
`;
const LocationsMobileContainer = styled("div")`
  margin-bottom: ${spacing.xxl};
  margin-left: ${spacing.xs};
`;
const EmployerInfoMobileContainer = styled("div")`
  display: flex;
  flex-direction: row;
  margin-bottom: ${spacing.l};
  margin-left: ${spacing.xs};
`;
const ImageDetailsContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const JobsMobileContainer = styled("div")`
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin-left: ${spacing.xs};
`;

const Row = styled("div")`
  padding: ${spacing.xs};
`;

const SearchInputForBusiness = styled("input")`
  width: 50%;
  height: 0px;
  padding: 20px;
  border: none;
  outline: none;
  border: 1px solid #2b4894;
  border-radius: 10px;
  display: ${({ isShowGoogleReview }) => (isShowGoogleReview ? "block" : "none")};
  margin-bottom: 20px;
`;

const DataWrapper = styled("div")`
  margin-bottom: 10px;
`;

const CompanyDetailsWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

const FlexWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
