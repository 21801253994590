import useIsMobile from "../../../../hooks/useIsMobile";

import ApplicationsJobDetailsView from "./views/ApplicationsJobDetailsView";
import ApplicationsJobDetailsResView from "./mobileViews/ApplicationsJobDetailsResView";

const ApplicationsJobDetailsContainer = (props) => {
  const isMobile = useIsMobile();

  const Wrapper = isMobile
    ? ApplicationsJobDetailsResView
    : ApplicationsJobDetailsView;

  return <Wrapper {...props} />;
};

export default ApplicationsJobDetailsContainer;
