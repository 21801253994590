import { Avatar } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";

import { userUpdate } from "../../../containers/Login/actions/LoginActions";
import LoginSelector from "../../../containers/Login/selectors/LoginSelector";
import { getBase64 } from "../../../utils/commonUtils";
import { images } from "../../../assets/images";
import { renderImage } from "../../../utils/imageUtils";
import ImageUploadStatus from "../ImageUploadStatus";

const ProfileImage = ({ style, currentImageUrl, alt, imageType }) => {
  const dispatch = useDispatch();
  const [imageStatus, setImageStatus] = useState();
  const loggedInUser = useSelector(LoginSelector.getLoggedInUser);

  const onUploadPic = async (target) => {
    setImageStatus("loading");
    const encodedFile = await getBase64(target.files[0]);
    dispatch(
      userUpdate(
        {
          userId: loggedInUser?.uid,
          user: {
            photoURL: encodedFile,
          },
          successCallback: () => setImageStatus("success"),
          errorCallback: () => setImageStatus("error"),
        },
        loggedInUser?.userType
      )
    );
  };

  const handleCapture = ({ target }) => {
    if (target.files[0]) {
      onUploadPic(target);
    }
  };

  return (
    <>
      <label htmlFor="imgupload" style={{ position: "relative" }}>
        <Avatar
          alt={alt}
          src={renderImage(currentImageUrl, imageType)}
          sx={style}
        >
          <img alt={alt} src={images.defaults[imageType]} style={style} />
        </Avatar>
        <EditImage
          src={images.profile.edit}
          alt="Edit"
          style={{ cursor: "pointer" }}
        />
      </label>
      <input
        id="imgupload"
        type="file"
        hidden
        accept=".jpg, .jpeg, .png, .webp"
        onChange={handleCapture}
        disabled={imageStatus === "loading"}
      />
      <ImageUploadStatus
        status={imageStatus}
        style={{
          marginTop: "20px",
        }}
      />
    </>
  );
};

export default ProfileImage;

const EditImage = styled("img")`
  position: absolute;
  width: 40px;
  height: 40px;
  bottom: -10px;
  right: 0px;
`;
