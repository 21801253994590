import { Badge } from "@mui/material";
import { Icon } from "../../components/atoms";
import { spacing } from "../../styles";

export const FooterConstants = {
  ABOUT: "About",
  ABOUT_US: "About us",
  BLOGS: "Blogs",
  CONTACT_US: "Contact Us",
  DOWNLOAD_SUBTEXT: "Download the EMPLOYD app today from",
  EMPLOYD_TAG: "EMPLOYD",
  EXPLORE_JOBS: "Explore Jobs",
  HIRING_SUBTEXT: "Hiring and local part-time job search made simple",
  PRIVACY_POLICY: "Privacy Policy",
  COPYRIGHT_RIGHTS_RESERVED: `Copyright © EmployD ${new Date().getFullYear()}, All Rights Reserved`,
  SEARCH_JOBS: "Search Jobs",
  TERMS_AND_CONDITION: "Terms and Conditions",
};

export const JobSeekerFooterData = {
  HOME: {
    label: "Home",
    icon: <Icon name="home" />,
  },
  CHAT: {
    label: "Chat",
    icon: (badgeCount) => (
      <Badge badgeContent={badgeCount} color="primary">
        <Icon name="comment-o" />
      </Badge>
    ),
  },
  FAVORITE: {
    label: "Applications",
    icon: <Icon name="application" />,
  },
  PROFILE: {
    label: "Profile",
    icon: <Icon name="user" />,
  },
};

export const EmployerFooterData = {
  JOBS: {
    label: "Jobs",
    icon: <Icon name="briefcase" style={{ marginBottom: spacing.xxs }} />,
  },
  PEOPLE: {
    label: "People",
    icon: <Icon name="home" style={{ marginBottom: spacing.xxs }} />,
  },
  PROFILE: {
    label: "Profile",
    icon: <Icon name="user" style={{ marginBottom: spacing.xxs }} />,
  },
  CHAT: {
    label: "Chat",
    icon: (badgeCount) => (
      <Badge badgeContent={badgeCount} color="primary">
        <Icon name="comment-o" style={{ marginBottom: spacing.xxs }} />
      </Badge>
    ),
  },
};
