import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

import LoginWebView from "./views/LoginWebView";
import LoginMobileView from "./mobileViews/LoginMobileView";

import * as loginActions from "./actions/LoginActions";
import { logEvent } from "configs/firebase";
import FirebaseEvents from "configs/firebase/FirebaseEvents";

import useIsMobile from "hooks/useIsMobile";
import { Icon, Text } from "components/atoms";
import {
  getBase64FromUrl,
  getRedirectValue,
  hideLoader,
  showLoader,
  showNotification,
} from "../../utils/commonUtils";
import { colors, spacing, styleUtils } from "styles";

import {
  EMPLOYER_PAGE_ROUTE,
  EMPLOYER_JOBS_PAGE_ROUTE,
  JOBSEEKER_PAGE_ROUTE,
  EXPLORE_JOBS_PAGE_ROUTE,
} from "../../routes/routeConstants";

import { EMPLOYER, JOB_SEEKER } from "../../appConstants";
import * as Loginconst from "./constants";
import { getUserInfoGoogleApi } from "configs/network/apis";

/**
 * LoginContainer component containing Login
 * handler functions
 */
const LoginContainer = (props) => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const { loginType: userType } = useParams();
  const { loginActionsProp } = props;

  const Wrapper = isMobile ? LoginMobileView : LoginWebView;

  useEffect(() => {
    const appContainer = document.getElementById("APP_CONTAINER");
    appContainer.style.backgroundColor = "#E3E7ED";
    return () => {
      appContainer.style.backgroundColor = "#fff";
    };
  }, []);

  const onLoginCallback = async (params) => {
    const [redirectValue] = [getRedirectValue()];

    const { pathname, search } = redirectValue;
    if (pathname) {
      navigate({
        pathname,
        search,
      });
    } else if (params.type === JOB_SEEKER) {
      search
        ? navigate({
            pathname: `${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`,
            search,
          })
        : navigate(`${JOBSEEKER_PAGE_ROUTE}/${EXPLORE_JOBS_PAGE_ROUTE}`);
    } else {
      search
        ? navigate({
            pathname: `${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`,
            search,
          })
        : navigate(`${EMPLOYER_PAGE_ROUTE}/${EMPLOYER_JOBS_PAGE_ROUTE}`);
    }
    showNotification({
      alertProps: {
        severity: "success",
        children: "You are logged in successfully",
      },
    });
  };

  const handleGoogleLogin = (params) => {
    try {
      showLoader();
      const { email, name, idToken, image, social_id } = params;
      loginActionsProp.socialLoginRequest({
        email,
        name,
        image,
        loginType: "GL",
        idToken,
        source: "WEB",
        type: userType,
        social_id,
        onCallback: socialLoginCallback,
      });
    } catch (error) {
      console.error(error);
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong. Please try again later.",
        },
      });
    } finally {
      hideLoader();
    }
  };

  const handleFacebookLogin = (params) => {
    try {
      showLoader();
      const { email, name, idToken, image, social_id } = params;
      loginActionsProp.socialLoginRequest({
        email,
        name,
        image,
        loginType: "FL",
        idToken,
        source: "WEB",
        type: userType,
        social_id,
        onCallback: socialLoginCallback,
      });
    } catch (error) {
      console.error(error);
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong. Please try again later.",
        },
      });
    } finally {
      hideLoader();
    }
  };

  const socialLoginCallback = (params) => {
    const { name, email, image, idToken, status, loginType, social_id } = params;
    let eventUserType;
    if (!status) {
      navigate(`/signup/${userType}`, {
        state: {
          email,
          name,
          image,
          isSocialLogin: true,
          idToken,
          loginType,
          social_id,
          source: "WEB",
        },
      });
      //Log signup event for jobseeker and employer here
      if (loginType === "GL" && userType === JOB_SEEKER) {
        eventUserType = FirebaseEvents.JS_GOOGLE_SIGNUP;
      } else if (loginType === "FL" && userType === JOB_SEEKER) {
        eventUserType = FirebaseEvents.JS_FACEBOOK_SIGNUP;
      } else if (loginType === "GL" && userType === EMPLOYER) {
        eventUserType = FirebaseEvents.EMP_GOOGLE_SIGNUP;
      } else {
        eventUserType = FirebaseEvents.EMP_FACEBOOK_SIGNUP;
      }
    } else {
      onLoginCallback(params);
      //Log signin event for jobseeker and employer here
      if (loginType === "GL" && params.type === JOB_SEEKER) {
        eventUserType = FirebaseEvents.JS_GOOGLE_SIGNIN;
      } else if (loginType === "FL" && params.type === JOB_SEEKER) {
        eventUserType = FirebaseEvents.JS_FACEBOOK_SIGNIN;
      } else if (loginType === "GL" && params.type !== JOB_SEEKER) {
        eventUserType = FirebaseEvents.EMP_GOOGLE_SIGNIN;
      } else {
        eventUserType = FirebaseEvents.EMP_FACEBOOK_SIGNIN;
      }
    }
    logEvent(eventUserType);
  };

  const handleClose = () => {
    sessionStorage.removeItem("isClickedOnApply");
    navigate(-1);
  };

  const onClickContinueWithEmail = () => {
    const referrer = state?.referrer;
    navigate(`/loginemail/${userType}`, {
      state: {
        referrer,
      },
    });
  };

  const handleUrlImgEncoding = async (imgUrl) => {
    try {
      const encodedFile = await getBase64FromUrl(imgUrl);
      return encodedFile;
    } catch (err) {
      console.log(err);
      return null;
    }
  };

  const onSuccessGoogleLogin = async (response) => {
    try {
      const userInfo = await getUserInfoGoogleApi({
        access_token: response?.access_token,
      });
      const { name, email, picture, sub: googleId } = userInfo;
      const encodedImg = await handleUrlImgEncoding(picture);
      handleGoogleLogin({
        email,
        name,
        image: userType !== Loginconst.EMPLOYER_KEY ? encodedImg : "", //ED-1739-Restrict Google/facebook profile image for Employer Signup
        idToken: response?.access_token,
        loginType: "GL",
        source: "WEB",
        social_id: googleId,
      });
    } catch (error) {
      onFailureGoogleLogin(response);
    }
  };

  const onSuccessFacebookLogin = async (response) => {
    const encodedImg = await handleUrlImgEncoding(response.picture?.data?.url);
    handleFacebookLogin({
      email: response.email,
      name: response.name,
      image: userType !== Loginconst.EMPLOYER_KEY ? encodedImg : "", //ED-1739-Restrict Google/facebook profile image for Employer Signup
      loginType: "FL",
      source: "WEB",
      idToken: response.accessToken,
      social_id: response.id,
    });
  };

  const onFailureGoogleLogin = (response) => {
    if (response?.details === "Cookies are not enabled in current environment.") {
      //No code here
    } else {
      showNotification({
        alertProps: {
          severity: "error",
          children: "Something went wrong. Please try again later.",
        },
      });
    }
  };

  return (
    <LoginViewContainer isMobile={isMobile}>
      <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
        <Text
          size={16}
          component="span"
          weight="extrabold"
          color="#2B4895"
          style={{ lineHeight: 1.2 }}
        >
          EMPLOY
          <Text
            size={16}
            component="span"
            weight="extrabold"
            color="#0A97FF"
            style={{ lineHeight: 1.2 }}
          >
            D
          </Text>
        </Text>
        <Icon
          name="cancel-dialog"
          size={32}
          style={{
            alignSelf: "flex-end",
            cursor: "pointer",
          }}
          onClick={handleClose}
        />
      </Box>
      <Wrapper
        handleClose={handleClose}
        handleFacebookLogin={handleFacebookLogin}
        handleGoogleLogin={handleGoogleLogin}
        onClickContinueWithEmail={onClickContinueWithEmail}
        onFailureGoogleLogin={onFailureGoogleLogin}
        onSuccessGoogleLogin={onSuccessGoogleLogin}
        onSuccessFacebookLogin={onSuccessFacebookLogin}
        type={userType}
      />
    </LoginViewContainer>
  );
};

const mapStateToProps = ({ loginState }) => ({
  isLoginLoading: loginState.isLoginLoading,
  isLoginFailed: loginState.isLoginFailed,
  isLoginSuccess: loginState.isLoginSuccess,
  user: loginState.user,
});

const mapDispatchToProps = (dispatch) => ({
  loginActionsProp: bindActionCreators(loginActions, dispatch),
});

LoginContainer.propTypes = {
  loginActionsProp: PropTypes.any.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

const LoginViewContainer = styled("div")`
  align-self: ${({ isMobile }) => (isMobile ? "flex-end" : "auto")};
  align-items: ${({ isMobile }) => (!isMobile ? "center" : "auto")};
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 12px 24px #2b489414;
  border: 1px solid ${colors.stroke};
  border-radius: ${spacing.s};
  display: flex;
  flex-direction: column;
  height: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "643px" : "600px")};
  left: 50%;
  position: absolute;
  padding: ${spacing.l};
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ isMobile }) => styleUtils.pxToRem(isMobile ? "348px" : "439px")};
`;
