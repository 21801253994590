export const colors = {
  primary: "#2B4895",
  textPrimary: "#000000",
  placeholder: "#757576",
  border: "#80868A",
  stroke: "#efeef3",
  chatBorderColor: "#ECEBEB",
  orange: "#EE9D52",
  unreadMessageCount: "#E87D20",
  green: "#60AD36",
  forestGreen: "#2b9433",
  paleMint: "#effff0",
  lightBlue: "#E6EDFF",
  dodgerBlue: "#0A97FF",
  selectedCardBlue: "#F7F9FF",
  activeLinkBlue: "#2C4895",

  secondary: "#80A4FF",
  link: "#098AE6",
  white: "#FFFFFF",
  backgroundWhite: "#FCFFFE",
  shadow: "#2B48951A",
  darkBlue: "#2B4895",
  royalBlue: "#3A5FC7",
  midnightBlue: "#2B4894",
  DarkSapphireBlue: "#2C4895",
  black: "#4D4D4D",
  red: "#FF0000",
  dropShadow: "#2B489533",
  labelBlack: "#383838",
  disableGrey: "#A6A6A6",
  mediumGrey: "#BABABA",
  slateGrey: "#A5A5A5",
  pictonBlue: "#41A4ED",
  fadedBlack: "#808080",
  brownText: "#4E4E4E",
  blackText: "#1D1D1D",
  red2: "#E83030",
  darkGray2: "#2C2C2C",
  shimmerGray: "#8C8C8C",
  darkBlack: "#000000DE",
  lightGray: "#717171",
  charcoalGray: "#1C1C1C",
  silver: "#c9c9c9",
  gainsboroGray: "#d3d3d3",

  titleBlack: "#1A1A1A",
  subtextGray: "#777777",
  descriptionGray: "#555555",
  timerGray: "#A8A8A8",
  bulletGray: "#848484",
  selectedBlue: "#F2F5FF",
  linkColor: "#1A9FD9",

  seperator: "#E4EBFF",
  linkText: "#333333",

  alertSuccess: "#EBF7EE",
  alertSuccessBorder: "#BDE4C7",
  alertInfo: "#E5EFFA",
  alertInfoBorder: "#ABCDF1",
  alertWarning: "#FEF7EA",
  alertWarningBorder: "#FADEAF",
  alertError: "#FCEDEA",
  alertErrorBorder: "#F5C5BB",
  inputBorder: "#E0E0E0",
  fadingColor: "#B9F4BD",
  boxShadow: "#647EFF2C",
  shimmerBaseColor: "#DCDCDC",
  shimmerHighlightColor: "#A9A9A9",
  starFill: "#EDD61D",
  darkGray: "#989898",
  filterBadgeBlue: "#2b4894",

  //User Rating colors
  ratingFilledYellowStar: "#E8C64A",
  ratingStarOutline: "#e8c649",
};
