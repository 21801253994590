import React, { Suspense, lazy, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Box, Button, FormControl, FormLabel, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useFormik } from "formik";

import useIsMobile from "../../../hooks/useIsMobile";
import OtpSelector from "../selectors/OtpSelectors";

import { editEmailDetailsSchema, editPhoneDetailsSchema } from "./otpValidationSchema";
import { jobSeekerVerifyRequest, employerVerifyRequest } from "../../SignUp/actions/SignUpActions";
import { resetEmailValidation, resetPhoneValidation, sendOtpRequest } from "../actions/OtpActions";

import { Icon, Text, TextInput } from "../../../components/atoms";
import { colors, spacing, styleUtils, textSizes } from "../../../styles";
import * as EditFormConsts from "./OtpConstants";
import { verifyLoginOtpUserRequest } from "../actions/LoginOtpActions";

const MuiPhoneNumber = lazy(() => import("material-ui-phone-number"));

/**
 * Edit email and Phone Screen View
 */
const EditSignUpDetails = (props) => {
  const phoneInput = useRef(null);
  const {
    isEmailEdit,
    isEmployer,
    handleEditNav,
    details: { email, phoneNumber },
  } = props;

  const isMobile = useIsMobile();
  const dispatch = useDispatch();

  const emailValidation = useSelector(OtpSelector.getIsEmailValidation);
  const isEmailOtpValid = useSelector(OtpSelector.getIsEmailOtpValid);
  const phoneValidation = useSelector(OtpSelector.getIsPhoneValidation);
  const isPhoneOtpValid = useSelector(OtpSelector.getIsPhoneOtpValid);

  useEffect(() => {
    formik.setFieldValue("email", email);
    formik.setFieldValue("phoneNumber", phoneNumber);
  }, []);

  const formik = useFormik({
    initialValues: isEmailEdit
      ? {
          email: "",
        }
      : {
          countryCode: "",
          phoneNumber: "",
        },
    validationSchema: isEmailEdit ? editEmailDetailsSchema : editPhoneDetailsSchema,
    onSubmit: (values) => {
      if (isEmployer) {
        if (isEmailEdit) {
          if (formik?.values?.email !== email) {
            dispatch(
              employerVerifyRequest({
                email: values.email,
                onCallback: handleEmailEdit,
              }),
            );
          } else {
            handleEditNav(false);
          }
        } else {
          handlePhoneEdit();
        }
      } else {
        if (isEmailEdit) {
          if (formik?.values?.email !== email) {
            dispatch(
              jobSeekerVerifyRequest({
                keyType: "email",
                keyValue: values.email,
                onCallback: handleEmailEdit,
              }),
            );
          } else {
            handleEditNav(false);
          }
        } else {
          handlePhoneEdit();
        }
      }
    },
  });

  const isEmailChanged = formik.values.email !== email;
  const isPhoneChanged = formik.values.phoneNumber !== phoneNumber;

  const handleEmailEdit = (params) => {
    const { message, isEmailExist } = params;
    if (isEmailExist) {
      formik.setErrors({ email: message });
    } else {
      if (emailValidation && isEmailOtpValid) {
        dispatch(resetEmailValidation());
      }
      dispatch(
        sendOtpRequest({
          target: "email",
          email: formik.values.email,
          onCallback: () => {},
        }),
      );

      handleEditNav(true, formik.values);
    }
  };

  const handlePhone = (phoneValue) => {
    const {
      current: {
        state: {
          selectedCountry: { dialCode },
        },
      },
    } = phoneInput;
    formik.setFieldValue("countryCode", dialCode);
    formik.setFieldValue("phoneNumber", phoneValue);
  };

  const handlePhoneEdit = () => {
    if (formik?.values?.phoneNumber !== phoneNumber) {
      if (phoneValidation && isPhoneOtpValid) {
        dispatch(resetPhoneValidation());
      }
      const {
        current: {
          state: {
            selectedCountry: { dialCode },
          },
        },
      } = phoneInput;
      let otpSrc = formik.values.phoneNumber;
      otpSrc = otpSrc.replace(`+${dialCode}`, "");
      dispatch(
        verifyLoginOtpUserRequest({
          keyValue: otpSrc,
          keyType: "phone",
          userType: "employer",
          loginType: false,
          onCallback: handlePhoneCheckCallback,
        }),
      );
    } else {
      handleEditNav(false, {});
    }
  };

  const handlePhoneCheckCallback = (params) => {
    const { userExist } = params;

    if (!userExist) {
      dispatch(
        sendOtpRequest({
          target: "phone",
          email: formik.values.email,
          phoneNumber: formik.values.phoneNumber,
          onCallback: () => {},
        }),
      );
      handleEditNav(true, formik.values);
    } else {
      formik.setFieldError("phoneNumber", EditFormConsts.PHONE_EXISTS_ERROR);
    }
  };

  return (
    <>
      <StyledHeaderWrapper isMobile={isMobile}>
        <Text color={colors.titleBlack} size="xl" weight="semibold">
          {isEmailEdit
            ? EditFormConsts.EDIT_EMAIL_FORM_HEADER
            : EditFormConsts.EDIT_PHONE_FORM_HEADER}
        </Text>
      </StyledHeaderWrapper>
      <EditDetailsContainer isMobile={isMobile}>
        <FormsInputContainer>
          {isEmailEdit && (
            <FormControl variant="standard" required sx={{ width: "100%" }}>
              <FormLabel
                sx={{
                  color: "#383838",
                  marginBottom: styleUtils.pxToRem("6px"),
                }}
              >
                {EditFormConsts.EDIT_EMAIL_LABEL}
              </FormLabel>
              <FormsTextInput
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                placeholder={EditFormConsts.EDIT_EMAIL_PLACEHOLDER}
                sx={{
                  width: styleUtils.pxToRem(isMobile ? "100%" : "432px"),
                }}
              />
              {formik.touched.email && Boolean(formik.errors.email) && (
                <FormHelperText sx={{ color: "#FF0000" }}>{formik.errors.email}</FormHelperText>
              )}
            </FormControl>
          )}
          {!isEmailEdit && (
            <FormControl variant="standard" required>
              <FormLabel
                sx={{
                  color: "#383838",
                  marginBottom: styleUtils.pxToRem("6px"),
                }}
              >
                {EditFormConsts.EDIT_PHONE_LABEL}
              </FormLabel>
              <Suspense fallback={null}>
                <MuiPhoneNumber
                  autoFormat={false}
                  countryCodeEditable={false}
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  defaultCountry={"us"}
                  disableAreaCodes={true}
                  onlyCountries={["us", "in"]}
                  onChange={handlePhone}
                  ref={phoneInput}
                  sx={{
                    "& .MuiInputBase-root": {
                      border: "1px solid #E0E0E0",
                      borderRadius: styleUtils.pxToRem("4px"),
                    },
                    "& .MuiInputBase-root:hover": {
                      border: "1px solid #2B4895",
                      borderRadius: styleUtils.pxToRem("4px"),
                    },
                    "& .MuiInputBase-root:before": {
                      borderBottom: "none",
                      transition: "none",
                    },
                    "& .MuiInputBase-root:after": {
                      borderBottom: "none",
                    },
                    "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                      borderBottom: "none",
                    },
                    "& .MuiButtonBase-root": {
                      borderRight: `1px solid ${colors.stroke}`,
                      paddingRight: spacing.s,
                      borderRadius: 0,
                    },
                    height: styleUtils.pxToRem("42px"),
                    width: styleUtils.pxToRem(isMobile ? "100%" : "432px"),
                  }}
                />
              </Suspense>
              {formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber) && (
                <FormHelperText sx={{ color: "#FF0000" }}>
                  {formik.errors.phoneNumber}
                </FormHelperText>
              )}
            </FormControl>
          )}
        </FormsInputContainer>
        <FormsBtnContainer>
          <Icon
            name="arrow-back"
            size={42}
            style={{ cursor: "pointer" }}
            onClick={() => handleEditNav(false)}
          />
          <Button
            variant="contained"
            disabled={!isPhoneChanged && !isEmailChanged}
            sx={{
              marginLeft: "auto",
              width: styleUtils.pxToRem(isMobile ? "152px" : "216px"),
              height: styleUtils.pxToRem("42px"),
              fontSize: textSizes.m,
            }}
            onClick={formik.handleSubmit}
          >
            {EditFormConsts.EDIT_SAVE_BTN}
          </Button>
        </FormsBtnContainer>
      </EditDetailsContainer>
    </>
  );
};

EditSignUpDetails.propTypes = {
  details: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phoneNumber: PropTypes.string.isRequired,
  }).isRequired,
  handleEditNav: PropTypes.func.isRequired,
  isEmailEdit: PropTypes.bool.isRequired,
  isEmployer: PropTypes.bool.isRequired,
};

export default EditSignUpDetails;

const EditDetailsContainer = styled(Box)`
  flex: ${({ isMobile }) => isMobile && 1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ isMobile }) => !isMobile && spacing.xxl};
  padding-top: 0;
  margin: ${({ isMobile }) => !isMobile && `1.75rem`};
  margin-top: ${({ isMobile }) => (!isMobile ? `-${spacing.xxxl}` : 0)};
`;

const FormsInputContainer = styled(Box)`
  margin-top: ${styleUtils.pxToRem("36px")};
`;

const FormsTextInput = styled(TextInput)`
  border: 1px solid #e0e0e0;
  height: ${styleUtils.pxToRem("42px")};
  border-radius: ${styleUtils.pxToRem("4px")};
`;

const FormsBtnContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  margin-top: ${styleUtils.pxToRem("358px")};
`;

const StyledHeaderWrapper = styled("div")`
  margin: ${({ isMobile }) => !isMobile && spacing.s};
  padding: ${({ isMobile }) => !isMobile && `3.25rem`};
`;
