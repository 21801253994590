export const UnAuthenticatedNavConstants = {
  HOME_TAB: "Home",
  EXPLORE_TAB: "Explore Jobs",
  PRIVACY_POLICY: "Privacy Policy",
  AUTH_SELECT_BUTTON: "Login/SignUp",
  SELECT_EMPLOYER_BUTTON: "Employer",
  SELECT_JOB_SEEKER_BUTTON: "Job Seeker",
  BLOGS: "Blogs",
  JOBSEEKER_HOME_BUTTON: "Job Seeker / Get a job",
  EMPLOYER_HOME_BUTTON: "Employers / Post a job",
};

export const JobSeekerAuthenticatedNavConstants = {
  HOME_TAB: "Home",
  EXPLORE_TAB: "Explore Jobs",
  MY_PROFILE: "Account",
  SETTINGS: "Settings",
  REFERRALS: "Referrals",
  LOGOUT: "Logout",
  CHAT: "Chat",
  FAVOURITES: "Applications",
};

export const EmployerAuthenticatedNavConstants = {
  JOBS_TAB: "Jobs",
  PEOPLE_TAB: "Job Seekers",
  POST_NEW_JOB_TAB: "Post New Job",
  CHAT: "Chat",
  MY_PROFILE: "Account",
  LOGOUT: "Logout",
};

export const NavTextIconMapping = {
  [UnAuthenticatedNavConstants.HOME_TAB]: "home",
  [UnAuthenticatedNavConstants.EXPLORE_TAB]: "exploreJobs",
  [UnAuthenticatedNavConstants.BLOGS]: "blog",
};
