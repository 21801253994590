export const JOB_SEEKER_SIGNUP_REQUEST = "JOB_SEEKER_SIGNUP_REQUEST";
export const JOB_SEEKER_SIGNUP_FAILED = "JOB_SEEKER_SIGNUP_FAILED";
export const JOB_SEEKER_SIGNUP_SUCCESS = "JOB_SEEKER_SIGNUP_SUCCESS";
export const JOB_SEEKER_VERIFY_REQUEST = "JOB_SEEKER_VERIFY_REQUEST";
export const JOB_SEEKER_VERIFY_FAILED = "JOB_SEEKER_VERIFY_FAILED";
export const JOB_SEEKER_VERIFY_SUCCESS = "JOB_SEEKER_VERIFY_SUCCESS";

export const EMPLOYER_SIGNUP_REQUEST = "EMPLOYER_SIGNUP_REQUEST";
export const EMPLOYER_SIGNUP_FAILED = "EMPLOYER_SIGNUP_FAILED";
export const EMPLOYER_SIGNUP_SUCCESS = "EMPLOYER_SIGNUP_SUCCESS";
export const EMPLOYER_VERIFY_REQUEST = "EMPLOYER_VERIFY_REQUEST";
export const EMPLOYER_VERIFY_FAILED = "EMPLOYER_VERIFY_FAILED";
export const EMPLOYER_VERIFY_SUCCESS = "EMPLOYER_VERIFY_SUCCESS";

export const RESET_SIGN_UP = "RESET_SIGN_UP";
